import { Component } from '@angular/core';
import { MessageService } from '../shared/service/message.service';
import { ToastMessageService } from '../shared/service/toast-message.service';

@Component({
  selector: 'app-pdf-chat',
  templateUrl: './pdf-chat.component.html',
  styleUrls: ['./pdf-chat.component.scss']
})
export class PdfChatComponent {
  responses = [] as {
    role: string;
    content: string;
  }[];
  username: string = '';
  isSuccess:boolean=false;
  notetext='Note: Please be aware that the messages have been generated by an artificial intelligence model. While efforts have been made to provide accurate and relevant information, there is a possibility of errors or inaccuracies. The response you receive should not be considered as professional or expert advice. We strongly recommend cross-checking the information with reliable sources or consulting a human expert when necessary. The purpose of this AI is to assist and provide general information, but it may not always be perfect. Use the information at your own discretion.';
  currenttime = new Date().toLocaleTimeString();
  
  intervalId: any;
  stopChatCount = 0;
  constructor(private messageService:MessageService) {}
  ngOnInit() {
    this.username = localStorage.getItem('okta-username')?localStorage.getItem('okta-username') as string:'Guest';
    var input = document.getElementById('chat-input') as HTMLInputElement;
    // Execute a function when the user presses a key on the keyboard
    input.addEventListener('keypress', function (event) {
      // If the user presses the "Enter" key on the keyboard
      if (event.key === 'Enter') {
        // Cancel the default action, if needed
        event.preventDefault();
        // Trigger the button element with a click
        const button = document.getElementById(
          'btn-Submit'
        ) as HTMLInputElement;
        button.click();
      }
    });
  }
  onSubmit() {
    const chatBox = document.getElementById('chat-box') as HTMLInputElement;
    const chatInput = document.getElementById('chat-input') as HTMLInputElement;
    if (chatInput.value === '') {
      alert('Please enter your query!')
      return;
    }
    this.responses.push({
      role: 'user',
      content: chatInput.value,
    });
    this.appendChatBox(true);
    this.createtyping();
    // this.messageService.sendPdfCompletionRequest(this.responses).subscribe(

    //   (res: any) => {

    //     if (res?.response?.response?.response) {
    //       let content = '';
    //       //res.response.response.response.forEach(
    //         //(element: { message: { role: string; content: string } }) => {
    //         //  this.responses.push(element.message);
    //           content = content + res?.response?.response?.response.message.content.answer;
    //        // }
    //       //);
    //       this.appendChatBox(false, content);
    //     } else {
    //       this.appendChatBox(false, "Sorry, I can't understand.");
    //     }
    //     setTimeout(() => {
    //       this.removeTyping();
    //     } , 2000);
    //   },
    //   (err: any) => {
    //     this.appendChatBox(false, "Sorry, I can't understand.");
    //     console.log('error is', err);
    //     setTimeout(() => {
    //       this.removeTyping();
    //     } , 2000);
    //   }
    // );

    this.messageService.complanceChat('HR-Bot', this.responses)
    .subscribe(
      (res: any) => {
        if (res?.response?.status == "success") {
          // this.toastMessage.showSuccess(res?.response?.message);
          this.startInterval();
        } else {
          // this.toastMessage.showError(res?.response?.message);
          this.appendChatBox(false, "Sorry, I can't understand.");
          setTimeout(() => {
            this.removeTyping();
          }, 2000);
        }
      },
      (error) => {
        // this.toastMessage.showError(error?.message);
        this.appendChatBox(false, "Sorry, I can't understand.");
        console.log('error is', error?.message);
        setTimeout(() => {
          this.removeTyping();
        }, 2000);
      }
    );
  }

  
  // new methods added 

  // checking status of file per INterver till successed

  startInterval(): void {
    this.intervalId = setInterval(() => {
      this.getUsersChatData();
    }, 12000); 
  }


  stopInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
      this.stopChatCount = 0;
    }
  }

  getUsersChatData() {
    this.messageService.complanceChatStats()
      .subscribe(
        (res: any) => {
          if (res?.status == 'success') {
            this.stopChatCount++;
            if (res.data !== 0) {
              setTimeout(() => {
                this.removeTyping();
              }, 2000);
              this.stopInterval();
              let content = '';
              content = content + res?.data || 'Something went wrong';
              this.appendChatBox(false, content);
            }

            if (this.stopChatCount > 12) {
              setTimeout(() => {
                this.removeTyping();
              }, 2000);
              this.appendChatBox(false, "Sorry, I can't understand.");
              this.stopInterval();
            }

          } else {
            setTimeout(() => {
              this.removeTyping();
            }, 2000);
            this.appendChatBox(false, "Sorry, I can't understand.");
            this.stopInterval();
          }       

        },
        (error) => {
          // Handle the error here
          this.stopInterval();
          console.error(error);
          this.appendChatBox(false, "Sorry, I can't understand.");
          setTimeout(() => {
            this.removeTyping();
          }, 2000);
        }
      );
  }

  removeTyping() {
    var typing = document.getElementById("typing") as HTMLInputElement;
    typing.remove();
  }

  createtyping() {
    const chatBox = document.getElementById('chat-box') as HTMLInputElement;
    const newChatDiv = this.chatTemplate2({
      class: 'typing',
      text: '',
      date: '',
      image: 'assets/typing.gif',
      botclass: 'typingimg',
    });
    chatBox.innerHTML += newChatDiv;
    chatBox.scrollTop = chatBox.scrollHeight;


  }
  chatTemplate2(aiOrPerson: any) {
    return `

        <div class="ai-person-container " id="typing">
        <div class="${aiOrPerson.botclass}"><img src="${aiOrPerson.image}" /></div>
          <div class="${aiOrPerson.class}">
            <p>${aiOrPerson.text}</p>
          </div>
          <span class="${aiOrPerson.class}-date">${aiOrPerson.date}</span>
        </div>
      `;
  }
  chatTemplate(aiOrPerson: any) {
    return `

        <div class="ai-person-container">
        <div class="${aiOrPerson.botclass}"><img src="${aiOrPerson.image}" /></div>
          <div class="${aiOrPerson.class} border shadow-sm">
            <p>${aiOrPerson.text}</p>
          </div>
          <span class="${aiOrPerson.class}-date">${aiOrPerson.date}</span>
        </div>
      `;
  }
  appendChatBox(fromPerson: any, response?: any) {

    const chatInput = document.getElementById('chat-input') as HTMLInputElement;
    const chatBox = document.getElementById('chat-box') as HTMLInputElement;
    const date = new Date();
    if (!fromPerson) {
      date.setSeconds(date.getSeconds() + 2);
    }
    if (fromPerson && chatInput && !chatInput.value.trim()) {
      return;
    }
    const timestamp = date.toLocaleTimeString();
    const newChatDiv = this.chatTemplate({
      class: fromPerson ? 'person' : 'ai',
      text: fromPerson ? chatInput && chatInput.value.trim() : response,
      date: timestamp,
      image: fromPerson ? 'assets/person.png' : 'assets/bot.png',
      botclass: fromPerson ? 'person-image' : 'ai-image',
    });
    if (!fromPerson) {
      // make it so it only responds once to multiple fast sentences
      setTimeout(() => {
        chatBox.innerHTML += newChatDiv;
        chatBox.scrollTop = chatBox.scrollHeight;
      }, 2000);
    } else {
      chatBox.innerHTML += newChatDiv;
      chatBox.scrollTop = chatBox.scrollHeight;
    }
    if (fromPerson) {
      chatInput.value = '';
    }

  }
}
