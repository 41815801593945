import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class SopService {
  activeUserRole = new BehaviorSubject({});

  uploadBYOD(currentFile: any, arg1: string, isImageExits: any) {
    throw new Error('Method not implemented.');
  }
  endpoint = environment.nodeEndpoint;
  apiURL = environment.apiURL;

  constructor(private http: HttpClient, private router: Router) { }

  sendBYODRequests(userquery: string) {
    let data = {
      "kbname": sessionStorage.getItem('kbname'),
      "userquery": userquery,
      "filename": localStorage.getItem('fileName')
    };
    return this.http.post(`${this.endpoint}byod-answer`, data).pipe(catchError(this.handleError))
  }


  sendFormRequests(userquery: string) {
    let data = {
      "kbname": sessionStorage.getItem('kbname'),
      "userquery": userquery
    };
    return this.http.post(`${this.endpoint}byod-answer`, data).pipe(catchError(this.handleError))
  }

  checkFileStatus() {

    return this.http.get(`${this.endpoint}file-status`).pipe(catchError(this.handleError))
  }

  downloadSop(file: File): Observable<ArrayBuffer> {
    const url = `${this.apiURL}/submitting?fileId=${file.name}`;
    return this.http.get(url, { responseType: 'arraybuffer' })
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 400) {
            return throwError('Bad Request: Please check your input.');
          } else if (error.status === 500) {
            return throwError('Internal Server Error: Something went wrong on the server.');
          } else {
            return throwError('An unexpected error occurred.');
          }
        })
      );
  }


  uploadSop(file: File) {
    console.log('api call ')
    const formData = new FormData();
    formData.append('file_new', file, file.name);
    //formData.append('with_image', imageExists ? 'true' : 'false');
    //with_image
    //if(imageExists) formData.append('filename file_image', file.name);
    //http://127.0.0.1:8010/submitting
    const req = new HttpRequest('POST', `${this.apiURL}/uploading`, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }


  // complance services

  complanceChat(app: any, messages: any) {
    return this.http.post(`${this.endpoint}pdf-search?app=${app}`, {
      message: messages
    }).pipe(catchError(this.handleError))
  }

  createUserHistory(payload: any): Observable<any> {
    const url = `${this.endpoint}create-rag-user-history`;
    return this.http.post<any>(url, payload);
  }


  getUserHistory(): Observable<any> {
    const url = `${this.endpoint}rag-user-history`;  
    return this.http.get<any>(url);
  }


  private handleError(error: HttpErrorResponse) {
    let errMessage = '';
    console.log('error', error);
    if (error?.status === 0) {
      console.error('An error occurred:', error.error);
    } else {

      if (error?.status === 409 || error?.status === 401) {
        errMessage = error.status.toString();
        return throwError(() => new Error(errMessage));
      }
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    errMessage = 'Please try again later';
    return throwError(() => new Error(errMessage));
  }

  getRagUserRoles(): Observable<string[]> {
    return this.http.get<any[]>(`${this.endpoint}get-raguser-role`);
  }
}
