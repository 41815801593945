
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';
// import { CvmatcherComponent } from './cvmatcher/cvmatcher.component';
import { HomeOpenaiComponent } from './home-openai/home-openai.component';
import { HomeComponent } from './home/home.component';
import { LogoutComponent } from './logout/logout.component';
import { PlaygroundComponent } from './playground/playground.component';
import { HelpSupportComponent } from './shared/components/help-support/help-support.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

import { AuthGuard } from './auth.guard';
import { MsalGuard } from '@azure/msal-angular';

import { ChatGptComponent } from './chatgpt/chat-gpt.component';


import { TranslateFileComponent } from './translate-file/translate-file.component';

import { TransitionBotComponent } from './Transition-Bot/Transition-Bot.component';
import { PgBotComponent } from './_features/pg-bot/pg-bot.component';
// import { AcuvateComponent } from './acuvate/acuvate.component';
// import { ChatbotComponent } from './acuvate/chatbot/chatbot.component';
// import { FineTuneComponent } from './acuvate/fine-tune/fine-tune.component';
import { BedrockChatComponent } from './bedrock-chat/bedrock-chat.component';
import { BgcInitComponent } from './bgc/bgc-init/bgc-init.component';
import { BgcListComponent } from './bgc/bgc-list/bgc-list.component';
import { BgcComponent } from './bgc/bgc.component';
import { BringYourFileComponent } from './bring-your-file/bring-your-file.component';
// import { CvjdpreferencesComponent } from './cvjdpreferences/cvjdpreferences.component';
// import { CvtoolComponent } from './cvtool/cvtool.component';
// import { DataAnalyticsComponent } from './data-analytics/data-analytics.component';
import { GoogleTranslateComponent } from './google-translate/google-translate.component';
import { ImageGeneratorComponent } from './image-generator/image-generator.component';
import { ITBotComponent } from './it-bot/it-bot.component';
// import { JdtoolComponent } from './jdtool/jdtool.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { PdfChatComponent } from './pdf-chat/pdf-chat.component';
import { PerplexityComponent } from './perplexity/perplexity.component';
import { SuccessStoriesComponent } from './success-stories/success-stories.component';
import { SuccessstoriesComponent } from './successstories/successstories.component';
import { FileTranslateComponent } from './systran/file-translate/file-translate.component';
import { SystranComponent } from './systran/systran.component';
import { TextTranslateComponent } from './systran/text-translate/text-translate.component';
import { VideoIndexerComponent } from './video-indexer/video-indexer.component';
import { SubsidiaryComponent } from './subsidiary/subsidiary.component';
import {ApiAssistantComponent} from  './api-assistant/api-assistant.component';
import { AskGenpactComponent } from './ask-genpact/ask-genpact.component';
import { GcpChatComponent } from './gcp-chat/gcp-chat.component';

const routes: Routes = [
  { path: '', component: HomeComponent },

  { path: 'home', component: OktaCallbackComponent },
  { path: 'QnA', component: HelpSupportComponent, canActivate: [AuthGuard] },

  // { path: 'cvmatcher', component: CvmatcherComponent, canActivate: [AuthGuard] },
  { path: 'logout', component: LogoutComponent },
  { path: 'playground', component: PlaygroundComponent, canActivate: [AuthGuard] },
  { path: 'unauthorized', component: UnauthorizedComponent, canActivate: [AuthGuard] },
  { path: 'chat-completion', component: ChatGptComponent, canActivate: [AuthGuard] },
  { path: 'home-openai', component: HomeOpenaiComponent, canActivate: [AuthGuard] },
  { path: 'byod', component: BringYourFileComponent, canActivate: [AuthGuard] },

  { path: 'translate-file', component: TranslateFileComponent, canActivate: [AuthGuard] },
  { path: 'video-indexer', component: VideoIndexerComponent, canActivate: [AuthGuard] },
  { path: 'data-insights', component: BedrockChatComponent, canActivate: [AuthGuard] },
  { path: 'image-generator', component: ImageGeneratorComponent, canActivate: [AuthGuard] },
  { path: 'image-generator/:type', component: ImageGeneratorComponent, canActivate: [AuthGuard] },

  { path: 'ask-genpact', component: AskGenpactComponent, canActivate: [MsalGuard] },

  { path: 'research-assistant', component: PerplexityComponent, canActivate: [AuthGuard] },
  { path: 'subsidiary', component: SubsidiaryComponent, canActivate: [AuthGuard] },
  // { path: 'data-analytics', component: DataAnalyticsComponent, canActivate: [AuthGuard] },

  { path: 'HR-Bot', component: PdfChatComponent, canActivate: [AuthGuard] },
  { path: 'IT-Bot', component: ITBotComponent, canActivate: [AuthGuard] },
  { path: 'Transition-Bot', component: TransitionBotComponent, canActivate: [AuthGuard] },
  // { path: 'cvtool', component: CvtoolComponent, canActivate: [AuthGuard] },
  // { path: 'jdtool', component: JdtoolComponent, canActivate: [AuthGuard] },
  // { path: 'cvjdpreferences', component: CvjdpreferencesComponent, canActivate: [AuthGuard] },
  { path: 'google-translate', component: GoogleTranslateComponent, canActivate: [AuthGuard] },



  { path: 'leaderboard', component: LeaderboardComponent, canActivate: [AuthGuard] },
  { path: 'data-analyzer', component: ApiAssistantComponent, canActivate: [AuthGuard] },
  { path: 'gcp-chat', component: GcpChatComponent, canActivate: [AuthGuard] },





  {
    path: 'bgc', component: BgcComponent, children: [
      // { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: '', component: BgcListComponent, canActivate: [AuthGuard] },
      { path: 'init', component: BgcInitComponent, canActivate: [AuthGuard] },
      { path: ':id/upload', component: BgcInitComponent, canActivate: [AuthGuard] },
    ]
  },

  {
    path: 'translate', component: SystranComponent, children: [
      { path: '', redirectTo: 'text', pathMatch: 'full' },
      { path: 'text', component: TextTranslateComponent, canActivate: [AuthGuard] },
      { path: 'file', component: FileTranslateComponent, canActivate: [AuthGuard] },
    ]
  },

  // {
  //   path: 'gknowledge', component: AcuvateComponent, children: [
  //     { path: '', redirectTo: 'chat-bot', pathMatch: 'full' },
  //     { path: 'chat-bot', component: ChatbotComponent, canActivate: [AuthGuard] },
  //     { path: 'fine-tune', component: FineTuneComponent, canActivate: [AuthGuard] },
  //   ]
  // },

  { path: 'success_stories', component: SuccessstoriesComponent, canActivate: [AuthGuard] },
  { path: 'success-stories', component: SuccessStoriesComponent, canActivate: [AuthGuard] },

  { path: 'video-guide', loadChildren: () => import('./video-player/video-player.module').then(m => m.VideoPlayerModule) },

  { path: 'ai-bot', component: PgBotComponent },

];





@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
