import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class SusidiaryService {
  perplextiyApi = environment.apiURL + '/subsidy';


  constructor(private http: HttpClient) { }


  relatedQuestion(payload: any) {
    return this.http.post(`${this.perplextiyApi}/recent_info`, payload);
  }

  relatedQuestionLinks(payload: any) {
    return this.http.post(`${this.perplextiyApi}/recent_bing`, payload);
  }

  relatedQuestionContent(payload: any) {
    return this.http.post(`${this.perplextiyApi}/recent_response`, payload);
  }

  relatedQuestionCollection(payload: any) {
    return this.http.post(`${this.perplextiyApi}/recent_collection`, payload);
  }

  policysearch(payload: any) {
    return this.http.post(`${this.perplextiyApi}/subsidy-policy-search`, payload);
  }
  policyStatus() {
return this.http.get(`${this.perplextiyApi}/subsidy-policy-status`);
  }
  policycrawl(payload: any) {
    return this.http.post(`${this.perplextiyApi}/subsidy-policy-crawl`, payload);
  }
}


