<app-sidenav [item]="'playground'"></app-sidenav>

<div class="wrapper">
  <div class="content">
    <div class="row m-0">
      <div class="col-12">
        <div class="row mx-md-5 my-md-3 bg-light">
          <div class="col-md-5 p-0">
            <div class="result-summary">

              <div class="month-label">{{currentMonth}} Leaderboard </div>
            

              <div class="heading-tertiary" style="color:transparent;">
                {{ currentUser?.user_rank  ? (currentUser?.user_rank <= 10 ? 'Congratulations!': 'Rising Star'): 'No Activity!'}}
              </div>
              

              <div class="result-box">
                <p style="color:transparent;" class="result"></p>
                <div  style="color:transparent;">{{currentUser?.user_rank || 0}} </div>          
              </div>

              <div class="result-text-box">
                <div class="heading-secondary" style="color:transparent;">Your Score: {{currentUser?.total || 0}}</div>
                <p class="paragraph">
                  {{currentUser?.user_rank  ? 'Thank you for your dedication and commitment.': 'Explore the features to get rank in leaderboard.'}}          
                </p>
              </div>  
            </div>

          </div>
          
          <div class="col-md-7">
            <div class="results-list px-md-3 bg-white">
                <table class="table">
                    <thead>
                        <tr class="d-desktop">
                            <th scope="col">OHR</th>
                            <th scope="col">Score</th>
                        </tr>
                        
                    </thead>
                    <tbody>
                        <!-- Loop through userList array using ngFor -->
                        <!-- Display only top 10 users -->
                        <!-- Assuming Angular framework for *ngFor directive -->
                        <!-- Replace with appropriate loop syntax if using another framework -->
                        <tr *ngFor="let user of userList; let i = index" >
                            <!-- User ID (OHR) -->
                            <td>{{ user.user_id }}</td>
                            <!-- Score -->
                            <td>{{ user.score }}</td> 
                         </tr> 
                     </tbody> 
                 </table> 
             </div> 
        </div>
        
        

        </div>
      </div>
    </div>
  </div>
</div>




