import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  apiURL = environment.apiURL;
  languageApi = environment.languageApi;
  gApiURL = environment.googleTranslateApi;
  private jsonFilePath = 'assets/language.json'; // Adjust the path as needed


  constructor(private http: HttpClient) { }

  translateLanguage() {
    // return this.http.get(`${this.languageApi}`);
    return this.http.get(this.jsonFilePath);
  }

  translateUpload(payload: any) {
    return this.http.post(`${this.apiURL}/translate_upload`, payload)
  }
  translateimageUpload(payload: any) {
    return this.http.post(`${this.apiURL}/translate_upload_image`, payload)
  }
  translateGimageUpload(payload: any) {
    return this.http.post(`${this.gApiURL}/translate_upload_image`, payload)
  }
  translateDownload(payload: any) {
    return this.http.post(`${this.apiURL}/translate_start`, payload);
  }
// translate_start_aon
translateDownload1(payload: any) {
  return this.http.post(`${this.apiURL}/translate_start_aon`, payload);
}
  translateStatus(payload: any) {
    return this.http.post(`${this.apiURL}/translate_status`, payload);
  }

  translateUploadGlossary(payload: any) {
    return this.http.post(`${this.apiURL}/translate_upload_glossary`, payload);
  }

  // video Indexing apis

  videoUpload(payload: any) {
    return this.http.post(`${this.apiURL}/upload_video`, payload)
  }

  videoStatus(payload: any) {
    return this.http.post(`${this.apiURL}/status`, payload);
  }

  getTranscript(payload: any) {
    return this.http.post(`${this.apiURL}/transcript`, payload);
  }

  uploadGFile(payload: any): Observable<any> {
    return this.http.post(`${this.gApiURL}/upload`, payload);
  }

  translateGFile(payload: any): Observable<any> {
    return this.http.post(`${this.gApiURL}/translate`, payload);
  }
gtexttranslate(payload: any): Observable<any> {
    return this.http.post(`${this.gApiURL}/text-translate`, payload);
}

  downloadGFile(payload: any): Observable<any> {
    return this.http.post(`${this.gApiURL}/download`, payload, { responseType: 'blob'});
  }
  translatefileDownload(payload: any, options?: any): Observable<any> {
    if (options && options.responseType === 'blob') {
      return this.http.post(`${this.apiURL}/translate_download_file`, payload, { ...options });
    } else {
      return this.http.post(`${this.apiURL}/translate_download_file`, payload);
    }
  }

translatedelete(filename: any,ohr: any) {
 const payload = new FormData();
  payload.append('filename', ohr+filename);

    return this.http.post(`${this.apiURL}/translate_delete_files`,payload);
}

downloadTranscript(payload:any){
  return this.http.post(`${this.apiURL}/download-transcript-file`,payload)
}

downloadTranscript1(payload: any): Observable<any> {
  return this.http.post(`${this.apiURL}/download-transcript-file`, payload,
  {
      responseType: 'blob'
    });
}
}
