<app-sidenav [item]="'playground'"></app-sidenav>

<div class="wrapper">
  <div class="content">
    <div class="row m-0">
      <div class="col-12">
        <div class="row mx-md-5 my-md-3 bg-light">
          <div class="col-md-5 p-0">
            <div class="result-summary">

              <div class="month-label">{{currentMonth}} Leaderboard </div>
            

              <div class="heading-tertiary">
                {{ currentUser?.user_rank  ? (currentUser?.user_rank <= 10 ? 'Congratulations!': 'Rising Star'): 'No Activity!'}}
              </div>
              

              <div class="result-box">
                <p class="result">Your Rank is</p>
                <div class="heading-primary">{{currentUser?.user_rank || 0}} </div>          
              </div>

              <div class="result-text-box">
                <div class="heading-secondary">Your Score: {{currentUser?.total || 0}}</div>
                <p class="paragraph">
                  {{currentUser?.user_rank  ? 'Thank you for your dedication and commitment.': 'Explore the features to get rank in leaderboard.'}}          
                </p>
              </div>  
            </div>

          </div>
          
          <div class="col-md-7">
            <div class="results-list px-md-3 bg-white">
          
              <table class="table">
                <thead>
                  <tr class="d-desktop">
                    <th scope="col">Top 10 rank</th>
                    <th scope="col">Leader name</th>
                    <th scope="col">OHR</th>
                    <th scope="col">Score</th>
                  </tr>
                  <tr  class="d-mobile">
                    <th scope="col">Rank</th>
                    <th scope="col">Leader</th>
                    <th scope="col">OHR</th>
                    <th scope="col">Score</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let user of userList; let i = index">
                    <tr *ngIf="i < 10" [ngClass]="{'currentuserRank':currentUser?.user_id === user?.user_id}">
                      <td scope="col">
                        <div class="icon-box">
                          <img *ngIf="i==0" class="rank-image" src="./assets/images/gold.png"> 
                                  <img *ngIf="i==1" class="rank-image" src="./assets/images/silver.png"> 
                                  <img *ngIf="i==2" class="rank-image" src="./assets/images/bronze.png">        
                          <span  class="rank-text" [ngClass]="{'badge-text': i < 3}">{{ user?.user_rank }}</span>
                        </div>
                      </td>
                      <td scope="col"><span class="">{{  updateName(user?.name) | titlecase }}</span></td>
                      <td scope="col"> <span class="">{{ user?.user_id }}</span></td>
                      <td scope="col"><span>{{ user?.total }}</span></td>
                    </tr>
                  </ng-container>
          
                </tbody>
              </table>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>




