<div class="quiz-container">
    <div class="start-screen" *ngIf="showMainMenu">
      <h1>AI-Powered Quiz</h1>
      <p>Test your knowledge about Artificial Intelligence!</p>
      <button (click)="startQuiz()" class="start-btn">Start Quiz</button>
    </div>
  
    <app-quiz *ngIf="showQuizScreen" (finalResult)="finalResult($event)"></app-quiz>
  
    <app-result *ngIf="showResultScreen" [finalResponse]="finalResponse"></app-result>
  </div>
  