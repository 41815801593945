<app-sidenav [item]="currentItem"></app-sidenav>
<div class="container-fluid responsive p-2 p-md-5 text-center">
  <div class="row">
    <div class="col-12 fw-bold" >

     <h3 class="mt-4">
      Please raise ticket for access, Please <a href="https://genpactindprod.service-now.com/sp?id=sc_cat_item&table=sc_cat_item&sys_id=5107df6b47c7251823a95ffbd36d43a9" target="_blank">click here</a> to request access.
      <br>
      <br>
      Thank you for your understanding.
    </h3>
    </div>

  </div>

</div>
