import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GcpService {
  endpoint = 'https://gapi1.playground-uat.genpact.com/';
  endpoint1 = environment.apiURL;
  endpoint2 = '';

  constructor(private http: HttpClient, private router: Router) { }

  sendMessage(payload: any) {
    return this.http.post(`${this.endpoint}chat`, payload);

  }

  uploadFile(payload: any) {
    return this.http.post(`${this.endpoint}upload`, payload);
  }
}
