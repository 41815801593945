import { AfterContentInit, Component, OnDestroy, OnInit, TemplateRef, Renderer2 } from '@angular/core';
import { MarkdownService } from 'ngx-markdown';
import { MessageService } from '../shared/service/message.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LoaderService } from '../shared/service/loader.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { DownloadPdfService } from '../shared/service/download-pdf.service';
import { VoiceRecognitionService } from '../shared/voice-recognition.service';
import { UserApiLimitService } from '../shared/service/user-api-limit.service';
import { SpeakService } from '../speak.service';
import { TtsReaderService } from '../tts-reader.service';
import {marked} from 'marked';

@Component({
  selector: 'app-bring-your-file',
  templateUrl: './bring-your-file.component.html',
  styleUrls: ['./bring-your-file.component.scss']
})
export class BringYourFileComponent implements OnInit, AfterContentInit, OnDestroy {
  notetext!: string;

  Isuploading: boolean = true;
  filename: string = 'tets';
  Isuploaded: boolean = false;
  searchquery: string = '';
  queryresponse: string = '';

  textTypingStarted: boolean = false;
  username: string = '';
  chkagree: boolean = false;
  showLoading: boolean = false;
  searchEnabled: boolean = false;
  isCopied1: boolean = false;
  isCopied2: boolean = false;
  isImageUploaded: boolean = false;
  startspeech: boolean = true;
  stopspeech: boolean = false;

  placeholder: string = '';

  modalRef?: BsModalRef;
  intervalId: any; // Variable to store the interval ID
  typeWriterInterval:any;
  processStatus: boolean = false; // display process btn
  tarea:boolean = true;
  combined:string= '';
  relatedQuestionList: any = [];
  relatedque:boolean = false;
  speaking: boolean = false;
  speechSynth: SpeechSynthesis | undefined;
  utterance: SpeechSynthesisUtterance | undefined;
  summary: any;
  endPointDetail: any;
  uploadedPayload:any;
  constructor(
    private MessageService: MessageService,
    private markdownService: MarkdownService,
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private toastMessage: ToastMessageService,
    public speechservice : VoiceRecognitionService,
    private downloadPdfService: DownloadPdfService,
    private renderer: Renderer2,
    private speakService: SpeakService,
    private ttsReaderService: TtsReaderService,
    private userApiLimitService: UserApiLimitService
  ) {

    this.getUserLimt();

    this.notetext = `Please be aware that the messages have been generated by an artificial intelligence
     model. While efforts have been made to provide accurate and relevant information,
      there is a possibility of errors or inaccuracies. The response you receive should not be
       considered as professional or expert advice. We strongly recommend cross-checking the
        information with reliable sources or consulting a human expert when necessary.
         The purpose of this AI is to assist and provide general information,
     but it may not always be perfect. Use the information at your own discretion.`;
     this.speechservice.init()
  }

  startService(){
    this.speechservice.start()
    this.startspeech = false;
    this.stopspeech = true;


  }

  stopService(){
    this.speechservice.stop()
    this.startspeech = true;
    this.stopspeech = false;

  }


  ngOnInit() {

    this.username = localStorage.getItem('okta-username')
      ? (localStorage.getItem('okta-username') as string)
      : 'Guest';

    const fileindex = localStorage.getItem('fileindex') || '';
    this.filename = localStorage.getItem('fileName') || 'Uploaded files.'
    this.MessageService.useraccess().subscribe(
      (res: any) => {
        this.onLoading();
        this.Isuploading = true;
        if (fileindex !== '' && fileindex === 'no') {
          // this.startInterval();
        }
      },
      (err: any) => {
        this.Isuploading = true;
        if (fileindex !== '' && fileindex === 'no') {
          // this.startInterval();
        }
      }
    );
    this.speechservice.voiceRecognitionUpdate.subscribe((updatedText: string) => {
      //// Update your prompt with the live updates
    console.log(updatedText);

  this.searchquery=updatedText;



    });

  }


  ngAfterContentInit(): void {

  }

remove(){}


  //get max count and count left of api
  getUserLimt() {
    this.userApiLimitService.getUserApiCount("upload-userfile").subscribe((res: any) => {
      if (res?.response?.status == 'success') {
        this.endPointDetail = res?.response?.data ? res?.response?.data[0] : null;
      }
    })
  }

  fileuploaded(event: any) {
    if (event !== false) {
      this.Isuploaded = true;
      this.getUserLimt();
      this.uploadedPayload = event;
      if(event?.gcp_status === "true"){
        // localStorage.getItem('fileName')
        localStorage.setItem('fileName', event?.filename);
      }

      // if (event?.gcp_status === true) {
      //   localStorage.setItem('fileindex', 'yes');
      //   this.uploadedPayload = event;
      //   this.Isuploading = false;
      //   // this.toastMessage.showSuccess('File processing successfully completed.');
      // }else{
        this.startInterval();
      // }
    }
  }

  filenameevent(event: any) {
    this.filename = event;
  }

//   copyTextToClipboard() {
//     const combinedContent = `${this.searchquery}\n${this.queryresponse}`;

//     const textarea = document.createElement('textarea');
//     textarea.style.position = 'fixed';
//     textarea.style.opacity = '0';
//     textarea.value = combinedContent;
//     document.body.appendChild(textarea);
//     textarea.focus();
//     textarea.select();

//     try {
//       document.execCommand('copy');
//       // Set the flag and display a message
//       this.isCopied = true;
//       setTimeout(() => {
//         this.isCopied = false;
//       }, 3000);
//     } catch (error) {
//       console.error('Error copying text:', error);
//     } finally {
//       document.body.removeChild(textarea);
//     }
// }

// copyTextToClipboard() {
//   // Create a temporary div element to hold the HTML content and extract text from it
//   const tempDiv = document.createElement('div');
//   tempDiv.innerHTML = this.queryresponse;

//   // Extract the text content from the temporary div
//   const textContent = tempDiv.textContent || tempDiv.innerText || '';

//   // Combine searchquery and textContent
//   const combinedContent = `${this.searchquery}\n${textContent}`;

//   // Create a temporary textarea element to copy content to clipboard
//   const textarea = document.createElement('textarea');
//   textarea.style.position = 'fixed';
//   textarea.style.opacity = '0';
//   textarea.value = combinedContent;
//   document.body.appendChild(textarea);
//   textarea.focus();
//   textarea.select();

//   try {
//     document.execCommand('copy');
//     // Set the flag and display a message
//     this.isCopied = true;
//     setTimeout(() => {
//       this.isCopied = false;
//     }, 3000);
//   } catch (error) {
//     console.error('Error copying text:', error);
//   } finally {
//     document.body.removeChild(textarea);
//   }
// }


convertMarkdownToHtml(markdown: string): string {
  return marked(markdown);
}

async copyToClipboard(markdown: string) {
  const html = this.convertMarkdownToHtml(markdown);
  try {
    await navigator.clipboard.write([
      new ClipboardItem({
        'text/html': new Blob([html], { type: 'text/html' })
      })
    ]);

    setTimeout(() => {
      this.isCopied1 = false;
      this.isCopied2 = false;
    }, 3000);
    console.log('HTML copied to clipboard');
  } catch (err) {
    console.error('Failed to copy: ', err);
  }
}



  async typeWriter(txt: string): Promise<string> {
   //this.loaderService.loading.next(true);
    this.textTypingStarted = true; // Text typing animation started
    const averageTypingSpeed = 15; // Adjust this value based on your desired speed

    const speed = Math.max(10, Math.min(averageTypingSpeed, averageTypingSpeed + txt.length * 5));

    return new Promise<string>((resolve) => {
      let i = 0;
       this.typeWriterInterval = setInterval(() => {
        if (i < txt.length) {
          this.queryresponse += txt.charAt(i);
          i++;
        } else {
          clearInterval(this.typeWriterInterval);
          this.typeWriterInterval = null;
          resolve(this.queryresponse); // Resolve with the fully typed text
        }
      }, speed);
    });

  }

  // toggleSpeaking() {
  //   this.speaking = !this.speaking;
  //   if (this.speaking) {
  //     this.speechSynth = window.speechSynthesis;
  //     if (this.speechSynth && !this.speechSynth.speaking && this.queryresponse.length > 0) {
  //       this.utterance = new SpeechSynthesisUtterance(this.queryresponse);
  //       this.speechSynth.speak(this.utterance);
  //       this.utterance.onend = () => {
  //         this.speaking = false;
  //       };
  //     }
  //   } else {
  //     if (this.speechSynth && this.utterance) {
  //       this.speechSynth.cancel();
  //       this.speaking = false;
  //     }
  //   }
  // }
  // toggleSpeaking() {
  //   this.speaking = !this.speaking;
  //   if (this.speaking) {
  //     this.speechSynth = window.speechSynthesis;
  //     if (this.speechSynth && !this.speechSynth.speaking && this.queryresponse.length > 0) {
  //       this.utterance = new SpeechSynthesisUtterance(this.queryresponse);
  //       const googleUSVoice = this.speechSynth.getVoices().find(voice => voice.name === 'Google US English' && voice.lang === 'en-US');
  //       if (googleUSVoice) {
  //         this.utterance.voice = googleUSVoice;
  //       } else {
  //         console.log('Google US English voice not found. Using default voice.');
  //       }
  //       this.speechSynth.speak(this.utterance);
  //       this.utterance.onend = () => {
  //         this.speaking = false;
  //       };
  //     }
  //   } else {
  //     if (this.speechSynth && this.utterance) {
  //       this.speechSynth.cancel();
  //       this.speaking = false;
  //     }
  //   }
  // }

  // toggleSpeaking() {
  //   this.speaking = !this.speaking;
  //   if (this.speaking) {
  //     if (this.queryresponse.length > 0) {
  //       this.speakService.speak(this.queryresponse);
  //     }
  //   } else {
  //     this.speakService.cancel();
  //     this.speaking = false;
  //   }
  // }


  onSearchButtonClicked() {
    if(!(this.searchquery.trim()=== '')) {
    if (this.searchEnabled) {
      this.sendByodRequestWithCheckbox();
    } else {
      this.sendBYODRequests();
    }
  }
  else{
    this.toastMessage.showError('Please enter the question');
  }
  }
submitQuestion(question:any){
 // const urlWithoutQuery = question;
  const questionWithoutNumber = question.replace(/^\d+\.\s*/, '');
  this.searchquery = questionWithoutNumber;
 // this.onSearchButtonClicked();
}
  sendByodRequestWithCheckbox() {
    if (this.searchEnabled) {
      this.queryresponse = '';
    //  this.showLoading = true; // Show loading animation
      this.loaderService.loading.next(true);

      // Replace this with your actual service call
      this.MessageService.sendFormRequests(this.searchquery || this.speechservice.text).subscribe(
        (res: any) => {
       //   this.showLoading = false; // Hide loading animation
          this.loaderService.loading.next(false);
          if (res?.response?.response?.answer) {
            this.queryresponse = this.markdownService.parse(this.queryresponse + res?.response?.response?.answer);
            // this.queryresponse = this.queryresponse + res?.response?.response?.answer;

          } else {
            this.toastMessage.showError(res?.response?.response)
          }
        },
        (error: any) => {
        //  this.showLoading = false;
          this.loaderService.loading.next(false);
          this.toastMessage.showError(error?.message);
        }
      );
    }
  }

  // check FileUpload status
  checkFileUploadStatus() {
    this.loaderService.loading.next(true);
    this.processStatus = true;
    localStorage.setItem('fileindex', 'no');
    this.MessageService.checkFileStatus()
      .subscribe(
        (res: any) => {
          if (res?.response === true) {

            localStorage.setItem('fileindex', 'yes');
            this.Isuploading = false;

            this.stopInterval();
            this.loaderService.loading.next(false);
            this.toastMessage.showSuccess('File processing successfully completed.');


          } else {
            if (res?.response == false) {
              this.toastMessage.showWarning('File processing in progress.');
              // do nothing
            } else {
              this.stopInterval();
              this.loaderService.loading.next(false);
              this.toastMessage.showError(res?.message);
            }
          }

        },
        (error: any) => {
          // Handle the error here
          this.stopInterval();
          this.loaderService.loading.next(false);
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );

  }

// check byod status
checkbyoddStatus() {
  this.loaderService.loading.next(true);
  this.processStatus = true;
  localStorage.setItem('fileindex', 'no');
  this.relatedQuestionList = [];


  this.MessageService.checkbyodStatus(this.uploadedPayload)
    .subscribe(
      (res: any) => {
        if (res?.message === 'Document has been uploaded successfully') {

          localStorage.setItem('fileindex', 'yes');
          this.Isuploading = false;
          if(res?.data != 'null') {
            this.relatedQuestionList = res?.data.filter((item: any) => item !== '') || [];
          }
          
          if(res?.summary) {
            this.summary = res?.summary;
            this.scrollToBottom();
          }
          

          if(this.relatedQuestionList.length > 0){
            this.relatedque = true;
          }

          this.stopInterval();
          this.loaderService.loading.next(false);
          this.toastMessage.showSuccess('File processing successfully completed.');


        } else {
          if (res?.data == 'null') {
            this.toastMessage.showWarning('File processing in progress.');
            // do nothing
          }
          else if(res?.status === 'failed'){
            this.stopInterval();
            this.loaderService.loading.next(false);
            this.toastMessage.showError(res?.message);

          }

          else {
            this.stopInterval();
            this.loaderService.loading.next(false);
            this.toastMessage.showError(res?.message);
          }
        }

      },
      (error: any) => {
        // Handle the error here
        this.stopInterval();
        this.loaderService.loading.next(false);
        console.error(error);
        this.toastMessage.showError(error?.message);
      }
    );

}
  sendBYODRequests() {
    this.tarea= false;
    this.queryresponse = '';
   // this.showLoading = true; // Show loading animationthis
    this.loaderService.loading.next(true);
        // Check if there is an item in localStorage named "azure" with a value of "yes"
        const azureValue = localStorage.getItem('Azure');

        const apiFunction = azureValue === 'yes' ? 'sendazurerequests' : 'sendBYODRequests';

    this.MessageService[apiFunction](this.searchquery || this.speechservice.text).subscribe(
      async (res: any) => {
      //  this.showLoading = false; // Hide loading animation
        this.loaderService.loading.next(false);
        console.log(res)
        if (res?.status == 'success') {
          this.scrollToBottom();
          this.toastMessage.showSuccess(res?.message);
           const responseText = await this.typeWriter(res?.data);
        //  const responseText =res?.data;
          this.queryresponse = responseText;
          this.tarea= true;
          this.scrollToBottom();
        //  this.toastMessage.showSuccess(this.combined);
        } else {
          this.toastMessage.showError(res?.message);
          this.tarea= true;
        }
      },
      (error: any) => {
        // Handle the error here
     //   this.showLoading = false;
        this.loaderService.loading.next(false);
        this.tarea= true;
        this.toastMessage.showError(error?.message);
      }
    );
  }




  onSearchKeyPress(event: any) {
    if (event.key === 'Enter') {
      if (this.searchEnabled) {
        this.sendByodRequestWithCheckbox();
      } else {
        event.preventDefault();
        this.sendBYODRequests();
      }
    }
  }

  scrollToBottom(): void {
    setTimeout(() => {
      try {
        document.getElementById("targetRed")?.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest"
        });
      } catch (err) {
        console.error('Error scrolling to bottom:', err);
      }
    }, 1000);
  }

  onLoading() {
    const modalbtn = document.getElementById('byod_policy_model') as HTMLLIElement;
    modalbtn.click();
  }

  // Open model
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md',ignoreBackdropClick: true });
  }

  // checking status of file per INterver till successed

  startInterval(): void {

    //this.checkFileUploadStatus();
   this.checkbyoddStatus();

    this.intervalId = setInterval(() => {
      // Your function to be called every 15 seconds
      console.log('interval call')
    //  this.checkFileUploadStatus();
      this.checkbyoddStatus();

    }, 15000); // 15 seconds in milliseconds


  }

  stopInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.processStatus = false;
      this.intervalId = null;
    }
  }

  resetForm(event: any) {
    console.log(event)
    localStorage.setItem('fileindex', 'yes');
    this.Isuploading = true;

    this.stopInterval();
    this.loaderService.loading.next(false);
  }

  downloadSummaryAsPdf() {
    this.downloadPdfService.downloadAsPdf("summary-download" ,'summary.pdf');
}

  downloadAsPdf() {
    this.downloadPdfService.downloadAsPdf("text-download" ,'downloaded-file.pdf');
}

  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed
    this.stopInterval();
  }
}
