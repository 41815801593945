import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LoaderService } from '../shared/service/loader.service';
import { AfterViewInit } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { MetaThumbnailService } from '../shared/service/meta-thumbnail.services';
import { PreplexityService } from "../shared/service/perplexity.service";
import { SusidiaryService } from '../shared/service/susidiary.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { NgxSpinnerService } from "ngx-spinner";
import { filter, forkJoin } from 'rxjs';
import { VoiceRecognitionService } from '../shared/voice-recognition.service';
import { DownloadPdfService } from '../shared/service/download-pdf.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-subsidiary',
  templateUrl: './subsidiary.component.html',
  styleUrls: ['./subsidiary.component.scss']
})
export class SubsidiaryComponent implements OnInit, OnDestroy {
  @ViewChild('scrollResearch', { static: false }) scrollContainer!: ElementRef;
  notetext: string = "By selecting this check box, you will enable the crawling process to gather data from all websites, ensuring that the content users receive is comprehensive and extensive.";
  userQuestion: string = '';
  relatedQuestionList: any = [];
  relatedUrl: string[] = [];
  prompt: string = '';
  researchData: any = [];
  submitButtonClicked: boolean = false;
  showHeading: boolean = true;
  checkb: boolean = false;
  deepsearch: boolean = false;
  checkb1: boolean = true;
  checkbsortby: boolean = true;
  relatedQuestion: string = '';
  response: string = '';
  // qnaList: any = [];
  selectedFreshness: string = 'Day';
  selectedContent: string = 'news';
  selectedSortby: string = 'relevance';
  maxLink: any = 5;
  selectedLink: any;
  startspeech: boolean = true;
  stopspeech: boolean = false;
  form: FormGroup;
  customText: string = '';
  stopTyping = false;
  loadingUrl: string = '';
  suggestedUrl: string = '';
  psearch:boolean=false;
  psearch1:boolean=false;
  customUrl:string = '';
imageurl:any;
content:any;
personurl: string[]=[];
dropdownSettings: IDropdownSettings = {
  singleSelection: true,  // or false if you want multiple selections
  idField: 'id',          // 'id' is the unique identifier in your options array
  textField: 'name',      // 'name' is the field used for display text
  itemsShowLimit: 3,
  maxHeight: 160,
  allowSearchFilter: true
};
dropdownSettings1: IDropdownSettings = {
  singleSelection: false,  // or false if you want multiple selections
  idField: 'id',          // 'id' is the unique identifier in your options array
  textField: 'name',      // 'name' is the field used for display text
  itemsShowLimit: 3,
  maxHeight: 160,
  allowSearchFilter: true
};
source = [
  { id: 'es-AR', name: 'Argentina (Spanish)' },
  { id: 'en-AU', name: 'Australia (English)' },
  { id: 'de-AT', name: 'Austria (German)' },
  { id: 'nl-BE', name: 'Belgium (Dutch)' },
  { id: 'fr-BE', name: 'Belgium (French)' },
  { id: 'pt-BR', name: 'Brazil (Portuguese)' },
  { id: 'en-CA', name: 'Canada (English)' },
  { id: 'fr-CA', name: 'Canada (French)' },
  { id: 'es-CL', name: 'Chile (Spanish)' },
  { id: 'da-DK', name: 'Denmark (Danish)' },
  { id: 'fi-FI', name: 'Finland (Finnish)' },
  { id: 'fr-FR', name: 'France (French)' },
  { id: 'de-DE', name: 'Germany (German)' },
  { id: 'zh-HK', name: 'Hong Kong SAR (Traditional Chinese)' },
  { id: 'en-IN', name: 'India (English)' },
  { id: 'en-ID', name: 'Indonesia (English)' },
  { id: 'it-IT', name: 'Italy (Italian)' },
  { id: 'ja-JP', name: 'Japan (Japanese)' },
  { id: 'ko-KR', name: 'Korea (Korean)' },
  { id: 'en-MY', name: 'Malaysia (English)' },
  { id: 'es-MX', name: 'Mexico (Spanish)' },
  { id: 'nl-NL', name: 'Netherlands (Dutch)' },
  { id: 'en-NZ', name: 'New Zealand (English)' },
  { id: 'no-NO', name: 'Norway (Norwegian)' },
  { id: 'zh-CN', name: "People's Republic of China (Chinese)" },
  { id: 'pl-PL', name: 'Poland (Polish)' },
  { id: 'en-PH', name: 'Philippines (English)' },
  { id: 'ru-RU', name: 'Russia (Russian)' },
  { id: 'en-ZA', name: 'South Africa (English)' },
  { id: 'es-ES', name: 'Spain (Spanish)' },
  { id: 'sv-SE', name: 'Sweden (Swedish)' },
  { id: 'fr-CH', name: 'Switzerland (French)' },
  { id: 'de-CH', name: 'Switzerland (German)' },
  { id: 'zh-TW', name: 'Taiwan (Traditional Chinese)' },
  { id: 'tr-TR', name: 'Turkey (Turkish)' },
  { id: 'en-GB', name: 'United Kingdom (English)' },
  { id: 'en-US', name: 'United States (English)' },
  { id: 'es-US', name: 'United States (Spanish)' }
];
sourceurl:any;
sourceurls: string[] = ["www.dlhitech.gov.cn","liaoning.chinatax.gov.cn","hrss.gd.gov.cn","rsj.sh.gov.cn","foshan.gov.cn","com.gd.gov.cn","ks.gov.cn","commerce.suzhou.gov.cn"];
// sourceurls: string[] = ["https://en.wikipedia.org"];

selectedpolicyurl = [];
nrmlurl = [];
selectedurl:string[] = [];
showDropdown = false;  // Controls the visibility of the dropdown menu
optionList: string[] = ['Option 1', 'Option 2'];
selectedOptions: Set<string> = new Set();

  customFromDate!: string;
  customToDate!: string;

Market:any;
  randomInt = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

  researchModeApiHits= 0;
  constructor(
    private toastMessage: ToastMessageService,
    private loaderService: LoaderService,
    private perplxityService: SusidiaryService,
    private downloadPdfService: DownloadPdfService,
    public metaThumbnailService: MetaThumbnailService,
    public speechservice: VoiceRecognitionService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder
  ) {
    // this.policystatus();
    this.speechservice.init();
    this.form = this.fb.group({
      selectedFreshness: [''],
      selectedContent: [''],
      selectedSortby: ['']
    });
  }


  ngOnInit() {
    if(localStorage.getItem('sourceurls1') != null){
      this.sourceurls = JSON.parse(localStorage.getItem('sourceurls1') || '[]');
      }
    this.optionSelected2();
    this.Market = [{ id: 'en-IN', name: 'India (English)' }];
    console.log('checkb', this.checkb)
    this.speechservice.voiceRecognitionUpdate.subscribe((updatedText: string) => {
      //// Update your prompt with the live updates
    console.log(updatedText);

  this.prompt=updatedText;



    });
    // this.loadOptions();
    // this.form1 = new FormGroup({
    //   selectControl: new FormControl('')
    // });

    // // Safe access using non-null assertion operator (!) to tell TypeScript that the object is not null
    // this.form1.get('selectControl')!.valueChanges.subscribe(value => {
    //   if (value === 'addNew') {
    //     // Reset the control to avoid confusion
    //     this.form1.get('selectControl')!.setValue('', { emitEvent: false });
    //     // Prompt the user for a new option
    //     const newOption = prompt('Please enter a new option:');
    //     if (newOption && !this.options.includes(newOption)) {
    //       this.options.push(newOption);
    //       this.saveOptions();
    //     }
    //   } else if (value) {
    //     // Handle other values normally
    //     this.selectedOptions.push(value);
    //   }
    // });

  }
  startService() {
    this.speechservice.start()
    this.startspeech = false;
    this.stopspeech = true;


  }

  stopService() {
    this.speechservice.stop()
    this.startspeech = true;
    this.stopspeech = false;

  }

  // loadOptions(): void {
  //   const savedOptions = localStorage.getItem('options');
  //   if (savedOptions) {
  //     this.options = JSON.parse(savedOptions);
  //   }
  // }

  // saveOptions(): void {
  //   localStorage.setItem('options', JSON.stringify(this.options));
  // }




  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
  }

  toggleOption(option: string): void {
    if (this.selectedOptions.has(option)) {
      this.selectedOptions.delete(option);
    } else {
      this.selectedOptions.add(option);
    }
  }

  addOption(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    if (event.key === 'Enter' && input.value) {
      if (!this.optionList.includes(input.value)) {
        this.optionList.push(input.value);
        this.selectedOptions.add(input.value); // Automatically select the newly added option
      }
      input.value = ''; // Clear the input after adding
    }
  }


  submitForm() {
    // Create your payload using the selected values
    const payload = {
      freshness: this.selectedFreshness === 'Custom' ? `${this.customFromDate}..${this.customToDate}` : this.selectedFreshness,
      content: this.selectedContent,
      sortby: this.selectedSortby
    };

    // Send the payload to your API or perform any other action
    console.log('Payload:', payload);
    this.cdr.detectChanges();
  }
  getOgData(urls: any) {
     this.loaderService.loading.next(true);
    return new Promise((reslove, reject) => {
      const urlRequests = urls.map((url: any) => this.metaThumbnailService.getOgDetail(url));
      forkJoin(urlRequests).subscribe((res: any) => {
        this.loaderService.loading.next(false);

        if (res?.length > 0) {
          let final = res.map((ele: any) => ele?.response?.data);

          reslove(final);
        } else {
          reslove(null);
        }
      }, (error) => {
        // Handle the error here
        this.loaderService.loading.next(false);
        console.error(error);
      });
    })
  }

  async submitQuestion(value: any) {
    let payload: any = { query: value,   freshness: this.selectedFreshness  === 'Custom' ? `${this.customFromDate}..${this.customToDate}` : this.selectedFreshness,
      content_panels: this.selectedContent,
      sortby: this.selectedSortby,
      "market":this.Market[0].id

    };

  if(this.suggestedUrl.trim() != ''){
    payload.site= this.suggestedUrl
  }

    if (this.checkb) {
      payload['research_mode'] ="true";
       this.spinner.show();
      }
      else if(this.checkbsortby)
      {
       // payload['sortby'] = 'Date';
        this.spinner.show();
      }
       else{
     this.loaderService.loading.next(true);
       }


    this.perplxityService.relatedQuestionLinks(payload)
      .subscribe(
        async (res: any) => {
          this.spinner.hide();
          if ( res?.data == '') {
            this.toastMessage.showError('No data was retreived for the suggested url/sources.Please recheck the url or try again later.');
            return;
          }
          if (this.checkb== true && res?.data != '')
        {
          //  this.toastMessage.showSuccess(res?.research_name);
           this.customText = res?.research_name;
           this.spinner.show();
          this.submitCollection(res, payload);
        }
        else if (res?.data != '')
        {
          this.submitResponse(res, payload);
          this.loaderService.loading.next(false);
        }
          if (res?.status == 'success') {
            this.stopTyping = false;
            this.showHeading = false;
            this.relatedQuestionList = [];
            // let urlsList = await this.getOgData(res?.urls);

            let urlsList: any = res?.data?.map((ele: any) => {
              return {
                url: ele?.url,
                ogImage: ele?.thumbnailUrl,
                description: ele?.snippet,
                title: ele?.name
              }
            });
            // console.log('result', urlsList)
            let questionAnswer = {
              question: value,
              answer: res?.response,
              url: []
            }
            if (this.checkb != true)
            {
            this.researchData.push(questionAnswer);
            }
            this.scrollToBottom();

            for (let index = 0; index < urlsList.length - 1; index++) {
              this.researchData[this.researchData.length - 1]['url'].push(urlsList[index]);

              if (urlsList?.length, index, urlsList[index]['description']) {
                await this.typeWriter(urlsList[index].description, index);
                this.scrollToBottom();
              }
            }


            this.relatedQuestionList = res?.questions || [];

            // this.toastMessage.showSuccess(res?.message);
            this.submitButtonClicked = true;
          } else {
            this.toastMessage.showError(res?.message);
          }

        },
        (error) => {
          // Handle the error here
          this.loaderService.loading.next(false);
          this.spinner.hide();
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );
  }

  async submitCollection(res: any, query: any) {
  let data:any={
  "research_mode":"true",
  data: res?.data,
  freshness: this.selectedFreshness === 'Custom' ? `${this.customFromDate}..${this.customToDate}` : this.selectedFreshness,
  content_panels: this.selectedContent,
  sortby: this.selectedSortby,
  ...query,

}

if (this.suggestedUrl.trim() != '') {
  data.site = this.suggestedUrl;
}
// this.suggestedUrl?data.append("url":this.suggestedUrl):

 this.perplxityService.relatedQuestionCollection(data)
      .subscribe(
        async (res: any) => {
          //  this.loaderService.loading.next(true);
          this.customText = res?.message;
           this.spinner.show();

           this.loadingUrl = res?.urls;

          this.stopTyping = true;
          if (res?.status == 'success')
          {
            this.submitResponse1(res?.data, query);
            this.scrollToBottom();
          } else {
            // this.loaderService.loading.next(false);
            this.spinner.hide();
            this.toastMessage.showError(res?.message);
          }

        },
        (error) => {
          // Handle the error here
          console.error(error);
          this.spinner.hide();
          this.toastMessage.showError(error?.message);
        }
      );

  }

  async submitResponse(data: any, query: any) {
    this.spinner.show();
    let payload = {
      freshness: this.selectedFreshness === 'Custom' ? `${this.customFromDate}..${this.customToDate}` : this.selectedFreshness,
      content_panels: this.selectedContent,
      sortby: this.selectedSortby,
      ...query,
      data: data?.data,
    }
if(this.suggestedUrl.trim() != ''){
payload.site= this.suggestedUrl
}

     this.loaderService.loading.next(true);
    this.perplxityService.relatedQuestionContent(payload)
      .subscribe(
        async (res: any) => {
          //  this.loaderService.loading.next(false);
          this.stopTyping = true;
          if (res?.status == 'success') {
this.spinner.hide();
            let urlsList = await this.getOgData(res?.urls);

            this.researchData.splice(this.researchData?.length - 1, 1);


            let questionAnswer:any = {
              question: query.query,
              answer: res?.response,
              url: urlsList || []
            }

            this.researchData.push(questionAnswer);
            this.prompt = '';
            this.speechservice.text = '';

            this.relatedQuestionList = res?.questions || [];

            // this.toastMessage.showSuccess(res?.message);
            this.submitButtonClicked = true;
            this.showHeading = false;

            this.scrollToBottom();
          } else {
            this.spinner.hide();
            this.toastMessage.showError(res?.message);
          }


        },
        (error) => {
          // Handle the error here
          // this.loaderService.loading.next(false);
          this.spinner.hide();
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );
  }


  async submitResponse1(data: any, query: any) {
    let payload = {
      "data": data,
      ...query
    }
    this.spinner.show();

    // this.loaderService.loading.next(true);
    this.perplxityService.relatedQuestionContent(payload)
      .subscribe(
        async (res: any) => {
          // this.loaderService.loading.next(false);
          this.spinner.hide();
          this.stopTyping = true;
          this.researchModeApiHits++;

           if(res?.response == 'false' && 10 > this.researchModeApiHits)
            {


              // this.loaderService.loading.next(true);
              this.customText = this.loadingUrl[this.randomInt(0, this.loadingUrl.length - 1)];
              this.spinner.show();
              setTimeout(() => {
                this.customText='Formatting the responses';
                this.spinner.show();
                this.submitResponse1(res, query);
              }, 15000);
            }

            if(this.researchModeApiHits >= 10){
              this.toastMessage.showWarning('Please try again later!');
              this.researchModeApiHits =0;
              setTimeout(() => {
                location.reload();
              }, 1000);
            }

          if (res?.response != 'false')
          {

            // let urlsList = await this.getOgData(res?.urls);

            // this.researchData.splice(this.researchData?.length - 1, 1);


            let questionAnswer:any = {
              question: query.query,
              answer: res?.response,
              //url: urlsList || []
            }
            // console.log(query.query);
            this.researchData.push(questionAnswer);
            this.prompt = '';
            this.speechservice.text = '';
            this.scrollToBottom();



            // this.relatedQuestionList = res?.questions || [];
            // this.toastMessage.showSuccess(res?.message);
            this.submitButtonClicked = true;
            this.showHeading = false;

            // this.toastMessage.showSuccess(res?.response);

          }

        },
        (error) => {
          // Handle the error here
          // this.spinner.hide();
        //  this.loaderService.loading.next(false);
          console.error(error);
          this.spinner.hide();
          this.toastMessage.showError(error?.message);
        }
      );
  }

  back()
  {
    location.reload();
  }


  addQnA(relatedQuestion: string, response: string) {
    const qna = { relatedQuestion, response };
    this.researchData.push(qna);
  }


  //typing

  async typeWriter(txt: any, index: any): Promise<string> {
    console.log(index, txt)

    this.researchData[this.researchData.length - 1]['url'][index]['description'] = '';

    const averageTypingSpeed = 1; // Adjust this value based on your desired speed

    const speed = Math.max(10, Math.min(averageTypingSpeed, averageTypingSpeed + txt.length * 5));

    return new Promise<string>((resolve, reject) => {
      let i = 0;
      const typeWriterInterval = setInterval(() => {

        if (i < txt.length && !this.stopTyping) {
          this.researchData[this.researchData.length - 1]['url'][index]['description'] += txt.charAt(i);
          i++;
        } else {
          clearInterval(typeWriterInterval);
          console.log('ok', index)
          resolve('ok'); // Resolve with the fully typed text
        }
      }, speed);
    });
  }


  // scoroll to bottom in chat window
  scrollToBottom() {
    setTimeout(() => {
      const container = this.scrollContainer.nativeElement;
      container.scrollTop = container.scrollHeight;
    }, 100);
  }

  ngOnDestroy(): void {

  }

  downloadAsPdf() {
    this.downloadPdfService.downloadAsPdf("text-d", 'downloaded-file.pdf');
  }
  optionSelected() {
    // Perform actions based on the selected option
   this.checkb=false;
 this.checkb1=true;
 this.psearch=false;


  }
  optionSelected1() {
   this.checkb=true;
    this.checkb1=false;
    this.checkbsortby=false;
    this.psearch=false;
  }
  optionSelected2(){
    this.psearch=true;
    this.checkb=false;
    this.checkb1=false;

  }

  submitQuestion1(prompt:any){
    this.spinner.show()
    let payload: any = {
      "market":this.Market[0].id,
      freshness: this.selectedFreshness === 'Custom' ? `${this.customFromDate}..${this.customToDate}` : this.selectedFreshness,
      "sites": this.sourceurl,
      query: prompt };

if(this.deepsearch){
  payload['deep_search'] = "true";
}

    this.perplxityService.policysearch(payload)
      .subscribe(
        async (res: any) => {
          this.spinner.hide();

          if (res?.status == 'success') {
            // this.showHeading=false;
            this.psearch1 = true;
            this.imageurl= res?.thumbnail;
            this.content= res?.response;
            // this.personurl=Array.from(new Set(res?.urls));
            this.selectedpolicyurl = res?.urls;
            this.nrmlurl = res?.urls;
if(this.deepsearch){
document.getElementById('togbtn')?.click();

}

            if(this.deepsearch == false)
              {
            this.policystatus();
              }
          }


        },
        (error) => {
          // Handle the error here
          this.loaderService.loading.next(false);
          this.spinner.hide();
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );

  }

  policystatus() {
    this.spinner.show();
    this.perplxityService.policyStatus().subscribe(
      (res: any) => {
        if (res?.status === 'success' && res?.message !== 'report is not generated yet') {
          this.spinner.hide();
          this.showHeading = false;
          this.psearch1 = true;
          // this.personurl=Array.from(new Set(res?.urls));

          this.imageurl = res?.thumbnail;
          this.content = res?.response;
          // this.personurl = Array.from(new Set(res?.urls));


        }
        if (res?.message === 'report is not generated yet') {
          // Schedule next call only if response is false
          this.toastMessage.showWarning(res?.message);
          setTimeout(() => this.policystatus(), 15000);
        }
        // else {
        //   // Optionally handle the case where status is not 'success'
        //   setTimeout(() => this.policystatus(), 15000);
        // }
      },
      (error) => {
        this.spinner.hide();
        console.error(error);
        this.toastMessage.showError(error?.message || 'An error occurred');

        // Optionally schedule next call on error
        setTimeout(() => this.policystatus(), 15000);
      }
    );
  }

  policycrawl() {

    let payload: any = {
      // freshness: this.selectedFreshness,
      "selected_sites": this.selectedurl,
      query: this.prompt };

if(this.deepsearch){
  payload['deep_search'] = "true";
}


this.perplxityService.policycrawl(payload)
      .subscribe(
        async (res: any) => {
          this.spinner.hide();

          if (res?.status == 'success') {
            // this.showHeading=false;
            this.personurl=Array.from(new Set(this.selectedurl));

            this.toastMessage.showSuccess(res?.message);
            this.policystatus();

          }


        },
        (error) => {
          // Handle the error here
          this.loaderService.loading.next(false);
          this.spinner.hide();
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );



  }

  updateurl(){

    this.sourceurls = [...this.sourceurls, this.customUrl];
       //   this.customUrl = '';
       localStorage.setItem('sourceurls1', JSON.stringify(this.sourceurls));

      this.toastMessage.showSuccess('url added successfully');
      this.customUrl = '';
  }
  // onSelectionChange(): void {
  //   if (this.selectedFreshness === 'Custom' && this.customFromDate && this.customToDate) {
  //     this.updateFormattedCustomRange();
  //   } else if (this.selectedFreshness !== 'Custom') {
  //     this.selectedFreshness = this.selectedFreshness;
  //   }
  // }

  // updateFormattedCustomRange(): void {
  //   this.selectedFreshness = `${this.customFromDate}..${this.customToDate}`;
  // }
  toggleModal(): void {
    if (this.selectedFreshness === 'Custom') {
      document.getElementById('togbtn1')?.click();
        }

  }


}

