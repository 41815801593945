import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TtsReaderService {
  private audio!: HTMLAudioElement;

  constructor(private http: HttpClient) { }

  speakMyText(text: string) {
    const url = `https://api.ttsreader.com/v1/tts/speak?text=${encodeURIComponent(text)}&voice=en-in-f-mp3`;
    this.http.get(url, { responseType: 'blob' }).subscribe((response: Blob) => {
      const audioUrl = URL.createObjectURL(response);
      this.audio = new Audio(audioUrl);
      this.audio.play();
    });
  }

  stopSpeaking() {
    if (this.audio) {
      this.audio.pause();
      this.audio.currentTime = 0;
    }
  }
}
