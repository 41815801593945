import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SystranService {
  apiURL = environment.apiURL;
  languageApi = environment.languageApi;

  constructor(private http: HttpClient) { }

  translatetext(payload: any) {
    return this.http.post(`${this.apiURL}/translate-text-aon`, payload);
  }
  translatetext1(payload: any) {
    return this.http.post(`${this.apiURL}/translate-text`, payload);
  }
  dictionary(payload: any) {
    return this.http.post(`${this.apiURL}/get-dictionary`, payload);
  }

  storelogs(payload: any) {
    return this.http.get(`${this.apiURL}/store_logs`,payload);
  }
  logsStorage(payload: any) : Observable<any> {
    return this.http.post<any>(`${this.apiURL}/store_logs`, payload);
  }
}
