import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ToastMessageService } from './shared/service/toast-message.service';
import { Location } from '@angular/common';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private router: Router,
    private toastMessage: ToastMessageService,
    private location: Location
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token: any = localStorage.getItem('token');

    // add bearer before specific token  starts
    let bearerArray = ['translate_upload', 'translate_start', 'translate_status',
      'chat_history', 'delete_p', 'old_chat', 'chat_update', 'new_chat',
      'upload_video', 'status', 'transcript', 'uploading', 'submitting', 'image-generate',
      'upload-jd', 'upload-cv', 'calculate-match-jd', 'calculate-match-cv', 'submit-match',
      'recent_bing', 'recent_response', 'sentiments-analysis', 'recent_collection', 'translate_upload_glossary',
      'translate_upload_image', 'init', 'upload', 'list', 'manage', 'export', 'process', 'translate-text',
      'get-dictionary', 'azure_csv_resp', 'analyze_sentiment', 'image-modify', 'byod-status', 'getanswer', 'uploader',
      'translate', 'download', 'store_logs', 'translate_upload_image', 'translate_download_file', 'translate_delete_files',
      'queryVS-status','store_logs','subsidy-policy-search','subsidy-policy-status','subsidy-policy-crawl',
      'download-transcript-file','azure-dalle3','gpt-4o','create-assistant','first-query','add-query','run-status','modify-assistant',
      'chat-bot','translate_start_aon','translate-text-aon' ,'text-translate', 'ask-genpact' ,'query','chat','upload','chat-bot-stream'
    ];
    // Get the API URL endpoint
    let apiEndpoint = request.url.split('/');
    let finalUrl = apiEndpoint[apiEndpoint?.length - 1].split('?')[0];


    if (bearerArray.includes(finalUrl)) {
      token = 'Bearer ' + token;
    }
    // add bearer before specific token  end

    request = request.clone({
      headers: request.headers.set('authorization', token)
    })
    return next.handle(request).pipe(
      catchError(errorData => {
        console.log(errorData)
        if (errorData.status === 409 ||
          errorData.status === 403
          || errorData.status === 422) {
          if (window.document.location.hostname === 'localhost') {
            this.router.navigate(['/unauthorized']);
            // this.toastMessage.showError(errorData?.error?.message);
            return throwError(errorData)
          } else {
            this.router.navigate(['/unauthorized']);
            return throwError(errorData)
          }

        } else if (errorData.status === 401) {
          const currentUrl = this.location.path();
          localStorage.setItem('p_redirect', currentUrl)
          this.router.navigate(['/']);
        }
        else if (errorData.status === 422) {

        }
        else if (errorData.status === 400) {
          this.toastMessage.showError(errorData?.error?.error?.message || errorData?.error?.message);
        }
        else if (errorData.status === 404) {

        }
        else if (errorData.status === 429) {
          console.log('hello', errorData?.error?.message)
          this.toastMessage.showError(errorData?.error?.message);
        }
        return throwError(errorData)
      })

    )
  }
}


export const AuthInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: AuthInterceptor,
  multi: true
}
