<app-sidenav></app-sidenav>
<div class="container-fluid px-md-5 mt-md-2">
  <div class="row my-5">
    <div class="col-md-9 pr-md-4 d-grid">


      <div class="row align-content-start">

        <div class="col-12">
          <div class="row mb-3">
            <div class="col-12">
              <h5 class="display-12 text-left headtext">Welcome to Generative AI Playground</h5>
              <p class="sub-title">Your genpact certified safe space for gen AI-enabled innovation</p>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-6" *ngFor="let list of tablinkData">
          <div class="card-container" [routerLink]="[list.link]">
            <div class="card">
              <div class="front-content">
                <p>{{list.name}}</p>
                <div>
                  <img [src]="list.icon" alt="icon" class="mb-3 w-48 thumb-img" />
                </div>
              </div>
              <div class="content">
                <p class="heading">{{list.name}}</p>
                <p class="m-0">
                  {{list.description}}
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>


      <div class="row bottom-section  align-items-end">
        <div class="col-12">
          <div class="content shadow">
            <img style="height: 5px; width:100%;" src="assets/img/gbar.png" alt="icon" class="mb-3 w-48" />
            <div class="item1 px-3">
              <div class="">
                <div style="display:flex; flex-direction: row; gap:4px">
                  <img style="height: 12px;position: relative;top: 4px;" class="arrow-icon" src="assets/images/arrow.png" alt="" />

                  <p>Please consult Genpact’s
                    <a class="text-skyblue cursor-pointer" style="text-decoration: underline; "
                      href="https://genpactonline.sharepoint.com/sites/Legal/Compliance/Shared%20Documents/Integrity/Code%20of%20Conduct.pdf"
                      target="_blank"> Code of Conduct document</a>
                    and <a class="text-skyblue cursor-pointer" style="text-decoration: underline; "
                      href="https://genpactonline.sharepoint.com/sites/Legal/Compliance/Shared%20Documents/PolicyCentralold/Use%20of%20Generative%20AI%20Programs%20Policy.pdf"
                      target="_blank">Use of Generative AI Programs Policy</a> before using Generative AI Playground or
                    any other gen AI platform.
                  </p>
                </div>
              </div>

              <div class="">
                <div style="display:flex; flex-direction: row; gap:4px">
                  <img style="height: 12px;position: relative;top: 4px;" class="arrow-icon" src="assets/images/arrow.png" alt="" />
                  <p>Learn more about <a class="text-skyblue cursor-pointer" style="text-decoration: underline;"
                      href="https://genpactonline.sharepoint.com/sites/IT/Documents/Policies/Open%20AI%20policy/Azure%20openai%20IT%20enablement-%20v3.pdf"
                      target="_blank">Azure OpenAI IT enablement</a>.</p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>

    </div>

    <div class="col-md-3 mt-3 mt-md-0">
      <div class="row scroll-container-home">


          <div class="col-12">
            <div class="leader-board-section">
            <div class="justify-content-center rank-block">
              {{currentMonth}}  Leaderboard
            </div>
            <a href="javascript:;" class="rank-block rank-score mb-3" [routerLink]="['/leaderboard']" title="Leaderboard">
              <div class="link-section">
                Rank:
              {{currentUser?.user_rank || 0}}
              </div>
              <div class="link-section">
                Score:
              {{currentUser?.total || 0}}

              </div>

            </a>
          </div>
          </div>


        <div class="col-md-12">
          <div class="border  shadow-sm" style="background-color:#073262; font-size: .9em;">
            <div style="position: relative; text-align: center;">
              <img src="assets/newicons/dykw.png" alt="icon" class="mb-3 w-100 pl-3" style="max-width: 100%;" />
              <h4 class="dyk"
                style="position: absolute;top: 25px; left: 50%; transform: translate(-50%, -50%); font-family: Georgia;color:#fff; background-color: rgba(255, 255, 255, 0); padding: 10px; font-size: 16px;">
                Did you know</h4>
            </div>

            <div class="line1">
              <span class="connector-line"></span>
            </div>
            <div class="px-3 pb-2">
              <div class="slider-container" [class.active]="currentSlide === 0">
                <div class="slider">
                  <div class="slide">
                    <div style="color: #fff;" *ngIf="currentSlide === 0">On GenAI completions playground you can create
                      a <a style="color: #00AECF;">business requirement document</a> by providing a use case</div>
                  </div>
                </div>

              </div>
              <div class="slider-container" [class.active]="currentSlide === 1">
                <div class="slider">
                  <div class="slide">
                    <div style="color: #fff;" *ngIf="currentSlide === 1">In the realm of bits and bytes, generative AI
                      weaves dreams into existence, crafting artistry which anyone can fathom. <a
                        style="color: #00AECF;"></a></div>
                  </div>
                </div>

              </div>
            </div>
            <div class="slider-dots">
              <div class="dot" (click)="showSlide(0)" [class.active]="currentSlide === 0"></div>
              <div class="dot" (click)="showSlide(1)" [class.active]="currentSlide === 1"></div>
            </div>
            <!--  -->

            <div class="inner">
              <div class=" text-center"></div>
              <p class="title-box"></p>
            </div>
          </div>

        </div>
        <div class="col-md-12">
          <br>
          <h3 style="font-size: 12px;  text-align: center; font-family: Georgia; color:#00AECF;"> Know more
            about Gen AI Playground</h3>
          <div class="embed-responsive embed-responsive-16by9">

            <!-- <iframe class="embed-responsive-item" src="assets/video.mp4?autoplay=0" controls allowfullscreen ></iframe> -->
            <video width="320" height="240" controls class="embed-responsive-item">
              <source src="assets/video.mp4" type="video/mp4">
              <!-- <source src="movie.ogg" type="video/ogg"> -->
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
        <div class="col-md-12">
          <br>
          <div class="didy1 border p-4 shadow-sm">
            <h6 style="color:#00AECF;">New on Gen AI"</h6>
            <div style="font-size: small; position: relative;">
              <div style="color:#ffffff; font-family: Georgia; "><i class="bi bi-1-square-fill texts"></i> Recently
                Launched
              </div>
              <ul>
                <li>
                  <div style="color: #ffffff; font-size: 10px; font-family: sans-serif; ">
                    Image Generation </div>
                </li>
                <li>
                  <div style="color: #ffffff; font-size: 10px; font-family: sans-serif; ">
                    Response Accuracy with new models
                  </div>
                </li>
                <li>
                  <div style="color: #ffffff; font-size: 10px; font-family: sans-serif; ">
                    GPT-4o model
                  </div>
                </li>
                <!-- <li>
                  <div style="color: #ffffff; font-size: 10px; font-family: sans-serif; ">
                    Chat History
                  </div>
                </li> -->
                <li>
                  <div style="color: #ffffff; font-size: 10px; font-family: sans-serif; ">
                    Assistant Library - Data Analyzer
                  </div>
                </li>
              </ul>
              <div class="con-line">
                <span class="connector-line"></span>
              </div>
              <div style="color:#ffffff; position: relative; "> <i class="bi bi-2-square-fill  texts"></i> Upcoming</div>
              <ul>
                <li>
                  <div style="color: #ffffff; font-size: 10px; font-family: sans-serif; ">
                    Assistant Library (Media Assist) </div>
                </li>
                <li>
                  <div style="color: #fffbfb; font-size: 10px; font-family: sans-serif; ">
                    Assistant Library (File Comparison)</div>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
    </div>


  </div>

  <div class="col-12 p-4 mt-2" style="background-color:#01152B; position: absolute;
  left: 0;"></div>





  <!-- policy & condition toggle -->
  <button type="button" class="btn btn-primary d-none" id="playground_policy_model"
    (click)="openModal(playground_policy_model)">
  </button>

  <!-- policy & condition block -->
  <ng-template #playground_policy_model>
    <div class="modal-body tcolor">
      <div class="row">
        <div class="col-12 text-decoration-underline fw-bold text-primary" style="color: #073262 !important;">Important
          Instructions</div>
      </div>
      <div class="row">
        <div class="col-12 pt-2" style="font-size: 13px;">
          <ul style="color: #073262 !important;">
            <li>
              Before continuing, please review the Use of Generative AI Programs Policy. Compliance with the Policy is
              required
            </li>
            <li>
              This playground is for experimentation and discovery by Genpact employees for internal use only. If you
              wish to create a program or tool as a Proof of Concept to be considered for further development, follow
              the IT Enablement procedure to request access to appropriate tools. AI-powered tools cannot be proposed
              for use within Genpact or in client accounts without undergoing an approval process.
            </li>
            <li>
              AI isn't perfect. While efforts have been made to provide accurate and relevant information, there is the
              possibility of errors and inaccuracies. The responses you receive should not be considered professional or
              expert advice. We strongly recommend checking the information with reliable sources or consulting a human
              expert as necessary or appropriate. Use the programs and their output at your own discretion.
            </li>
            <li>
              Your prompts may be subject to review or audit for compliance with Genpact's policies and/or for refining
              the technology.
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-12 ml-4">
          <input id="byod_check_label" type="checkbox" [(ngModel)]="chkagree" class="form-check-input" />
          <label class="form-check-label text-decoration-underline fw-bold text-primary"
            for="byod_check_label" style="color: #073262 !important; margin-left: 15px;">
            I acknowledge that I have read the information above, understand it, and agree to comply with the Use of
            Generative AI Programs Policy and other applicable Genpact policies
          </label>
        </div>

      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn button-genpact text-white  cursor-pointer" [disabled]="!chkagree"
        (click)="modalRef?.hide()">
        Proceed
      </button>
    </div>
  </ng-template>
</div>
