import { Component, OnInit, Inject } from '@angular/core';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import { Router } from '@angular/router';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { LeaderboardService } from '../shared/service/leaderboard.service';
interface ResourceServerExample {
  label: string;
  url: string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isAuthenticated!: boolean;
  resourceServerExamples: Array<ResourceServerExample>;
  userName?: string;
  error?: Error;

  constructor(
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private router:Router,
    private toastr:ToastMessageService,
    private leaderboardService:LeaderboardService
    ) {
    this.resourceServerExamples = [
      {
        label: 'Node/Express Resource Server Example',
        url: 'https://github.com/okta/samples-nodejs-express-4/tree/master/resource-server',
      },
      {
        label: 'Java/Spring MVC Resource Server Example',
        url: 'https://github.com/okta/samples-java-spring/tree/master/resource-server',
      },
      {
        label: 'ASP.NET Resource Server Example',
        url: 'https://github.com/okta/samples-aspnet/tree/master/resource-server'
      }
    ];
  }

  async login() {
    try {
      if(!this.isAuthenticated){
      await this.oktaAuth.signInWithRedirect();
      }else{

      }
    } catch (err: any) {
      console.error(err);
      this.error = err;
    }
  }

  async ngOnInit() {
    this.isAuthenticated = await this.oktaAuth.isAuthenticated();
    if (this.isAuthenticated) {
      let token:any = localStorage.getItem('okta-token-storage')
      token = JSON.parse(token);
      localStorage.setItem('token',token.accessToken.accessToken)

      await this.leaderboardService.createUser();

      console.log('token is',token.accessToken.accessToken)
      const userClaims = await this.oktaAuth.getUser();
      console.log('userClaimes',userClaims)
      this.userName = userClaims.name ? userClaims.name : userClaims.email;
      console.log('this username', this.userName)
      localStorage.setItem('okta-username', this.userName ? this.userName :'');
      
      this.redirectToRoute();
    }else{
      this.login()
    }
  }


  redirectToRoute(){
    let isRedirectExits = localStorage.getItem('p_redirect');
    if(isRedirectExits){
      localStorage.removeItem('p_redirect');
      this.router.navigate([isRedirectExits]);
      this.toastr.clearAll();
      setTimeout(() => {       
        this.toastr.showSuccess('Logged In Succssfully.')
      }, 500);
    }else{
      this.router.navigate(['home-openai']);
    }
  }
}
