import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class GTranslateService {
  apiURL = environment.googleTranslateApi;

  constructor(private http: HttpClient) {}

  uploadFile(payload: any): Observable<any> {
    return this.http.post(`${this.apiURL}/upload`, payload);
  }

  translateFile(payload: any): Observable<any> {
    return this.http.post(`${this.apiURL}/translate`, payload);
  }

  downloadFile(payload: any): Observable<any> {
    return this.http.post(`${this.apiURL}/download`, payload, { responseType: 'blob'});
  }
   }

