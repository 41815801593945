<app-systran-sidenav></app-systran-sidenav>
<div class="flex-container p-5" style="margin-top: -35px;">
  <div class="flex-item" style="padding-left: 45px;">


<div class="navigation">
  <a routerLink="/translate/text" routerLinkActive="active-link">Text translation</a>
  <div class="divider-vertical"></div>
  <a routerLink="/translate/file" routerLinkActive="active-link">File translation</a>
</div>
<hr class="horizontal-line">
</div>
</div>

<router-outlet></router-outlet>
<br><br>
<div class="footer">
  footer
  </div>
