import { Component, OnDestroy, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { LoaderService } from '../shared/service/loader.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { TranslateService } from '../shared/service/translate.service';
import { GTranslateService } from '../shared/service/gtranslate.service';
import { UserApiLimitService } from '../shared/service/user-api-limit.service';
import * as Papa from 'papaparse';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-google-translate',
  templateUrl: './google-translate.component.html',
  styleUrls: ['./google-translate.component.scss']
})
export class GoogleTranslateComponent {
  downloadStatus = false;
  languageArray: any = [];
  processStatus: boolean = false;

  convertTo: any = [{ value:'en',name:'English'}];
  targetSASUrl: any;
  fileUploadedStatus = false;
  fileData: any;
  userOHR: any;
  glossaryStatus = false;
  // ImageStatus = false;
  file: File | any = null;
  glossaryFileData: any;
  glossaryFileUploadedStatus = false;
  showUpload: boolean = true;
  tableData: any = [{ currentText: '', translatedText: '' }];
  detectedLanguage: any;
  fileName: any;
  mimeType: any;
  glossaryTable : any;

  dropdownSettings:IDropdownSettings = {
    singleSelection: true,
    idField: 'value',
    textField: 'name',
    itemsShowLimit: 3,
    maxHeight:160,
    allowSearchFilter: true
  };

  intervalId: any; // Variable to store the interval ID
  endPointDetail: any;
  currentText: any;
  translatedText: any;
  fileContent: any;
  fileExtension: any;
  downloadbtn: boolean = false;

  constructor(
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private toastMessage:ToastMessageService,
    private userApiLimitService: UserApiLimitService,
    private gtranslateService: GTranslateService
  ) {
    this.getTranslateLanguage();
    this.getUserLimt();
  }

  ngOnInit() {
  }

  addRow() {
    this.tableData.push({ currentText: '', translatedText: '' });
  }

  removeRow() {
    this.tableData.splice(this.tableData?.length - 1, 1);
  }

  //get max count and count left of api
  getUserLimt() {
    this.userApiLimitService.getUserApiCount("translate").subscribe((res: any) => {
      if (res?.response?.status == 'success') {
        this.endPointDetail = res?.response?.data ? res?.response?.data[0] : null;
      }
    })
  }

  // Get all translation language
  getTranslateLanguage() {
    this.translateService.translateLanguage().subscribe((res: any) => {
      if (res?.translation) {
        const transformedResponse = Object.keys(res?.translation).map(key => ({
          value: key,
          name: res?.translation[key].name
        }));
        this.languageArray = transformedResponse || [];
      }
    })
  }
  //
  toggleFileInputType(): void {
    // this.ImageStatus = !this.ImageStatus;
    // this.fileUploadAcceptValue();
    // this.fileUploadMultipleValue();
  }
  // fileUploadAcceptValue(): string {
  //   return this.ImageStatus ? '.png,.jpeg,.jpg,.PNG,.tif,.tiff' : '.txt,.xlsx,.xls,.pdf,.PDF,.pptx,.PPTX,.ppt,.doc,.DOC,.docx,.DOCX';
  //   '".pdf, .doc, .docx, .txt,.ppt,.pptx,.xls,.xlsx,.png,.jpg,.jpeg,.PNG'

  // }

  // fileUploadMultipleValue(): boolean {
  //   return this.ImageStatus;
  // }
//onupload
onupload(event:any){
  // this.pdfFile = 
  this.fileName = event.target.files[0].name;
  //if file size greater than 16mb or file type is not of input accept type
  if(event.target.files[0].size>16*1024*1024){
    alert('Maximum size is 16 mb');
    return;
  }
  else if(!event.target.files[0].name.match(/.(txt|pdf|doc|docx|xls|xlsx|ppt|pptx|png|jpeg|jpg|PNG)$/i)){
    alert('Please upload file of type .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .png, .jpg, .jpeg, .PNG');
    return;
  }
  // else if(!this.ImageStatus && event.target.files[0].name.match(/.(png|jpeg|jpg|PNG|tif|tiff)$/i)){
  //   alert('Please check the box before uploading image file');
  //   return;
  // }
  // else if(this.ImageStatus && event.target.files[0].name.match(/.(txt|pdf|doc|docx|xls|xlsx|ppt|pptx)$/i)){
  //   alert('Please uncheck the box before uploading non image file');
  //   return;
  // }

  //else if non image files lenght>1

   else{
   this.file = event.target.files[0];
    this.postTranslateUpload();
   }
}
  // Upload file for translation
  postTranslateUpload() {
    this.loaderService.loading.next(true);
    this.getOHR();
    const formData = new FormData();
  formData.append('user_id', this.userOHR);
  formData.append('file', this.file);
    this.gtranslateService.uploadFile(formData)
      .subscribe(
        (res: any) => {
          this.loaderService.loading.next(false);
          this.getUserLimt();

         if( res?.success == true){
          // console.log(res);
          this.fileUploadedStatus = true;
          this.showUpload = false;
          this.mimeType = res?.mimetype;
          this.detectedLanguage = res?.detected_language;

          this.toastMessage.showSuccess(res?.message);
         }else{
          this.toastMessage.showError(res?.message);
         }
        },
        (error) => {
          // Handle the error here
          this.loaderService.loading.next(false);
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );
  }

  addGlossaryEntry() {
    this.tableData.push({ currentText: '', translatedText: '' });
  }

  removeGlossaryEntry(index: number) {
    this.tableData.splice(index, 1);
  }

  // translate file  after upload
  translateContent() {
    this.loaderService.loading.next(true);
    this.getOHR();
    this.glossaryTable = this.tableData.map((row: any) => `${row.currentText},${row.translatedText}\n`).join('');
    let payload: {
      user_id?: any;
      target_language?: any;
      file_name?: any;
      mime_type?: any;
      detected_language?: any;
      glossary_content?: string;
      use_glossary?: boolean;
    } = {};
    
    if(this.glossaryStatus)
    {
      payload['user_id'] = this.userOHR;
      payload['target_language'] = this.convertTo[0]?.value;
      payload['file_name'] = this.fileName;
      payload['mime_type'] = this.mimeType;
      payload['detected_language'] = this.detectedLanguage;
      payload['glossary_content'] = this.glossaryTable;
      payload['use_glossary'] = true;
    }
    else 
    {
      payload['user_id'] = this.userOHR;
      payload['target_language'] = this.convertTo[0]?.value;
      payload['file_name'] = this.fileName;
      payload['mime_type'] = this.mimeType;
      payload['detected_language'] = this.detectedLanguage;
    }
    this.gtranslateService.translateFile(payload)
      .subscribe(
        (res: any) => {
          this.loaderService.loading.next(false);

          if(res)
          {
            this.fileContent = res?.translated_file_content;
            this.fileExtension = res?.file_extension;
            this.downloadbtn = true;
          }else{
            this.toastMessage.showError(res?.message);
          }
        },
        (error) => {
          // Handle the error here
          this.loaderService.loading.next(false);
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );
  }

  // Download translated file
  downloadFile() {
  const payload = {
    "file_extension" : this.fileExtension,
    "translated_file_content" : this.fileContent
  }
  this.gtranslateService.downloadFile(payload).subscribe((res: Blob) =>
  {
    const file = new Blob([res], { type: this.mimeType }); 
    if(this.fileName.includes('.jpg'))
    {
      this.fileName = this.fileName.replace('.jpg', '.pdf');
    }
    else if(this.fileName.includes('.jpeg')) {
      this.fileName = this.fileName.replace('.jpeg', '.pdf');
    }
   else if(this.fileName.includes('.PNG')) {
      this.fileName = this.fileName.replace('.PNG', '.pdf');
    }
   else if(this.fileName.includes('.png')) {
      this.fileName = this.fileName.replace('.png', '.pdf');
    }
    // console.log(this.fileName);
    saveAs(file, this.fileName); 
    // saveAs(file, 'output.pdf'); 
    this.loaderService.loading.next(false);
    this.toastMessage.showSuccess('Downloading File.');
  },
  );
  }

  // reset upload file
  resetFile() {
    this.fileUploadedStatus = false;
    this.fileData = '';
    this.showUpload = true;
    this.downloadStatus = false;
    this.downloadbtn = false;
    this.targetSASUrl = '';
    this.convertTo = [{ value:'en',name:'English'}];
    localStorage.removeItem('f_tarnslate');
  }

  resetglossaryFile() {
    this.glossaryFileUploadedStatus = false;
    this.glossaryFileData = '';
    this.glossaryStatus = false;
    // this.showUpload = true;
    this.downloadStatus = false;
    this.targetSASUrl = '';
    localStorage.removeItem('glossary_tarnslate');
  }


  // get user OHR id
  getOHR() {
    let userData = localStorage.getItem('okta-token-storage');
    if (userData) {
      let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
      if (ohr_id) {
        this.userOHR = ohr_id;
      }
    }
  }


  resetForm(event: any) {
    localStorage.removeItem('f_tarnslate');
//refresh page
   // window.location.reload();
   this.resetFile();
    this.loaderService.loading.next(false);
  }


  ngOnDestroy(): void {
     // Clear the interval when the component is destroyed
    //  this.stopInterval();
  }
}
