import { Component, Input, AfterViewInit, ViewChild, ElementRef, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { DigiService } from 'src/app/digi.service';

@Component({
  selector: 'app-avatar-display',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './avatar-display.component.html',
  styleUrls: ['./avatar-display.component.scss']
})
export class AvatarDisplayComponent implements OnInit {
  @Input() avatarUrl: string = ''; // Input to accept the avatar URL from parent
  @Input() successMessage: string = 'Image has been successfully generated';
  @Input() finalresponse :string ="abcde";
  
  @ViewChild('textCanvas') textCanvas!: ElementRef<HTMLCanvasElement>;

  constructor(private http: HttpClient,private digiservice:DigiService ) {}

  ngOnInit() {
    // Call the fetchPersonalityTraits method after a 30-second delay
    setTimeout(() => {
      this.fetchPersonalityTraits();
    }, 30000); // 
  }

  fetchPersonalityTraits() {
    const formData = new FormData();
    formData.append("answers",this.finalresponse);

    // this.http.post('https://api.playground-dev.genpact.com/poc/avatar/ai-personality/',formData)
      this.digiservice.getpersonality(formData).subscribe((response: any) => {
        this.displayTextOnCanvas(response);
      });
  }

  displayTextOnCanvas(text: string) {
    const canvas = this.textCanvas.nativeElement;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      const maxWidth = 360; 
      const lineHeight = 25; 
      const x = 10; 
      let y = 30; 
   
      canvas.width = 400; 
      canvas.height = 250; 
      ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.fillStyle = '#000'; 
      ctx.font = '13px Arial'; 
   
      // Function to wrap text manually
      const words = text.split(' ');
      let line = '';
   
      for (let i = 0; i < words.length; i++) {
        const testLine = line + words[i] + ' ';
        const metrics = ctx.measureText(testLine);
        const testWidth = metrics.width;
   
        if (testWidth > maxWidth && i > 0) {
          ctx.fillText(line, x, y); 
          line = words[i] + ' '; 
          y += lineHeight; 
        } else {
          line = testLine;
        }
      }
   
      ctx.fillText(line, x, y); 
    }
  }
}
