import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})

export class PreplexityService {
  perplextiyApi = environment.apiURL;


  constructor(private http: HttpClient) { }


  relatedQuestion(payload: any) {
    return this.http.post(`${this.perplextiyApi}/recent_info`, payload);
  }

  relatedQuestionLinks(payload: any) {
    return this.http.post(`${this.perplextiyApi}/recent_bing`, payload);
  }

  relatedQuestionContent(payload: any) {
    return this.http.post(`${this.perplextiyApi}/recent_response`, payload);
  }

  // relatedQuestionCollection(payload: any) {
  //   return this.http.post(`${this.perplextiyApi}/recent_collection`, payload);
  // }

  getDataAsPromise(url:any, data:any = { method: 'GET' }) {
    return new Promise((resolve, reject) => {
      fetch(url, {
        ...data,
        headers: {
          ...data.headers,
           'Content-Type': 'application/json',
          'Authorization':  'Bearer ' + localStorage.getItem('token'), // Replace with your actual token
        },
      })
        .then(response => response.json())
        .then(result => resolve(result))
        .catch(error => resolve(error));
    });
  }



  // relatedQuestionCollection(paylaod: any): Observable<any> {
  //   const promise = this.getDataAsPromise(`${this.perplextiyApi}/recent_collection`,{ method: 'POST',body:paylaod}); // Calling your method that returns a Promise
  //   return from(promise);
  // }

  relatedQuestionCollection(paylaod: any): Observable<any> {
    const promise = this.getDataAsPromise(`${this.perplextiyApi}/recent_collection`,{ method: 'POST',body:JSON.stringify(paylaod)}); // Calling your method that returns a Promise
    return from(promise);
  }








  policysearch(payload: any) {
    return this.http.post(`${this.perplextiyApi}/subsidy-policy-search`, payload);
  }
  policyStatus() {
return this.http.get(`${this.perplextiyApi}/subsidy-policy-status`);
  }
  policycrawl(payload: any) {
    return this.http.post(`${this.perplextiyApi}/subsidy-policy-crawl`, payload);
  }
}

