<div class="upload-section">
    <div class="button-container">
      <!-- Hide the file input and use a label to style the button -->
      <input type="file" id="fileInput" (change)="onFileSelected($event)" accept="image/jpeg, image/png" hidden>
      <label for="fileInput" class="custom-file-upload">Upload Image</label>
      
      <button class="button3" (click)="captureImage()">Capture Image</button>
    </div>
  
  
    <canvas #uploadCanvas width="640" height="360"></canvas>
    <button class="button1" (click)="generateAvatar()" class="generate-avatar-button">Generate Avatar</button>

  </div>
  
  <app-loader></app-loader>

  <!-- <app-popup [showPopup]="showPopup"></app-popup> -->
  
  <!-- Modal for capturing image -->
  <div id="captureModal" class="modal">
    <div class="modal-content">
      <span class="close" (click)="closeModal()">&times;</span>
      <video #video width="640" height="360" autoplay></video>
      <canvas #captureCanvas width="640" height="360" style="display:none;"></canvas>
      <button class="button2" (click)="captureImageFromVideo()">Capture Image from Video</button>
    </div>
  </div>
  
  <!-- Avatar display component -->
  <!-- <app-avatar-display [avatarUrl]="generatedAvatarUrl" [successMessage]="successMessage"></app-avatar-display> -->
  