import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageGeneratorService {

  // imageGeneratorURL = 'https://6pflcnhze0.execute-api.ap-south-1.amazonaws.com/dev';
  apiURL= environment.apiURL;
  nodeEndpoint= environment.nodeEndpoint;
  // nodeEndpoint= environment.gcpurl;
  constructor(private http: HttpClient) { }

  //  generateImageMessage(): Observable<any>  {

  //   const promise = this.getDataAsPromise(`${this.imageGeneratorURL}/welcome`); // Calling your method that returns a Promise
  //   return from(promise);

  //   const headers = new HttpHeaders({'Origin': 'http://localhost:4200'});
  //   return this.http.get(`${this.imageGeneratorURL}/welcome`, { headers });
  // }

//  getDataAsPromise(url:string,data:any={ method: 'GET'}): Promise<any> {
//     return new Promise((resolve, reject) => {
//       fetch(url,data)
//         .then(response => response.json())
//         .then(result => resolve(result))
//         .catch(error => resolve(error));
//     });
//   }



  // generateImageStable(paylaod: any): Observable<any> {
  //   const promise = this.getDataAsPromise(`${this.imageGeneratorURL}/image-generate-stable`,{ method: 'POST',body:JSON.stringify(paylaod)}); // Calling your method that returns a Promise
  //   return from(promise);
  // }

  // generateImageTitan(paylaod: any): Observable<any> {
  //   const promise = this.getDataAsPromise(`${this.imageGeneratorURL}/image-generate-titan`,{ method: 'POST',body:JSON.stringify(paylaod)}); // Calling your method that returns a Promise
  //   return from(promise);
  // }

  // modifyImage(paylaod: any): Observable<any> {
  //   const promise = this.getDataAsPromise(`${this.imageGeneratorURL}/image-modify`,{ method: 'POST',body:JSON.stringify(paylaod)}); // Calling your method that returns a Promise
  //   return from(promise);
  // }

  generateImage(paylaod: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.nodeEndpoint}generate-image`, paylaod, { headers });
  }

  modifyImage(paylaod: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.nodeEndpoint}modify-image`, paylaod, { headers });
  }

  // generateImageTitan(payload: any): Observable<any> {
  //   // const promise = this.getDataAsPromise(`${this.imgApiURL}/titan/image-generate`,{ method: 'POST',body:JSON.stringify(payload)}); // Calling your method that returns a Promise
  //   // return from(promise);
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   return this.http.post(`${this.imgApiURL}/titan/image-generate`, payload, { headers });
  // }

  // generateImageDalle(payload: any): Observable<any> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   return this.http.post(`${this.dURL}/dalle/image-generate`, payload, { headers });
  // }

  // modifyImageTitan(payload: any): Observable<any> {
  //   // const promise = this.getDataAsPromise(`${this.imgApiURL}/titan/image-modify`,{ method: 'POST',body:JSON.stringify(payload)}); // Calling your method that returns a Promise
  //   // return from(promise);

  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   return this.http.post(`${this.imgApiURL}/titan/image-modify`, payload, { headers });
  // }

  // modifyImageDalle(payload: any): Observable<any> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   return this.http.post(`${this.apiURL1}/dalle/image-modify`, payload, { headers });
  // }

  // modifyImageStable(payload: any): Observable<any> {
  //   const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  //   return this.http.post(`${this.imgApiURL}/stable/image-modify`, payload, { headers });
  // }

  getImagePrompt(payload: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.apiURL}/img-gen-prompt`, payload, { headers });
  }

getdalleimage(paylaod: any): Observable<any> {
  // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  return this.http.post(`${this.apiURL}/azure-dalle3`, paylaod);
}


}
