
import { Component, TemplateRef, ViewChild, ElementRef, OnInit } from '@angular/core';
import { MessageService } from '../shared/service/message.service';
import { ActivatedRoute ,Router} from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { VoiceRecognitionService } from '../shared/voice-recognition.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { HostListener } from '@angular/core';
import { UserApiLimitService } from '../shared/service/user-api-limit.service';
import { LoaderService } from '../shared/service/loader.service';
@Component({
  selector: 'app-playground',
  templateUrl: './playground.component.html',
  styleUrls: ['./playground.component.scss'],
  providers: [VoiceRecognitionService]
})
export class PlaygroundComponent implements OnInit {
  @ViewChild('textboxPrompt', { static: false })
  textboxPrompt!: ElementRef;
  [x: string]: any;
  currentItem = 'playground';
  generatebtn: boolean = false;
  undobtn: boolean = false;
  regeneratebtn: boolean = false;
  downloadbtn: boolean = false;
  chkagree: boolean = false;
  username: string = '';
  showLoading: boolean = false;
  placeholder: string = '';
  windowRef = window;
  speechtext:string='';
  // isCopied: boolean = false;
  isMenuOpen: boolean = false;
  showAdvancedSettings: boolean = false;
  speechon:boolean = false;
  speechoff:boolean = true;
  startspeech: boolean = true;
  stopspeech: boolean = false;
  streamprompt:string='';
  notetext='Note: Please be aware that the messages have been generated by an artificial intelligence model. While efforts have been made to provide accurate and relevant information, there is a possibility of errors or inaccuracies. The response you receive should not be considered as professional or expert advice. We strongly recommend cross-checking the information with reliable sources or consulting a human expert when necessary. The purpose of this AI is to assist and provide general information, but it may not always be perfect. Use the information at your own discretion.';
  modalRef?: BsModalRef;
  totalUsage:any;
  constructor(private messageService: MessageService,
    private activatedRoute:ActivatedRoute,
    private router:Router,
    private modalService: BsModalService,
    public speechservice : VoiceRecognitionService,
    private toster:ToastMessageService,
    private loaderService:LoaderService,
    private elementRef: ElementRef,
    private userApiLimitService: UserApiLimitService
    ) {
      this.speechservice.init();
      this.getUserLimt();
  }
  startService(){
    this.speechservice.start()
    this.startspeech = false;
    this.stopspeech = true;
    this.showLoading = true, 
    this.toster.showSuccess('speech recognition has started');
  }

  updateprompt() {
    this.prompt = this.prompt+ this.speechservice.text;
  }

 

  stopService(){
    this.speechservice.stop()
    this.startspeech = true;
    this.stopspeech = false;
    this.toster.showWarning('Speech Recognition Stopped');
    this.prompt += this.streamprompt;
    console.log(this.prompt);
    console.log(this.speechservice.text);
    //this.speechon = false;
 this.streamprompt='';
 this.showLoading=false;
   // this.speechoff = !this.speechoff;

  }
  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any; }; }) {
    let screenWidth = event.target.innerWidth;
    let container = this.elementRef.nativeElement.querySelector('.res-container');

   if(container){
    if (screenWidth < 768) {
      container.style.width = '100%';
    } else {
      container.style.width = '768px';
    }
   }
  }

    //get max count and count left of api
    getUserLimt() {
      this.userApiLimitService.getUserApiCount("playground").subscribe((res: any) => {
        if (res?.response?.status == 'success') {
          this.endPointDetail = res?.response?.data ? res?.response?.data[0] : null;
        }
      })
    }

  toggleAdvancedSettings() {
    this.showAdvancedSettings = !this.showAdvancedSettings; // Toggle the variable value
  }
  // copyGeneratedContent() {
  //   const generatedContent = this.prompt; // Replace with the actual variable holding the generated content
  //   const textarea = document.createElement('textarea');
  //   textarea.value = generatedContent;
  //   document.body.appendChild(textarea);
  //   textarea.select();
  //   document.execCommand('copy');
  //   document.body.removeChild(textarea);
  // }
  changebtn(check:boolean) {
    const el1 = document.getElementById("generatebtn") as HTMLInputElement;
    const el2 = document.getElementById("undobtn") as HTMLInputElement;
    const el3 = document.getElementById("regeneratebtn") as HTMLInputElement;
    const el4 = document.getElementById("downloadbtn") as HTMLInputElement;
    if (el1!==null && el2!==null && el3!==null && el4!==null) {
      el1.disabled = check;
      el2.disabled = check;
      el3.disabled = check;
      el4.disabled = check;
    }
  }
  ngOnInit() {
    this.username = localStorage.getItem('okta-username')?localStorage.getItem('okta-username') as string:'Guest';
    this.messageService.useraccess().subscribe((res: any) => {
      this.onLoading();
    }, (err: any) => {

      console.log('error is', err);
    });
    this.speechservice.voiceRecognitionUpdate.subscribe((updatedText: string) => {
      //// Update your prompt with the live updates
    console.log(updatedText);
  //this.prompt+=updatedText;
  this.streamprompt=updatedText;



    });
  }


   // Open model
   openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md',ignoreBackdropClick: true });
  }


  onLoading() {
    const modalbtn = document.getElementById("playground_policy_model") as HTMLLIElement;
    //$('#exampleModal')?.modal('show');
    modalbtn.click();
  }
  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }
  show_completion: boolean = true;
  show_cv:boolean = false;
  prompt1 = this.speechservice.text;
  prompt: string = '' ;
  top_p: number = 0;
  frequency_penalty: number = 0;
  best_of: number = 1;
  presence_penalty: number = 0;
  max_tokens: number = 850;
  stop = null;
  deployments: string = 'Genpact';
  examples: string = 'Load an Example';
  temperature: number = 0;
  chkpreresponse: boolean = false;
  chkpostresponse: boolean = false;
  preresponse: string = '';
  postresponse: string = '';


  deployment_list: any = [
    { id: 2, name: 'Genpact' },
  ]
  examples_list: any = [
    {
      id: -1, name: 'Load an Example', prompt: '', temperature: 0,
      top_p: 0,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 100,
      stop: null,
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 0, name: 'Generate Business Requirement Document',
      prompt: 'Act as a world class Business Analyst, Create a Business Requirement document BRD for below use case? \nThis document must Contain specific sections like version control, Project context, Stakeholders, Approvers, Purpose, Scope, Assumptions, Exclusions, Functional & Non Functional requirements in the form of basic user stories etc\nUSE CASE:',

      temperature: 0,
      top_p: 0,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 100,
      stop: null,
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 1, name: 'Generate User Stories',
      prompt: 'Act as world class Business Analyst, Please write down the detailed user stories in fully agile format for these Functional requirements with Acceptance criteria in the format of GIVEN, WHEN & THEN??',
      temperature: 0,
      top_p: 0,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 100,
      stop: null,
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 2, name: 'Generate Test Cases',
      prompt: 'Please create test cases from following requirement with aspects / flow provided, also include positive, negative and exceptional test cases and provide test cases in tabular format which should include ( Test case id , Test case Description ,Test steps , Expected Results) \nRequirements:\n\n\nAspects/Flow:',
      temperature: 0,
      top_p: 0,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 100,
      stop: null,
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 3,
      name: 'Summarize issue resolution from conversation',
      prompt: "Generate a summary of the below conversation in the following format:\nCustomer problem:\nOutcome of the conversation:\nAction items for follow-up:\nCustomer budget:\nDeparture city:\nDestination city:\n\nConversation:\nUser: Hi there, I’m off between August 25 and September 11. I saved up 4000 for a nice trip. If I flew out from San Francisco, what are your suggestions for where I can go?\nAgent: For that budget you could travel to cities in the US, Mexico, Brazil, Italy or Japan. Any preferences?\nUser: Excellent, I’ve always wanted to see Japan. What kind of hotel can I expect?\nAgent: Great, let me check what I have. First, can I just confirm with you that this is a trip for one adult?\nUser: Yes it is\nAgent: Great, thank you, In that case I can offer you 15 days at HOTEL Sugoi, a 3 star hotel close to a Palace. You would be staying there between August 25th and September 7th. They offer free wifi and have an excellent guest rating of 8.49/10. The entire package costs 2024.25USD. Should I book this for you?\nUser: That sounds really good actually. Please book me at Sugoi.\nAgent: I can do that for you! Can I help you with anything else today?\nUser: No, thanks! Please just send me the itinerary to my email soon.\n\nSummary:",
      temperature: 0.3,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 350,
      stop: null,
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 4,
      name: 'Summarize key points from financial report (extractive)',
      prompt: "Below is an extract from the annual financial report of a company. Extract key financial number (if present), key internal risk factors, and key external risk factors.\n\n# Start of Report\nRevenue increased $7.5 billion or 16%. Commercial products and cloud services revenue increased $4.0 billion or 13%. O365 Commercial revenue grew 22% driven by seat growth of 17% and higher revenue per user. Office Consumer products and cloud services revenue increased $474 million or 10% driven by Consumer subscription revenue, on a strong prior year comparable that benefited from transactional strength in Japan. Gross margin increased $6.5 billion or 18% driven by the change in estimated useful lives of our server and network equipment. \nOur competitors range in size from diversified global companies with significant research and development resources to small, specialized firms whose narrower product lines may let them be more effective in deploying technical, marketing, and financial resources. Barriers to entry in many of our businesses are low and many of the areas in which we compete evolve rapidly with changing and disruptive technologies, shifting user needs, and frequent introductions of new products and services. Our ability to remain competitive depends on our success in making innovative products, devices, and services that appeal to businesses and consumers.\n# End of Report",
      temperature: 0.3,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 350,
      stop: null,
      chkpreresponse: true,
      chkpostresponse: true,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 5,
      name: 'Summarize an article (abstractive)',
      prompt: "Provide a summary of the text below that captures its main idea.\n\nAt Microsoft, we have been on a quest to advance AI beyond existing techniques, by taking a more holistic, human-centric approach to learning and understanding. As Chief Technology Officer of Azure AI Cognitive Services, I have been working with a team of amazing scientists and engineers to turn this quest into a reality. In my role, I enjoy a unique perspective in viewing the relationship among three attributes of human cognition: monolingual text (X), audio or visual sensory signals, (Y) and multilingual (Z). At the intersection of all three, there’s magic—what we call XYZ-code as illustrated in Figure 1—a joint representation to create more powerful AI that can speak, hear, see, and understand humans better. We believe XYZ-code will enable us to fulfill our long-term vision: cross-domain transfer learning, spanning modalities and languages. The goal is to have pre-trained models that can jointly learn representations to support a broad range of downstream AI tasks, much in the way humans do today. Over the past five years, we have achieved human performance on benchmarks in conversational speech recognition, machine translation, conversational question answering, machine reading comprehension, and image captioning. These five breakthroughs provided us with strong signals toward our more ambitious aspiration to produce a leap in AI capabilities, achieving multi-sensory and multilingual learning that is closer in line with how humans learn and understand. I believe the joint XYZ-code is a foundational component of this aspiration, if grounded with external knowledge sources in the downstream AI tasks.",
      temperature: 0.3,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 250,
      stop: null,
      chkpreresponse: true,
      chkpostresponse: true,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 6,
      name: 'Generate product name ideas',
      prompt: "Generate product name ideas for a yet to be launched wearable health device that will allow users to monitor their health and wellness in real-time using AI and share their health metrics with their friends and family. The generated product name ideas should reflect the product's key features, have an international appeal, and evoke positive emotions.\n\nSeed words: fast, healthy, compact\n\nExample product names: \n1. WellnessVibe\n2. HealthFlux\n3. VitalTracker\n\nProduct names:\n1.",
      temperature: 0.8,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 60,
      stop: null,
      chkpreresponse: true,
      chkpostresponse: true,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 7,
      name: 'Generate an email',
      prompt: "Write a product launch email for new AI-powered headphones that are priced at $79.99 and available at Best Buy, Target and Amazon.com. The target audience is tech-savvy music lovers and the tone is friendly and exciting.\n\n1. What should be the subject line of the email?  \n2. What should be the body of the email?",
      temperature: 1,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 350,
      stop: null,
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 8,
      name: 'Generate a product description (bullet points)',
      prompt: "Write a product description in bullet points for a renters insurance product that offers customizable coverage, rewards and incentives, flexible payment options and a peer-to-peer referral program. The tone should be persuasive and professional.",
      temperature: 1,
      top_p: 1,
      frequency_penalty: 1.6,
      presence_penalty: 0,
      max_tokens: 150,
      stop: null,
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 9,
      name: 'Generate a listicle-style blog',
      prompt: "Write a catchy and creative listicle style blog on the topic of emerging trends in e-commerce that are shaping the future of retail. The blog should have a memorable headline and a clear call to action in the end encouraging the reader to engage further.",
      temperature: 1,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 600,
      stop: null,
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 10,
      name: 'Generate a job description',
      prompt: "Write a job description for the following job title: 'Business Intelligence Analyst'. The job description should outline the main responsibilities of the role, list the required qualifications, highlight unique benefits like flexible working hours, and provide information on how to apply.",
      temperature: 1,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 600,
      stop: null,
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 11,
      name: 'Generate a quiz',
      prompt: "Generate a multiple choice quiz from the text below. Quiz should contain at least 5 questions. Each answer choice should be on a separate line, with a blank line separating each question.\n\nA neutron star is the collapsed core of a massive supergiant star, which had a total mass of between 10 and 25 solar masses, possibly more if the star was especially metal-rich. Neutron stars are the smallest and densest stellar objects, excluding black holes and hypothetical white holes, quark stars, and strange stars. Neutron stars have a radius on the order of 10 kilometers (6.2 mi) and a mass of about 1.4 solar masses. They result from the supernova explosion of a massive star, combined with gravitational collapse, that compresses the core past white dwarf star density to that of atomic nuclei.\n\nExample:\nQ1. What is a neutron star?\nA. The collapsed core of a massive supergiant star\nB. The smallest and densest stellar object\nC. A white hole\nD. A quark star",
      temperature: 0.8,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0.5,
      max_tokens: 500,
      stop: null,
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 12,
      name: 'Classify Text',
      prompt: "Classify the following news headline into 1 of the following categories: Business, Tech, Politics, Sport, Entertainment\n\nHeadline 1: Donna Steffensen Is Cooking Up a New Kind of Perfection. The Internet's most beloved cooking guru has a buzzy new book and a fresh new perspective\nCategory: Entertainment\n\nHeadline 2: Major Retailer Announces Plans to Close Over 100 Stores\nCategory:",
      temperature: 0,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 60,
      stop: null,
      chkpreresponse: true,
      chkpostresponse: true,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 13,
      name: 'Classify and detect intent',
      prompt: "For the below text, provide two labels one each from the following categories:\n- Department: “Books”, “Home”, “Fashion”, “Electronics”, “Grocery”, “Others”\n- Order intent\n\nSubject: Request for Refund of Recent Book Purchase\nDear [Business Name],\nI am writing to request a refund for the books I purchased from your store last week. Unfortunately, the books did not meet my expectations, and I would like to return them for a full refund.\nI have attached a copy of the purchase receipt to this email as proof of purchase. The books are in their original packaging and have not been used, so I hope that the refund process will be straightforward.\nPlease let me know what steps I need to take to return the books and receive a refund. I look forward to hearing back from you soon.\nThank you for your attention to this matter.\nSincerely,\n[Your Name]\n\nResponse:",
      temperature: 0,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 60,
      stop: null,
      chkpreresponse: true,
      chkpostresponse: true,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 14,
      name: 'Cluster into undefined categories',
      prompt: "Cluster the following news headlines into topic categories based on patterns seen within the text. Also mention reasoning behind how these categories were defined. \n\nOutput format:\n{\n\"topic_name\": \"\",\n\"headlines\": [],\n\"reasoning\": \"\"\n}\n\nInput news headlines:\n1. \"From books to presentations in 10s with AR + ML\"\n2. \"Demo from 1993 of 32-year-old Yann LeCun showing off the World's first Convolutional Network for Text Recognition\"\n3. \"First Order Motion Model applied to animate paintings\"\n4. \"Robinhood and other brokers literally blocking purchase of $GME, $NOK, $BB, $AMC; allow sells\"\n5. \"United Airlines stock down over 5% premarket trading\"\n6. \"Bitcoin was nearly $20,000 a year ago today\"\n\nOutput:",
      temperature: 0,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 64,
      stop: null,
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 15,
      name: 'Analyze sentiment with aspects',
      prompt: "Perform aspect based sentiment analysis on the below product review. \n- Provide overall sentiment score between 0 to 5 for the review\n- Provide a sentiment polarity score between 0 to 5 for each aspect\n- Mention the top positive aspect and top negative aspect, if any\n\nReview 1: This console is an absolute beast! I finally managed to get my hands on it through the company’s invite after 2 years of the chip shortage and scalping issues. It arrived a couple days later as scheduled, no problems. Set up was simple and was kinda surprised by the weight of the console. The login process took less than 10 min, quick and easy. Installed games then instantly felt the difference. Loading times is literally insane, first time experiencing an SSD as fast as this. I also really enjoy the controller, it feels really good in the hands.  Playing games has never been better for a competitive gamer like me. Sometimes I feel like I’m cheating with how powerful this thing is for only $500. However, I do wish there were more exclusives for this console but I feel like this console will get better as time goes on. \n\nOverall sentiment score: 4.5\nAspects with sentiment polarity score:\n- Console: 5\n- Set up : 5\n- Login process: 5\n- Loading times: 5\n- Controller: 5\n- Exclusives: 3\n\nTop positive aspect: Console \nTop negative aspect: Exclusives\n\nReview 2: This console has some great features, but it's not without its flaws. The graphics are excellent, but the load times can be frustratingly slow. The online community is active and fun to engage with, but the subscription fee is a bit steep.",
      temperature: 0,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 100,
      stop: null,
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 16,
      name: 'Extract entities from text',
      prompt: "Extract the person name, company name, location and phone number from the text below.\n\nHello. My name is Robert Smith. I’m calling from Contoso Insurance, Delaware. My colleague mentioned that you are interested in learning about our comprehensive benefits policy. Could you give me a call back at (555) 346-9322 when you get a chance so we can go over the benefits?",
      temperature: 0.2,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 150,
      stop: null,
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 17,
      name: 'Parse unstructured data',
      prompt: "There are many fruits that were found on the recently discovered planet Goocrux. There are neoskizzles that grow there, which are purple and taste like candy. There are also loheckles, which are a grayish blue fruit and are very tart, a little bit like a lemon. Pounits are a bright green color and are more savory than sweet. There are also plenty of loopnovas which are a neon pink flavor and taste like cotton candy. Finally, there are fruits called glowls, which have a very sour and bitter taste which is acidic and caustic, and a pale orange tinge to them.\n\nPlease make a table summarizing the fruits from Goocrux\n| Fruit | Color | Flavor |\n| Neoskizzles | Purple | Sweet |\n| Loheckles | Grayish blue | Tart |",
      temperature: 0,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 100,
      stop: [
        "\n\n"
      ],
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 18,
      name: 'Translate text',
      prompt: "Translate the following to French and Spanish. \n\n1. On a scale of 1 to 10, how satisfied are you with your in-store experience today?\n2. How likely are you to recommend our product to others?",
      temperature: 0.2,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 200,
      stop: null,
      chkpreresponse: true,
      chkpostresponse: true,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 19,
      name: 'Natural Language to SQL',
      prompt: "### Postgres SQL tables, with their properties:\n#\n# Employee(id, name, department_id)\n# Department(id, name, address)\n# Salary_Payments(id, employee_id, amount, date)\n#\n### A query to list the names of the departments which employed more than 10 employees in the last 3 months\n\nSELECT",
      temperature: 0,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 150,
      stop: [
        "#",
        ";"
      ],
      chkpreresponse: true,
      chkpostresponse: true,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 20,
      name: 'Natural language to Python',
      prompt: "# Write a python function to reverse a string. The function should be an optimal solution in terms of time and space complexity.\n# Example input to the function: abcd123\n# Example output to the function: 321dcba",
      temperature: 0.2,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 150,
      stop: [
        "#"
      ],
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 21,
      name: 'Explain a SQL query',
      prompt: "Explain what the below SQL query does. Also answer why someone might be interested in this time period, and why a company might be interested in this SQL query.\n\nDetails: Temperature = 0.8; MaxTokens = 150+; Stop sequences: #\nSELECT c.customer_id\nFROM Customers c\nJOIN Streaming s\nON c.customer_id = s.customer_id\nWHERE c.signup_date BETWEEN '2020-03-01' AND '2020-03-31'\nAND s.watch_date BETWEEN c.signup_date AND DATE_ADD(c.signup_date, INTERVAL 30 DAY)\nGROUP BY c.customer_id\nHAVING SUM(s.watch_minutes) > 50 * 60\n\nExplanation:",
      temperature: 0.7,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 250,
      stop: null,
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 22,
      name: 'Question answering',
      prompt: "A neutron star is the collapsed core of a massive supergiant star, which had a total mass of between 10 and 25 solar masses, possibly more if the star was especially metal-rich. Neutron stars are the smallest and densest stellar objects, excluding black holes and hypothetical white holes, quark stars, and strange stars. Neutron stars have a radius on the order of 10 kilometres (6.2 mi) and a mass of about 1.4 solar masses. They result from the supernova explosion of a massive star, combined with gravitational collapse, that compresses the core past white dwarf star density to that of atomic nuclei.\n\nAnswer the following question from the text above.\n\nQ: How are neutron stars created?\nA:",
      temperature: 0.7,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 256,
      stop: [
        "\n"
      ],
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: 'A:',
      postresponse: 'Q:',
    },
    {
      id: 23,
      name: 'Generate insights',
      prompt: "Over the last five years, our company has tracked the sales performance of our five products: Product A, Product B, Product C, Product D, and Product E. Our analysis shows that Product A has consistently been the top-selling product, with an average of 10,000 units sold per year, generating $2 million in annual revenue and a profit margin of 15%. However, the profit margin for Product A has decreased over the past two years, indicating a need for re-evaluation of our pricing strategy. Product B and Product C have also shown steady sales and profitability, with an average of 7,500 units sold per year, generating $1.5 million in annual revenue, and a consistent profit margin of 20%. Product D has seen a slight decline in sales over the past two years, with an average of 5,000 units sold per year, generating $1 million in annual revenue, and a profit margin of 10%. Our analysis suggests that we may need to adjust our marketing or product development strategies to improve its performance. Product E has shown the lowest sales and profitability among the five products, with an average of 2,000 units sold per year, generating $400,000 in annual revenue, and a profit margin of 5%. Further investigation and potential changes to our product offering or marketing approach are necessary to improve the performance of Product E. Overall, our sales performance analysis suggests that we should focus on maintaining the profitability of our top-selling products while exploring ways to improve the performance of our less successful products.\n\nCompare the performance of each product and determine which one is the most profitable.",
      temperature: 0.7,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 256,
      stop: [
        "\n"
      ],
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: 'A:',
      postresponse: 'Q:',
    },
    {
      id: 24,
      name: 'Chain of thought reasoning',
      prompt: "Q: Contoso made a sale for $50. The raw materials cost $10 and the labor was $20. What was the profit on the sale?\nA: The total cost was $10 + $20. Revenue was $50. Profit is revenue - total cost so the profit was $20\n\nQ: Fabrikam sold 100 widgets for $10 each. However, 30 of the widgets were returned for a full refund. How much money did Fabrikam make? \nA:",
      temperature: 0,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 100,
      stop: null,
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: '',
      postresponse: '',
    },
    {
      id: 25,
      name: 'Chatbot',
      prompt: "The following is a conversation with an AI assistant. The assistant is helpful, creative, clever, and very friendly. \n \nHuman: Hello, who are you? \nAI: Hello, I am an AI assistant. I am here to help you with anything you need.\nHuman: I'd like to cancel my subscription. \nAI:",
      temperature: 0.9,
      top_p: 1,
      frequency_penalty: 0,
      presence_penalty: 0,
      max_tokens: 256,
      stop: [
        "Human:",
        "AI:"
      ],
      chkpreresponse: false,
      chkpostresponse: false,
      preresponse: 'AI:',
      postresponse: 'Human:'
    },

  ];

  endPointDetail: any;
  Generate() {
    this.changebtn(true);
    const Obj = {
      prompt: this.prompt || this.speechservice.text,
     // Show loading animation
      temperature:1,// this.temperature,
      top_p: 0.5,//this.top_p,
      frequency_penalty: this.frequency_penalty,
      presence_penalty: this.presence_penalty,
      max_tokens: this.max_tokens,
      stop: this.stop,
    }
    this.loaderService.loading.next(true);
    this.totalUsage= null;
    this.messageService.sendPlaygroundRequest(Obj).subscribe((res: any) => {
   
      this.totalUsage= res?.usage || null;

      if(res?.status=='error' || res?.status==400){
        console.log('Error',res);
      
        this.toster.showError(res?.message || res?.data || 'Something went wrong');
      }else{
        res.choices.forEach((element: any) => {
          //console.log('element text::',JSON.parse(element.text))
           // Show loading animation
          this.loaderService.loading.next(false);
          const text = element?.text || '';
          this.prompt = this.prompt + text;
         
          var elem = document.getElementById('textbox-prompt') as HTMLElement;
          setTimeout(() => {
            elem.scrollTop = elem.scrollHeight;
          }, 1000);
          this.changebtn(false);
        });
      }
    }, (error:any) => {
       this.loaderService.loading.next(false);
      this.changebtn(false);
      console.log(error);

    });
  }
  onExampleChange(value: any) {
    const getvalue = this.examples_list.find((x: { name: any; }) => x.name == value);
    this.speechservice.text = '';
    if (value != 'Load an Example') {
      this.prompt = getvalue.prompt;
      this.temperature = getvalue.temperature;
      this.top_p = getvalue.top_p;
      this.frequency_penalty = getvalue.frequency_penalty;
      this.presence_penalty = getvalue.presence_penalty;
      this.best_of = getvalue.best_of;
      this.max_tokens = getvalue.max_tokens;
      this.stop = getvalue.stop;
      this.chkpostresponse = getvalue.chkpostresponse;
      this.chkpreresponse = getvalue.chkpreresponse;
      this.preresponse = getvalue.preresponse;
      this.postresponse = getvalue.postresponse;
    } else {
      this.prompt = getvalue.prompt;
      this.temperature = getvalue.temperature;
      this.top_p = getvalue.top_p;
      this.frequency_penalty = getvalue.frequency_penalty;
      this.best_of = getvalue.best_of;
      this.max_tokens = getvalue.max_tokens;
      this.stop = getvalue.stop;
      this.chkpostresponse = getvalue.chkpostresponse;
      this.chkpreresponse = getvalue.chkpreresponse;
      this.preresponse = getvalue.preresponse;
      this.postresponse = getvalue.postresponse;

    }
  }
  undo() {
    const getvalue = this.examples_list.find((x: { name: any; }) => x.name == 'Load an Example');
    this.prompt = getvalue.prompt;
    this.temperature = getvalue.temperature;
    this.top_p = getvalue.top_p;
    this.frequency_penalty = getvalue.frequency_penalty;
    this.presence_penalty = getvalue.presence_penalty;
    this.best_of = getvalue.best_of;
    this.max_tokens = getvalue.max_tokens;
    this.stop = getvalue.stop;
    this.chkpostresponse = getvalue.chkpostresponse;
    this.chkpreresponse = getvalue.chkpreresponse;
    this.preresponse = getvalue.preresponse;
    this.postresponse = getvalue.postresponse;
  }


  downloadAsDocx() {
    const content = this.textboxPrompt.nativeElement.value;
    const docx = ` ${content} `;

    const blob = new Blob([docx], { type: 'application/msword' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'downloaded-file.txt';
    a.click();
  }

  enable_skill=false
  clickonCV() {
    this.enable_skill = true;
   window.open('https://portal.azure.com/#view/Microsoft_Azure_Storage/ContainerMenuBlade/~/overview/storageAccountId/%2Fsubscriptions%2F6088ee04-d851-45bb-8093-f1abe686fe62%2FresourceGroups%2FOpenAI-POWERBI-DEV%2Fproviders%2FMicrosoft.Storage%2FstorageAccounts%2Fopenaidevtest/path/cv-match/etag/%220x8DB626FCC5BD8E4%22/defaultEncryptionScope/%24account-encryption-key/denyEncryptionScopeOverride~/false/defaultId//publicAccessVal/None','_blank')
  }

}
