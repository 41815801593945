import { Component } from '@angular/core';

@Component({
  selector: 'app-pg-bot',
  templateUrl: './pg-bot.component.html',
  styleUrls: ['./pg-bot.component.scss']
})
export class PgBotComponent {

 searchText: string = '';
  linkTitles = [
    { name: 'Write email', link: '/playground' },
    { name: 'Generate code', link: '/chat-completion' },
    { name: 'Upload file and generate insights', link: '/byod' },
    { name: 'ChatGPT', link: '/chat-completion' },
    { name: 'Software Installation request (IT genius)', link: 'https://genius-websdk.azurewebsites.net/' },
    { name: 'Report IT issue (IT geneus)', link: 'https://genius-websdk.azurewebsites.net/' },
    { name: 'Access request (IT genus)', link: 'https://genius-websdk.azurewebsites.net/' },
    { name: 'Check IT ticket status (IT genius)', link: 'https://genius-websdk.azurewebsites.net/' }
  ];

  get filteredLinks() {
    const lowerSearchText = this.searchText.toLowerCase();
    return this.linkTitles.filter(link =>
      link.name.toLowerCase().includes(lowerSearchText)
    );
  }

  get showAll() {
    return this.searchText.trim() === '' || this.filteredLinks.length === 0;
  }
}
