import { HttpClient, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DigiService {

  endpoint ="https://api.playground-dev.genpact.com/poc/avatar/"

  getDataAsPromise1(url: string, data: any = { method: 'POST' }, headers: any = {}): Promise<any> {
    let token: any = localStorage.getItem('token');
 
    // Add authorization header with bearer token
    headers = {
      ...headers,
      Authorization: 'Bearer ' + token,
      // "Content-Type": 'application/json'
    };
    // const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    // return this.http.post(`${this.imageGeneratorURL}/image-generate`, paylaod, { headers });
 
    return new Promise((resolve, reject) => {
      fetch(url, { ...data, headers })
        .then(response => response.json())
        .then(result => resolve(result))
        .catch(error => resolve(error));
    });
  }

  constructor(private http: HttpClient,) { }


  getDigiAvatar(end: any): Observable<any> {
 
    const promise = this.getDataAsPromise1(`${this.endpoint}generate-prompt/`,{ method: 'POST',body:end}); // Calling your method that returns a Promise
    return from(promise);
  }

  getAvatar(end: any): Observable<any> {
 
    const promise = this.getDataAsPromise1(`${this.endpoint}generate-image/`,{ method: 'POST',body:end}); // Calling your method that returns a Promise
    return from(promise);
  }

  getpersonality(end: any): Observable<any> {
 
    const promise = this.getDataAsPromise1(`${this.endpoint}ai-personality/`,{ method: 'POST',body:end}); // Calling your method that returns a Promise
    return from(promise);
  }
}
