
<div class="chat-box-new ">
    <div class="row">
        <div class="col-12">
            <div class="row-x">           

               


                <div class="conversation">
                    <!-- Page Heading block  Start -->
                    <h1 class="chat-box-header genpact-color py-2 mb-n3">
                         <div *ngIf="chatData.length > 0">
                            <button  id="downloadbtn" class="btn btn-light" title="Download PDF" (click)="downloadAsPdf()">
                                <i class="fa fa-download" aria-hidden="true"></i>
                            </button>
                        
                        </div>
                        <h4 class="text-white"> {{methodSelected?.name}}  </h4>
                    </h1>
                    
                    <!-- Page Heading block  End -->

                    <!-- Stop Generation & Regenerate button  start -->
                    <div class="res_generating" *ngIf="generateResponse">
                        <button (click)="unsubscribeChat()">
                            <span>Stop Generating</span>
                            <i class="fa fa-stop" aria-hidden="true"></i>
                        </button>
                    </div>

                    <div class="res_generating" *ngIf="!generateResponse && (chatData?.length > 0)">
                        <button (click)="userChat(true)">
                            <span>Regenerate</span>
                            <i class="fa fa-retweet" aria-hidden="true"></i>
                        </button>
                    </div>
                    <!-- Stop Generation & Regenerate button  end -->

                    <!-- Chat block  start -->
                    <div id="text-d" class="box messages smooth-scroll" #scrollChat>

                        <div class="message" *ngIf="chatData.length === 0">
                            <div class="user">
                                <img src="assets/bot.png" alt="GPT Avatar">
                                <i class="fa fa-reply icon-send" aria-hidden="true"></i>
                            </div>
                            <div class="content">{{methodSelected?.description}}</div>
                        </div>


                        <div class="message" *ngFor="let chat of chatData;let index = index"
                            [ngClass]="{'message-bot':chat?.role !='user'}">

                            <div class="user" *ngIf="chat?.role =='user';else botTemplate">
                                <img src="assets/people-1.png" class="shadow-sm" alt="User Avatar">
                                <i class="fa fa-mail-forward icon-send" aria-hidden="true"></i>
                            </div>
                            <ng-template #botTemplate>
                                <div class="user">
                                    <img src="assets/bot.png" class="shadow-sm" alt="GPT Avatar">
                                    <i class="fa fa-reply icon-send" aria-hidden="true"></i>
                                </div>
                            </ng-template>

                            <!-- Copy and display resonse block start -->
                            <div  class="content">
                                <button *ngIf="chat?.role !='user'" class="markdown-clipboard-button content-copy"
                                    (click)="copyToClipboard(chat?.content)">
                                    <i class="fa fa-clone" aria-hidden="true"></i>
                                </button>

                                <textarea [value]="chat?.content" style="display: none;"></textarea>

                                <div *ngIf="chat?.content &&  chat?.role !='user'" lineNumbers clipboard markdown
                                    [data]="chat?.content" [disableSanitizer]="true">
                                </div>


                                <div   *ngIf="chat?.content && chat?.role =='user'">
                                    {{chat?.content}}
                                </div>

                            </div>
                            <!-- Copy and display resonse block End -->

                        </div>
                    </div>
                    <!-- Chat block  End -->

                    <!-- Input block  Start -->
                    <div class="user-input" *ngIf="!generateResponse">
                        <div class="box input-box">
                            <textarea [(ngModel)]="prompt" class="message-input" placeholder="Ask a question" rows="1"
                                #textArea  (keyup.enter)="userChat()"></textarea>
                            <div id="send-button" (click)="userChat()">
                                <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                                    <path fill="#006ae3" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <!-- Input block  End -->

                </div>

            </div>
        </div>
    </div>
</div>

