import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MessageService } from '../shared/service/message.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { LoaderService } from '../shared/service/loader.service';
import { DataAnalyticsService } from '../shared/service/data-analytics.service';

@Component({
  selector: 'app-data-upload',
  templateUrl: './data-upload.component.html',
  styleUrls: ['./data-upload.component.scss']
})
export class DataUploadComponent implements OnInit {
  @Output() fileuploaded = new EventEmitter<any>();
  @Output() filename = new EventEmitter<any>();
  @Input() placeholder = 'Only .xlsx and .csv, files are allowed';
  @Input() inputAccept = '.xlsx,.XLXS,.csv,.CSV,.xls,.XLS'

  containsImages: boolean = false;
  clearFileInput(id: string) {

    let input1 = document.getElementById(id) as HTMLInputElement;
    input1.value = '';

  }
  selectedFiles?: FileList;
  currentFile?: File;

  progress = 0;
  message = '';
  isImageExits = false;
  fileInfos?: Observable<any>;
  constructor(
    private uploadService: MessageService,
     private toastMessage: ToastMessageService,
     private loaderService:LoaderService,
     private dataAnalyticsService:DataAnalyticsService,
     ) { }
  ngOnInit(): void {
  }


  uploads(event: any) {

    if (event?.target?.files) {

      if (event.target.files?.length > 0) {
        let fileSizeMB = 0;
       
        for (const file of event?.target?.files) {
          

          // checking file type
          if (!this.checkFileSupported(file)) {
            this.clearFileInput('file_upload');
            this.currentFile = undefined;
            this.toastMessage.showError('Invalid file format.');
            return;
          }

          //  clacuation file size
          fileSizeMB = fileSizeMB + (file.size / 1024 ** 2);

          if (fileSizeMB > 16) {
            this.clearFileInput('file_upload');
            this.currentFile = undefined;
            this.toastMessage.showError('File size should be less than 16 MB');
            return;
          }
        }

        console.log('file size ', fileSizeMB)
      }

      this.selectedFiles = event.target.files;

      let myfileName: any = [];
      for (const file of event?.target?.files) {
        myfileName.push(file.name);
      }

      if (myfileName?.length > 1) {
        localStorage.setItem('fileName_da', 'multi');
      } else {
        localStorage.setItem('fileName_da', myfileName[0]);
      }

      // localStorage.setItem('fileName', myfileName.join(' , '));
      this.filename.emit(myfileName.join(' , '));
      this.progress = 0;

      if (this.selectedFiles) {
        const file: File | null = this.selectedFiles.item(0);

        if (file) {
          this.currentFile = file;
          this.loaderService.loading.next(true);
     
          this.dataAnalyticsService.uploadFile(this.currentFile).subscribe({
            next: (event: any) => {
              console.log(event)
              this.loaderService.loading.next(false);
              this.fileuploaded.emit(event);

              // if (event.type === HttpEventType.UploadProgress) {
              //   this.progress = Math.round((100 * event.loaded) / event.total);
              // } else if (event instanceof HttpResponse) {
              //   console.log(event);
              //   if (event.body.response?.status == "success") {
              //     if(event.body.response?.gcp_status){
              //       this.fileuploaded.emit({gcp_status:true});
              //     }else{
              //       this.fileuploaded.emit({gcp_status:false});
              //     }

              //     this.toastMessage.showSuccess(event.body.response?.message);
              //   } else {
              //     this.fileuploaded.emit(false);
              //     this.toastMessage.showError(event.body.response?.message);
              //   }

              //   this.clearFileInput('file_upload');
              //   this.currentFile = undefined;
              // }
            },
            error: (err: any) => {
              this.loaderService.loading.next(false);
              this.fileuploaded.emit(false);
              this.clearFileInput('file_upload');
              this.currentFile = undefined;
              console.log(err);
              this.progress = 0;

              if (err?.error?.message) {
                this.toastMessage.showError(err.error.message);
              } else {
                this.toastMessage.showError('Could not upload the file!');
              }
              this.currentFile = undefined;
            },
          });
        }
        this.selectedFiles = undefined;
      }
    }
  }


  checkFileExtension(file: any) {
    // checking file type
    let patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    let fileExtension = (file.name).match(patternFileExtension);
    return '.' + fileExtension[1];
  }

  checkFileSupported(file: any) {
    return this.inputAccept.split(',').includes(this.checkFileExtension(file));
  }

}
