<div class="image-component">
    <div class="center-content">
      <h2>DAG V 1.0</h2>
      <p>Our highest quality image-to-image model</p>
      <a href="#" class="button">Try it now!!</a>
    </div>
    <!-- Add images around the heading -->
    <div class="floating-images">
      <img src="assets/digital-avatar/generated_basav.png" alt="Image 1">
      <img src="assets/digital-avatar/generated_hafeez.png" alt="Image 2">
      <img src="assets/digital-avatar/generated_monalisa.png" alt="Image 3">
      <img src="assets/digital-avatar/generated_rahman.png" alt="Image 4">
      <img src="assets/digital-avatar/generated_vengatesh.png" alt="Image 5">
      <img src="assets/digital-avatar/generated_aditi.png" alt="Image 6">
      <img src="assets/digital-avatar/image6.png" alt="Image 7">
      

    </div>
  </div>
  