import { Injectable } from '@angular/core';
import axios from 'axios';
@Injectable({
  providedIn: 'root'
})

export class CommonService {
  getMethodAxios = (url:any) => new Promise((resolve, reject) => {
    axios.get(url)
    .then((response) => {
      resolve(response);
    }).catch((error) => {
      console.log(error);
      reject(error);
    });
  })

  postMethodAxios = (url:any,body:any) =>  new Promise((resolve, reject) => {
    axios.post(url, body, {headers:{"Content-Type":'application/json'}})
      .then((response) => {
        resolve(response);
      }).catch((error) => {
        console.log(error);
        reject(error);
      });
  })
}
