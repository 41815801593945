import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class MetaThumbnailService {
  endpoint = environment.nodeEndpoint;


  constructor(private http: HttpClient) { }

  getOgDetail(url: string) {
    return this.http.get(`${this.endpoint}og-detail?url=${url}`);
  }









}