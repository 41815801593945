<app-sidenav></app-sidenav>

<div class="wrapper">
<section class="content">
    <div class="container-fluid responsive">
      <div class="container" style="padding: 25px;">

        <div class="row flex-nowrap">
          <main id="main">
            <div class="row mt-4 mb-3" >
              <div class="col-12 header" style="text-align: center; color:#073262;">
                <h4 style="margin-bottom: 25px;
                ">
                <span class="greeting">
                  {{ fileuploaded ? 'Genpact PodPulse' : 'Welcome to Genpact PodPulse' }}
                  <sup style="color:#00aecf;font-size: 16px;">Beta</sup>

                </span>
                
                  <!-- <span *ngIf="isloading" class="loader"></span> -->
                </h4>
  <div class="row" *ngIf="!fileuploaded">
    <div class="col-md-12" >
      <app-multiple-upload
      style="display: flex; justify-content: space-around;"
      #fileInput
      ins="Please upload your file in .txt, .docx, .pdf, or .pptx format. "
      placeholder="To ensure accurate podcast audio generation, the file must be in English, as content in other languages will not be processed."
      [header]="'Choose your file to upload'"

    multiple
     class="disabled-upload"
      inputAccept=".txt,.pdf,.PDF,.pptx,.PPTX,.pptx,.docx,.DOCX"
      (change)="fileChange($event)"
      ></app-multiple-upload>
      
    </div>
  </div>
  </div>
  </div>


  
  <div *ngIf="!fileuploaded">
    <div class="history-container" *ngIf="historyItems && historyItems.length > 0">
      <h2>Podcasts 
<sup>
  <!-- refresh -->
  <button class="btn-btn primary podbtn1" style="max-width: 250px; " (click)="refresh()" >
    <img src="assets/podpulse/refresh.png" alt="icon" class="mb-3 w-48 thumb-img" />
    </button>
</sup>

      </h2>
      <p style="  color: #073262;
      text-align: left;
    margin-top: -25px;"
      >Your audio files are available for 7 days from creation. Please download as needed.</p>


      <div class="grid-container" style="max-height:250px;overflow-y:scroll;">
        <!-- New Notebook Card -->
        <!-- <div class="notebook-card new-notebook">
          <div class="card-content">
            <span>+</span>
            <a href="/notebooklm">
            <p>New Notebook</p>
          </a>
          </div>
        </div>
     -->
        <!-- History Cards -->
         <!-- <input
  type="text"
  [(ngModel)]="searchTerm"
  placeholder="Search by filename"
  class="form-control"
  style="margin-bottom: 20px;"
/> -->
        <div *ngFor="let item of historyItems" class="notebook-card" (click)="podcastcheck(item.podId, item.base_filename,item.status,item.messages)">
          <div class="card-content">
            <div style="display: flex;">
              <img [src]="item.status === 'CONVERSATION - COMPLETED, AUDIO - COMPLETED' ? 'assets/podpulse/mp3.png' : getFileIcon(item.filetype)" 
     alt="icon" class="mb-3 w-48 thumb-img1" />


              <a [title]="item.base_filename">{{ item.title}}</a>
            </div>
        
            <div style="display: flex; flex-direction: column; align-items: flex-end;">
              <!-- download btn -->
              <!-- <button 
              class=""
              tooltip="Download Podcast"
              [disabled]="item.audio == null || item.audio == '' || item.audio == undefined
              || item.audio == 'Wip' || item.audio == 'WiP' || item.status !== 'CONVERSATION - COMPLETED, AUDIO - COMPLETED'
              "
                style="max-width: 160px; background-color: transparent; border: none; z-index: 1;"
                (click)="downloadPodcast(item.audio, item.base_filename,item.podId); $event.stopPropagation()"
              >
                <i style="color: #00aecf; font-size: 18px;" class="bi bi-download"></i>
              </button> -->
           
              <!-- delete btn -->
              <button
              type="button"
              class="delete-btn"
              aria-label="Delete Podcast"
              (click)="deletepodcast(item.podId); $event.stopPropagation()"
              style="background: transparent; margin-top: 1px; border: none;"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Delete Podcast"
            >
              <i class="bi bi-trash delbtn" style="font-size: 18px;"></i>
            </button>
            
              

            </div>
            

          </div>
          <audio  style="max-width: 210px;
          max-height: 40px;
          border: none;
          margin-top:10px;
          box-shadow: none;
         
      "   controls>
            <!-- <source src="horse.ogg" type="audio/ogg"> -->
           <source [src]="podcasturl+'/' + item.podId + '/audio?download=true&token=' + token1" type="audio/mpeg">
           Your browser does not support the audio element.
         </audio>

         <div style="color: #1BA641;" *ngIf=" item.audio !== null ">
        
         {{ extractPercentage(item.audio) }}
</div>
          <p>{{ item.timestamp }}</p>
        </div>
        
        
      </div>
    </div>
    
 </div>
 
 <!--  -->
 

  <div class="row px-md-4 "
  style="
         flex-direction: row;
        justify-content: flex-end;"
  *ngIf="fileuploaded">
 
    <div class="col-12 file-list" >
    
       
      
      <h6 style="display: contents; text-decoration: underline;">
        <img style="height: 22px;
        width: 25px;
    " src="/assets/images/icons/file_upload.svg" alt="icon" class="mb-3 w-48 thumb-img" />
        <!-- {{poid}} -->
        {{filename}}
         <!-- <span (click)="Isuploading=true;filename='';queryresponse='';searchquery='';fileuploaded=false;summary=''; chatData=[];poid='' ;audiop = false;refresh()">
          <i class="fa fa-close" style="color:red;"></i>
        </span> -->
      </h6>
    </div>
  </div>
  <br>

<!-- Loading icon -->
<div class=" d-flex" *ngIf="fileuploaded" style="    display: flex !important;
justify-content: flex-start;
margin-bottom: 15px;
gap: 25px;
">
  <button class="btn-btn primary podbtn1" style="max-width: 250px; " (click)="Isuploading=true;filename='';queryresponse='';searchquery='';fileuploaded=false;summary=''; chatData=[];poid='' ;audiop = false;refresh1();">
    <img src="assets/podpulse/back.png" alt="icon" class="mb-3 w-48 thumb-img" /> 
  </button>
    <!-- <button class="btn-btn primary podbtn1" style="max-width: 250px; " (click)="generateConversation(poid)" >
      <img src="assets/podpulse/refresh.png" alt="icon" class="mb-3 w-48 thumb-img" />
    </button> -->
    <!-- <button class="btn-btn primary podbtn1" style="max-width: 250px; " (click)="download()">
      <img src="assets/podpulse/download.png" alt="icon" class="mb-3 w-48 thumb-img" />
    </button> -->


  </div>

<!-- Loading icon -->

            <div style="display: none;" class="row-x" *ngIf="fileuploaded">
                <div class="conversation">

                  <div class="res_generating">
                    <button *ngIf="!audiop" style="max-width: 250px; color:#fff !important;" class="btn btn-primary podbtn"
                    (click)="generateAudio(this.poid)"
                    >
                        {{isloading ? 'Generating Podcast' : 'Generate Podcast'}}
                        <span *ngIf="isloading" class="loader" >
                         
                        </span>
                        
                    </button>

                      <audio *ngIf="audiop" style="    margin-top: 15px; margin-left: -6px;" controls>
                     <!-- <source src="horse.ogg" type="audio/ogg"> -->
                    <source [src]="selectedAudio" type="audio/mpeg">
                    Your browser does not support the audio element.
                  </audio>
                </div>
              <!-- three buttons back,retry,download -->
              <div class=" d-flex" *ngIf="fileuploaded" style="    display: flex !important;
              justify-content: flex-start;
              gap: 25px;
          ">
                <button class="btn-btn primary podbtn1" style="max-width: 250px; " (click)="Isuploading=true;filename='';queryresponse='';searchquery='';fileuploaded=false;summary=''; chatData=[];poid='' ;audiop = false;refresh();">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
                  </svg>  
                </button>
                  <button class="btn-btn primary podbtn1" style="max-width: 250px; " (click)="generateConversation(poid)" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-clockwise" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"/>
                      <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"/>
                    </svg>
                  </button>
                  <button class="btn-btn primary podbtn1" style="max-width: 250px; " (click)="download()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
                      <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
                    </svg>
                  </button>


                </div>

                                                           
                    <div id="text-d" class="box messages smooth-scroll" #scrollChat>
                      <div class="message" *ngFor="let chat of chatData; let index = index">
                        <div class="user" *ngIf="chat.role === 'user'; else botTemplate">
                          <img src="assets/people-1.png" class="shadow-sm" alt="User Avatar">
                          <i class="fa fa-mail-forward icon-send" aria-hidden="true"></i>
                        </div>
                        <ng-template #botTemplate>
                          <div class="user">
                            <img src="assets/people-1.png" class="shadow-sm" alt="GPT Avatar">
                            <i class="fa fa-reply icon-send" aria-hidden="true"></i>
                          </div>
                        </ng-template>
                      
                        <div class="content" *ngIf="chat.content">
                          <div *ngIf="chat.role == 'user'">
                            <div markdown mermaid lineNumbers clipboard [disableSanitizer]="true">
                              <strong>{{ chat.speaker }}</strong><br />
                      
                              <!-- If isEditing is true, show textarea for editing, otherwise show the content -->
                              <textarea *ngIf="chat.isEditing" [(ngModel)]="chat.editContent" class="form-control"></textarea>
                              <p *ngIf="!chat.isEditing">{{ chat.content }}</p>
                            </div>
                      
                            <!-- Edit, Save, and Cancel Buttons for User -->
                            <button *ngIf="!chat.isEditing" (click)="startEditing(index)" class="btn btn-sm btn-primary">
                              <i class="fa fa-pencil" aria-hidden="true"></i> <!-- Edit Icon -->

                            </button>
                            <button *ngIf="chat.isEditing" (click)="saveEdit(index)" class="btn btn-sm btn-success">
                              <i class="fa fa-check" aria-hidden="true"></i> <!-- Save Icon -->

                            </button>
                            <button *ngIf="chat.isEditing" (click)="cancelEdit(index)" class="btn btn-sm btn-danger">
                              <i class="fa fa-times" aria-hidden="true"></i> <!-- Cancel Icon -->
                            </button>
                          </div>
                      
                          <div *ngIf="chat.role !== 'user'" style="margin-top: -25px;">
                            <div markdown mermaid lineNumbers clipboard [disableSanitizer]="true">
                              <strong>{{ chat.speaker }}</strong><br />
                              
                              <!-- If isEditing is true, show textarea for editing, otherwise show the content -->
                              <textarea *ngIf="chat.isEditing" [(ngModel)]="chat.editContent" class="form-control"></textarea>
                              <p *ngIf="!chat.isEditing">{{ chat.content }}</p>
                            </div>
                      
                            <!-- Edit, Save, and Cancel Buttons for Assistant -->
                            <button *ngIf="!chat.isEditing" (click)="startEditing(index)" class="btn btn-sm btn-primary">
                              <i class="fa fa-pencil" aria-hidden="true"></i> <!-- Edit Icon -->

                            </button>
                            <button *ngIf="chat.isEditing" (click)="saveEdit(index)" class="btn btn-sm btn-success">
                              <i class="fa fa-check" aria-hidden="true"></i> <!-- Save Icon -->

                            </button>
                            <button *ngIf="chat.isEditing" (click)="cancelEdit(index)" class="btn btn-sm btn-danger">
                              <i class="fa fa-times" aria-hidden="true"></i> <!-- Cancel Icon -->
                            </button>
                          </div>
                        </div>
                      </div>
                      
                        <!-- <div id="targetRed" style="opacity: 0;;">
                            ...
                        </div> -->
                    </div>

                    <!-- <div class="user-input" >

                      <div class="box input-box" >
                     
                                </div> -->
                      <!-- <div class="box input-box" >
                          <textarea id="t1" 
                            
                        id="txtquery" class="form-control byod-input "
                       
                        autosize="true" placeholder="Enter your search query here"></textarea> -->
                      <!-- <div class="d-flex" style="align-items: center; gap: 10px;">
                        <div id="send-button"  >
                            <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                                <path fill="#006ae3" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
                            </svg>
                        </div>
                      </div> -->
                    <!-- </div> -->
                    <!-- </div> -->
                </div>
    </div>


    <div class="container1" *ngIf="fileuploaded">
      <!-- Left side: file name at the top, chat below -->
    
      <div class="left-side" >
        <!-- <div class="file-name" >
    
       
      <div class="col-12 file-list" >
          <h6 style="display: contents; text-decoration: underline;">
            <img style="height: 22px;
            width: 25px;
        " src="/assets/images/icons/file_upload.svg" alt="icon" class="mb-3 w-48 thumb-img" />
            {{filename}}
          
          </h6>
</div>

        </div> -->
       
        <div class="chat-interface">
          <div style="display: flex;
    justify-content: space-between;">
            <!-- File Upload Button -->
             <span style="color: #00aecf;
             font-size: 20px;
             font-weight: bold;">Generated Transcript</span>
            <input type="file" accept=".txt" (change)="updatepodcast($event,poid)" style="display:none" #fileinput>
            
            <div>
              <!-- regerate -->
              <button *ngIf="isregen" title="Regenerate Podcast" class="btn-btn primary podbtn1" style="max-width: 250px;" (click)="regen(poid)">
                <img src="assets/podpulse/refresh.png" alt="icon" class="mb-3 w-48 thumb-img" />
                </button>

            <!-- Upload Transcript Button -->
            <button title="The names of the speakers should match those in the downloaded transcript." *ngIf="isupload"  class="btn-btn primary podbtn1" style="max-width: 250px;" (click)="fileinput.click()">
              <img src="assets/podpulse/upload.png" alt="icon" class="mb-3 w-48 thumb-img" />
            </button>
          
            <!-- Download Transcript Button -->
            <button title="Download Transcript" class="btn-btn primary podbtn1" style="max-width: 250px;" (click)="download()">
              <img src="assets/podpulse/download.png" alt="icon" class="mb-3 w-48 thumb-img" />
            </button>
          </div>
          </div>
          
          <!-- Dynamic Chat messages using *ngFor with role, speaker, and content -->
          <div *ngFor="let chat of chatData; let i = index"
     [ngClass]="{'assistant-message': chat.role === 'assistant', 'user-message': chat.role === 'user'}"
     class="message">

  <!-- Speaker Name -->
  <p class="speaker" [style.color]="'#00aecf'">{{ chat.speaker }}</p>

  <!-- Edit mode -->
  <div *ngIf="isEditing[i]; else displayMode">
    <!-- Editable text area for message content -->
    <textarea [(ngModel)]="editedContent[i]" style="font-size:12px; width: 100%; height:150px;"></textarea>

    <!-- Save and Cancel buttons -->

    <div style="
    display: flex;
    justify-content: flex-end;
">
    <button (click)="saveEdit(i)" class="save-btn">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-floppy" viewBox="0 0 16 16">
        <path d="M11 2H9v3h2z"/>
        <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z"/>
      </svg>
    </button>
    <button (click)="cancelEdit(i)" class="cancel-btn">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
        <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
      </svg>
    </button>
</div>

  </div>

  <!-- Display mode -->
  <ng-template #displayMode>
    <!-- Display message content -->
    <p style="font-size:12px;">{{ chat.content }}</p>

    <!-- Edit button -->
    <div style="
    display: flex;
    justify-content: flex-end;
    display: none;
">
    <button (click)="startEdit(i)" class="edit-btn">
      <i class="bi bi-pencil"></i>
    </button>
</div>

  </ng-template>
</div>

        </div>
        
      </div>
      
      <!-- Right side: centered button -->
      <div class="right-side">

       

      <div>
        
        <!-- <label >Podcast Overview</label>  -->
       

       <div >
        <div class="audio-overview-header">
          <span style="color: #00aecf;">Podcast Audio Generation</span>
          <div style="display:none;" class="info-icon-container">
            <i class="bi bi-info-circle info-icon"></i>
            <div class="tooltip-content">
              <p>
                Audio Overviews are lively “deep dive” discussions that summarize the key topics in your sources. This is an experimental feature and below are some notes to help you get started:
              </p>
              <ul>
                <li>Audio Overviews (including the voices) are AI-generated, so there might be inaccuracies and audio glitches.</li>
                <li>Audio Overviews are not a comprehensive or objective view of a topic, but simply a reflection of your sources.</li>
                <li>Audio Overviews are only in English at this moment.</li>
                <li>It can take several minutes to generate an Audio Overview (feel free to do other things while generation is in progress).</li>
                <li>You need edit access to a notebook in order to generate or delete an Audio Overview.</li>
                <li>You can share your feedback on an Audio Overview using the thumbs up and thumbs down buttons.</li>
              </ul>
            </div>
          </div>
        </div>
        
        
        <div *ngIf="!audiop" class="audio-overview-container">
          <div class="audio-icon">
            <i *ngIf="!isloading" class="bi bi-speaker"></i> <!-- Example icon for audio (replace with your desired icon) -->
             <span class="loader" *ngIf="isloading">
             
             </span>
          </div>
          <div *ngIf="!isloading"  class="audio-text">
            Click to Generate the Podcast
          </div>
          <div *ngIf="isloading" class="audio-text" style="color:#00aecf">
            {{percent}}
          </div>
          <button class="load-button" (click)="generateAudio(this.poid)">
            {{isloading ? 'Generating' : 'Generate'}}
          </button>
        </div>
        
         
<!-- audio -->
           <audio *ngIf="audiop" style="    margin-top: 15px; margin-left: -6px;" controls>
          <!-- <source src="horse.ogg" type="audio/ogg"> -->
         <source [src]="selectedAudio" type="audio/mpeg">
         Your browser does not support the audio element.
       </audio>
     </div>

    </div>

     </div>
     
    </div>
    <br>
    <br>
    <br>
    









  </main>
  </div>





















  </div>
  </div>
  </section>
</div>





















<!-- modal -->
 <!-- policy & condition toggle -->
 <button type="button" class="btn btn-primary d-none" id="playground_policy_model"
 (click)="openModal(playground_policy_model)">
</button>

<!-- policy & condition block -->
<ng-template #playground_policy_model>
 <div class="modal-body tcolor">
   <div class="row">
     <div class="col-12 text-decoration-underline fw-bold text-primary" style="color: #073262 !important;">Disclaimer</div>
   </div>
   <div class="row">
     <div class="col-12 pt-2" style="font-size: 13px;">
       <ul style="color: #073262 !important;
                               max-height: 400px;
                               overflow-y: auto;
       ">
         <li>
          <b>AI-Generated Content</b>: The audio produced by this tool uses artificial intelligence. Verify facts independently as the content may not always be accurate or up-to-date.
        </li>
        <li>
          <b>Voice Representation</b>: The synthetic voices do not represent real individuals. Any resemblance to actual persons is coincidental.
        </li>
        <li>
         <b> Retention Period</b>: Audio files will be retained for a period of 7 days from the time the podcast is generated.
        </li>
        <li>
          <b>Technology Limitations</b>: Generated audio may lack emotional nuance and human-like expression, affecting listener perception.
        </li>
        <li>
          <b>Misinformation Potential</b>: Automated systems can generate misleading information. Use the tool cautiously and verify content accuracy.
        </li>
        <li>
         <b> User Responsibility</b>: By using this tool, you agree to avoid sharing Genpact/client confidential information or personally identifiable information (PII). Only upload documents that Genpact has contractual permission to maintain on its network.
        </li>
        <li>
          <b>Intellectual Property</b>: Ensure text input complies with copyright laws; the tool does not provide legal advice on content ownership or rights.

        </li>
        <li>
          <b>Audit and Refinement</b>: Your inputs may be audited and used to refine the tool's accuracy.
        </li>
       </ul>
     </div>
   </div>
   <div class="row">
     <div class="col-12 ml-4">
       <input id="byod_check_label" type="checkbox" [(ngModel)]="chkagree" class="form-check-input" />
       <label class="form-check-label text-decoration-underline fw-bold text-primary"
         for="byod_check_label" style="color: #073262 !important; margin-left: 15px;">
         I acknowledge that I have read, understand and agree to above instructions and disclaimers.
       </label>
     </div>

   </div>

 </div>
 <div class="modal-footer">
   <button type="button" class="btn button-genpact text-white  cursor-pointer" [disabled]="!chkagree"
     (click)="modalRef?.hide()">
     Proceed
   </button>
 </div>
</ng-template>