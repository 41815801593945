import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements AfterViewInit {
  @Output() fileuploaded = new EventEmitter<any>();
  @Input() expectedFilenames: string[] = [];
  @Input() placeholder = 'doc, pdf and ppt';
  @Input() inputAccept = '.pdf,.PDF,.pptx,.PPTX,.ppt,.pptx,.doc,.DOC,.docx,.DOCX'
  @Input() maxFileSize: any = 16;

  selectedFiles?: FileList;
  currentFile?: File;
  toggle= true;
  constructor() {

  }

  ngAfterViewInit(): void {
    if (typeof (this.maxFileSize) === 'string') this.maxFileSize = parseFloat(this.maxFileSize)
    console.log('max file size', this.maxFileSize)
  }

  clearFileInput(id: string) {

    let input1 = document.getElementById(id) as HTMLInputElement;
    input1.value = '';

  }

  upload(event: any): void {
    var patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    var fileExtension = (event.target.files[0].name).match(patternFileExtension);

    // localStorage.setItem('file_extension', fileExtension[1]);
    const fileSizeMB = event.target.files[0].size / 1024 ** 2;

    if (fileSizeMB > this.maxFileSize) {
      this.clearFileInput('file_uploadx');
      this.currentFile = undefined;
      alert(`File size should be less than ${this.maxFileSize} MB`);
    } else {
      this.selectedFiles = event.target.files;
      // localStorage.setItem('fileName', event.target.files[0].name);
      // this.filename.emit(event.target.files[0].name);


      if (this.selectedFiles) {
        const file: File | null = this.selectedFiles.item(0);

        if (file) {
          this.currentFile = file;
          let data = {
            file: file,
            name: event.target.files[0].name
          }
          this.toggle = false;
          this.fileuploaded.emit(data);

        } else {
          this.currentFile = undefined;
        }

        this.selectedFiles = undefined;
        setTimeout(() => {
          this.toggle = true;
        }, 100);
      }
    }
  }

}
