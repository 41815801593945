import { Component, Inject, Input, OnInit } from '@angular/core';

import { ElementRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { OktaAuthService } from '../shared/okta/okta.service';

import { Location } from '@angular/common';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  @Input() item = ''; // decorate the property with @Input()
  currenti = this.item as string;
  path = '';
  username = '';

  isHomePage: boolean = false;

  ngOnInit() {
    this.username = localStorage.getItem('okta-username') ? localStorage.getItem('okta-username') as string : 'Guest';
    this.isHomePage = this.location.isCurrentPathEqualTo('/home-openai');
  }

  goBack() {
    this.location.back();
  }

  check(): string {
    if (this.currenti === 'All') {
      this.path = '/demos';
    } else {
      this.path = '/playground';
    }
    return this.path;
  }
  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private router: Router,
    private elementRef: ElementRef,
    private oktaService: OktaAuthService,
    private location: Location
  ) { }

  @HostListener('window:resize', ['$event'])
  onResize(event: { target: { innerWidth: any; }; }) {
    let screenWidth = event.target.innerWidth;
    let container = this.elementRef.nativeElement.querySelector('.res-container');

    if (container) {
      if (screenWidth < 768) {
        container.style.width = '100%';
      } else {
        container.style.width = '768px';
      }
    }
  }
  logout() {

    localStorage.removeItem('okta-token-storage');
    localStorage.removeItem('okta-username');
    localStorage.removeItem('token');
    localStorage.clear();
    sessionStorage.clear();
    this.oktaService.logout();
    this.router.navigate(['logout']);
  }
  hide() {
    const animated = document.getElementsByClassName("animated-icon");
    this.toggleClass('open', animated[0]);
    const el = document.getElementById("mySidenav") as HTMLElement;
    el.style.width = "0px";
  }
  clo() {
    const el = document.getElementById("mySidenav") as HTMLElement;
    const animated = document.getElementsByClassName("animated-icon");
    var width = el?.style.width;
    if (width === "250px" || width == "") {
      el.style.width = "0px";
      this.toggleClass('open', animated[0]);
    }
    else {
      el.style.width = "0px";
      this.toggleClass('open', animated[0]);
    }
  }
  Nav() {
    const el = document.getElementById("mySidenav") as HTMLElement;
    const animated = document.getElementsByClassName("animated-icon");
    var width = el?.style.width;
    if (width === "0px" || width == "") {
      el.style.width = "250px";
      this.toggleClass('open', animated[0]);
    }
    else {
      el.style.width = "0px";
      this.toggleClass('open', animated[0]);
    }
  }
  toggleClass(className: string, el: any) {
    const hasClass = el.classList.contains(className);
    if (hasClass) {
      el.classList.remove(className);
    } else {
      el.classList.add(className);
    }
  }

  downloadFile() {
    const filePath = 'assets/images/zoom-bg.zip'; // Replace with your file path in the assets folder

    fetch(filePath)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'zoom-bg.zip'; // Set the file name for download
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        // Handle error if download fails
        console.error('Download failed:', error);
      });
  }
}
