import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  standalone:true,
  imports:[CommonModule],
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy{

  loading= false;
  sub!:Subscription;

  constructor(private loaderservice:LoaderService){

  }

  someAsyncOperation() {
    this.loaderservice.loading.next(true); // Show loader
    // Simulating an async operation
    setTimeout(() => {
      // After the operation is complete
      this.loaderservice.loading.next(false); // Hide loader
    }, 3000);
  }
  ngOnInit(): void {
    this.sub = this.loaderservice.loading.subscribe((res:any)=>{
      this.loading = res;
    })
  }

  ngOnDestroy(): void {
      this.sub?.unsubscribe();
  }


}
