<app-sidenav></app-sidenav>



<div class="container mt-5 text-align-center" *ngIf="chatMessages.length === 0">
  <div style="display: flex;justify-content: center;">
    <div class="instructions-box" *ngIf="!fileName">

      <h4 class="chat1-header " style="text-align: center;">
        <span class="greeting">Data Analyzer
        </span><br>
        <!-- <span class="question">Please select/Add a prompt to get started</span> -->
        <span class="question">Please Upload a file to get started</span>

      </h4>
      <p >An assistant who Excels at making and handling data visuals, using tools to create charts, read and show data, and gain insights from files.</p>
    </div>
    </div>
</div>



<div class="row px-md-5 ">

<div class="col-12 file-list" *ngIf="fileData" >
  File Name:
  <h6 style="display: contents; text-decoration: underline;">
      {{fileName}}
      <span (click)="resetFile()">
          <i class="fa fa-close" style="color:red;"></i>
      </span>
  </h6>
</div>
</div>


  <!-- related questions -->
   <div *ngIf="fileData">
  <div class="row mt-5  px-md-5" >
    <div  class="col-md-12" style="text-align: justify; padding-top: 10px">
      <div class="heading">

        <svg class="fontawesomesvg" xmlns="http://www.w3.org/2000/svg" height="1em"
          style="vertical-align: baseline;" viewBox="0 0 576 512">
          <path
            d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z" />
        </svg>
        &nbsp;<h4 style="display: contents;">Recommended Questions</h4>
        <!-- <div class="answer w-100 mt-3" *ngFor="let answer of researchData"> -->
        <div class="answer w-100 mt-3" *ngFor="let question of relatedQuestionList"
          (click)="userMessage=question">

          <div class="col-sm-12">
            <div class="related-question">
              <div> {{ question }}</div>
              <button class="btn btn-sm border-0 pl-3">
                <i class="fa fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
  <!-- related questions -->






<br><br><br>

<!-- Chat interface -->
      <div class="container-fluid"  *ngIf="chatMessages.length > 0">
        <!-- <div class="row">
          <div class="col-lg-8 offset-lg-2"> -->
            <!-- Chat interface -->
            <!-- <div class="chat-body"> -->
              <!-- <div class="chat-messages"> -->
                <!-- Messages loop -->
                <div *ngFor="let message of chatMessages; let i = index" [ngClass]="{'user': message.user === 'user', 'bot': message.user === 'bot'}">
                  <!-- <div class="message-container"> -->
                    <div class="message-content">
                      <div class="message-header">
                        <!-- User and bot avatars/icons -->
                        <span *ngIf="message.user === 'user'" class="avatar">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16">
                            <defs>
                              <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
                                <stop offset="0%" style="stop-color:#073262;stop-opacity:1" />
                                <stop offset="50%" style="stop-color:#00AECF;stop-opacity:1" />
                                <stop offset="100%" style="stop-color:#FF555F;stop-opacity:1" />
                              </linearGradient>
                            </defs>
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" fill="url(#grad1)"/>
                            <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" fill="url(#grad1)"/>
                          </svg>
                        </span>

                        <span *ngIf="message.user === 'bot'" class="avatar">
                          <img src="https://img.icons8.com/?size=256&amp;id=50200&amp;format=png" width="24" height="24" alt="Bot icon">
                        </span>
                        <!-- Display message text and image if available -->
                        <span *ngIf="message.user === 'user'" >{{ message.text }}</span>

                        <span *ngIf="message.user === 'bot'" markdown lineNumbers>{{ message.text }}
                          <br>
                          <span *ngIf="message.image"><img style="max-width: 60%;" [src]="message.image" alt="Fetched Image" /></span>

                          <!-- <button *ngIf="message.data !== undefined && message.data !== null && message.data !== ''"
                          (click)="downloadExcelFile(message.data,message.format)">
                      Download the Excel file
                    </button> -->
                                       </span>
                      </div>
                      <!-- Message actions (copy and regenerate) -->
                      <div class="message-actions">
                        <button style="border:none;color:#00aecf;" *ngIf="(message.user === 'user' || message.user === 'bot') && !message.image"   class="btn btn-outline-secondary btn-sm" (click)="copyMessage(message.text)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-copy" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"/>
                          </svg>
                        </button>
                        <button style="border:none;color:#ff555f;" *ngIf="message.user === 'user'" class="btn btn-outline-secondary btn-sm" (click)="regenerateAnswer(message.text)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41m-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9"/>
                            <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5 5 0 0 0 8 3M3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9z"/>
                          </svg>
                        </button>
                        <!-- Download but only for bot and message.image -->
                        <button style="border:none;color:#073262;" *ngIf="message.user === 'bot' && message.image" class="btn btn-outline-secondary btn-sm" (click)="downloadImage(message.image)">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                            <path d="M8 1a.5.5 0 0 1 .5.5v7.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 9.293V1.5A.5.5 0 0 1 8 1z"/>
                            <path fill-rule="evenodd" d="M1 13.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1H1.5a.5.5 0 0 1-.5-.5z"/>
                          </svg>
                          </button>
                          <button style="border:none;color:#073262;" *ngIf="message.user === 'bot' && message.data !== undefined && message.data !== null && message.data !== '' && message.data !== 'No data for excel file'" class="btn btn-outline-secondary btn-sm" (click)="downloadExcelFile(message.data,message.format)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
                              <path d="M8 1a.5.5 0 0 1 .5.5v7.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 9.293V1.5A.5.5 0 0 1 8 1z"/>
                              <path fill-rule="evenodd" d="M1 13.5a.5.5 0 0 1 .5-.5h13a.5.5 0 0 1 0 1H1.5a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            </button>
                      </div>
                    </div>
                  <!-- </div> -->
                </div>
              <!-- </div> -->
            <!-- </div> -->

<!-- </div>
</div> -->
</div>

<!-- back arrow button -->
<div class="container mt-5 " style="text-align: center;" *ngIf="chatMessages.length > 0" >
  <!-- <button class="back-btn" (click)="clearChat()">Back</button> -->
  <button  type="button" style="background-color:#00AECF" (click)="clearChat()" title="Back" class="shadow btn text-light btn-plight px-4">
    <i class="fa fa-arrow-left" aria-hidden="true"></i>
    </button>
  </div>
<br><br><br><br><br><br><br><br>


      <div class="card-footer fixed-bottom custom-card-footer" style="background-color:#ffffff;"  *ngIf="txtstatus" >





        <div class="textarea-container"  *ngIf="txtstatus">
          <textarea (keydown.enter)="checkassistantstatus()" [disabled]="!fileName" [(ngModel)]="userMessage" rows="3" class="form-control" placeholder="Ask your question...."></textarea>
          <div class="input-group-append">
            <!-- <button id="image-upload-btn" style="border:none;" class="btn btn-outline-secondary" type="button">
              <svg id="image-upload-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-image" viewBox="0 0 16 16">
                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
                <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z"/>
              </svg>
            </button> -->
            <!-- <button
            [disabled]="!selectedPrompt"
               id="image-upload-btn"
               style="border:none;"
                 class="btn btn-outline-secondary"
             type="button"
            (click)="triggerFileInputClick1()">
           <svg id="image-upload-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-image" viewBox="0 0 16 16">
            <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0"/>
             <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1z"/>
                </svg>
           </button> -->
   <input
       #imageinput
         type="file"
  accept="image/*"
  style="display: none;"
  (change)="onFileChange($event)">






            <button
            [disabled]="!selectedPrompt "
            style="border:none;"
            [title]="'Only the following file types are allowed: ' + fileTypes.join(', ')"

            class="btn btn-outline-secondary"
            type="button"
            (click)="triggerFileInputClick()">
            <i class="bi bi-paperclip"></i>
          </button>
          <input
            type="file"
            [accept]="fileTypes"
            #fileInput
            style="display: none;"
            (change)="onFileChange($event)">







            <button

            [disabled]="!userMessage"  style="border:none; color:#00AECF" class="btn btn-outline-secondary" type="button"(click)="checkassistantstatus()" > <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
              <path fill="#006ae3" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"></path>
          </svg></button>
          </div>
        </div>
      </div>










































<!-- policy & condition toggle -->
<button type="button" class="btn btn-primary d-none" id="byod_policy_model"  (click)="openModal(byod_policy_model)">
</button>

<!-- policy & condition block -->
<ng-template #byod_policy_model>
    <div class="modal-body">

        <div class="row">
            <div class="col-12  fw-bold text-primary">In order to adhere to the Legal and Security policies,
                please take care of below mentioned points:</div>
        </div>
        <div class="row">
            <div class="col-12" style="font-size: 13px;">
                <ol style="list-style:decimal;">
                  <li>
                    	Please be aware that the outputs have been generated by an artificial intelligence model. While efforts have been made to provide accurate and relevant information, there is a possibility of errors or inaccuracies. The response you receive should not be considered as professional or expert advice. We strongly recommend cross-checking the information with reliable sources or consulting a human expert when necessary. The purpose of this AI is to assist and provide general information, but it may not always be perfect. Use the information at your own discretion.
                  </li>
                  <li>
                    Any documents uploaded in the tool should be hosted on Genpact network.
                  </li>
                    <li>
                      Only documents hosted on the Genpact network and for which Genpact has contractual permission to maintain on the Genpact network may be uploaded here.
                    </li>
                    <li>
                      This application is for Genpact internal employees use only and only for internal usage.
                    </li>
                    <!-- change here-->
                    <li>
                      	If you have any inquiries, we kindly encourage you to consult Genpact's <a class="text-skyblue cursor-pointer" style="text-decoration: underline; "
                        href="https://genpactonline.sharepoint.com/sites/Legal/Compliance/Shared%20Documents/Integrity/Code%20of%20Conduct.pdf"
                        target="_blank"> Code of Conduct document</a> and ChatGPT and Use of AI Programs Guidelines and <a class="text-skyblue cursor-pointer" style="text-decoration: underline; "
                        href="https://genpactonline.sharepoint.com/sites/Legal/Compliance/Shared%20Documents/PolicyCentralold/Use%20of%20Generative%20AI%20Programs%20Policy.pdf"
                        target="_blank">Use of Generative AI Programs Policy</a>.
                    </li>

                </ol>

            </div>
        </div>
        <div class="row">
            <div class="col-12 ml-4">
                <input id="byod_check_label" type="checkbox" [(ngModel)]="chkagree" class="form-check-input" />
                <label class="form-check-label text-decoration-underline fw-bold text-primary" style="margin-left: 15px; font-size: 13px !important;" for="byod_check_label">
                    I acknowledge that I have read, understand and agree to above instructions.
                </label>
            </div>

        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-genpact text-white center cursor-pointer" [disabled]="!chkagree"
            (click)="modalRef?.hide()">
            Proceed
        </button>
    </div>
</ng-template>
