<div class="mat-dialog">
  <div class="mat-modal-header header-container">
    <h4 class="modal-title">{{data.fileNAME}}</h4>
    <button type="button" class="closebtn" aria-label="Close" (click)="closePopup()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div mat-dialog-content class="mat-dialog-content d-flex">
    <div class="preview-container w-60" >
      <iframe *ngIf="data.video == false" class="w-100" [src]="filelink | safe">
      </iframe>
      <iframe *ngIf="data.video == true" class="w-100" [src]="data.source | safe">
      </iframe>
      <!-- <ngx-doc-viewer class="w-100" [url]="filelink" viewer="google" style="width:100%;height:80vh;"></ngx-doc-viewer> -->
    </div>
    <div class="askQuestion-container w-40">
      <div class="_infoIframe_1hjup_231">
        <div>
          <div role="tablist" class="ms-FocusZone css-157 ms-Pivot root-148" data-focuszone-id="FocusZone532">
            <div class="ms-Button">
                  <span class="ms-Pivot-text text-155">Ask Questions</span>
            </div>
          </div>
          <div class="itemContainer-149">
            <div>
              <div class="_chatiframeHeight_1hjup_311">
                <div class="_fullScreenChat_nv6g8_1">
                  <div class="_webchat_nv6g8_39">
                    <div #messageContainer  class="_react_scroll_to_bottom">
                      <ng-container *ngFor="let message of messages">
                        <div class="message" [ngClass]="{ 'from': message.author === 'bot', 'to': message.author === 'user' }">
                          {{ message.content }}
                        </div>
                      </ng-container>
                      <button *ngIf="showGeneratingAnswer" class="Loading-btn" type="button" disabled>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Generating answer...
                      </button>
                    </div>
                    <div style="flex-shrink: 0;" class="footer-input">
                      <div class="ms-Stack _questionInputContainer_1o7fl_1 css-97 d-flex">
                        <div class="ms-TextField ms-TextField--multiline ms-TextField--borderless _questionInputTextArea_1o7fl_43 root-207">
                          <div class="ms-TextField-wrapper">
                            <div class="ms-TextField-fieldGroup">
                              <textarea [(ngModel)]='textField' id="TextField539" (keyup.enter)="sendMessage(textField)" (change)="changeInTextField(textField)" class="ms-TextField-field ms-TextField--unresizable field-209"
                                placeholder="Ask questions from this document" aria-invalid="false"></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="_questionInputButtonsContainer">
                          <div class="_questionInputSendButton" aria-label="Ask question button">
                              <button style="outline: none; border: none; background-color: transparent; cursor: pointer;" [disabled]="flgDisabled" (click)="sendMessage(textField)">
                              <svg fill="rgba(0, 174, 204, 1)" id="sendbuttonicon" class="___12fm75w f1w7gpdv fez10in fg4l7m0" aria-hidden="true"
                                width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.79 2.62a1.25 1.25 0 0 0-1.75 1.46l2.02 7.53a1 1 0 0 0 .82.73l9.89 1.41c.28.04.28.46 0 .5l-9.88 1.4a1 1 0 0 0-.83.74l-2.02 7.54a1.25 1.25 0 0 0 1.75 1.45l21.5-10.25c.95-.45.95-1.8 0-2.26L3.79 2.62Z"
                                  fill="rgba(0, 174, 204, 1)">
                                </path>
                              </svg>
                              </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
