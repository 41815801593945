import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MessageService } from '../shared/service/message.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { LoaderService } from '../shared/service/loader.service';
import * as tslib_1 from "tslib";
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
  @Output() fileuploaded = new EventEmitter<any>();
  @Output() filename = new EventEmitter<any>();
  @Input() placeholder = 'Only .txt, .docx, .pdf, .pptx, spreadsheets(.csv, .xls, .xlsx) and images (.jpg, .jpeg, .png) files are allowed (max Size 16 MB)';
  @Input() inputAccept = '.pdf,.PDF,.txt,.TXT,.pptx,.PPTX,.docx,.DOCX,.xlsx,.XLXS,.csv,.CSV,.xls,.XLS,.jpeg,.png,.jpg'
  @Input()  header = 'Choose Your File(s) to Upload';
  containsImages: boolean = false;
  clearFileInput(id: string) {

    let input1 = document.getElementById(id) as HTMLInputElement;
    input1.value = '';

  }
  selectedFiles?: FileList;
  currentFile?: File;

  progress = 0;
  message = '';
  isImageExits = false;
  fileInfos?: Observable<any>;
  constructor(
    private uploadService: MessageService,
     private toastMessage: ToastMessageService,
     private loaderService:LoaderService
     ) { }
  ngOnInit(): void {
  }


  uploads(event: any) {

    if (event?.target?.files) {

      if (event.target.files?.length > 0) {
        let fileSizeMB = 0;
        let unsupported_multi_files = ['.xlsx', '.XLXS', '.csv', '.CSV', '.xls', '.XLS','.jpeg', '.png', '.jpg'];

        for (const file of event?.target?.files) {
          // checking file not support in multiple file upload
          if ((event?.target?.files?.length > 1) && (unsupported_multi_files.includes(this.checkFileExtension(file)))) {
            this.toastMessage.showError("Please do not include image files and excel/csv files if you are uploading multiple files");
            return;
          }

          // checking file type
          if (!this.checkFileSupported(file)) {
            this.clearFileInput('file_upload');
            this.currentFile = undefined;
            this.toastMessage.showError('Invalid file format.');
            return;
          }

          //  clacuation file size
          fileSizeMB = fileSizeMB + (file.size / 1024 ** 2);

          if (fileSizeMB > 16) {
            this.clearFileInput('file_upload');
            this.currentFile = undefined;
            this.toastMessage.showError('File size should be less than 16 MB');
            return;
          }
        }

        console.log('file size ', fileSizeMB)
      }

      this.selectedFiles = event.target.files;

      let myfileName: any = [];
      for (const file of event?.target?.files) {
        myfileName.push(file.name);
      }

      if (myfileName?.length > 1) {
        localStorage.setItem('fileName', 'multi');
      } else {
        localStorage.setItem('fileName', myfileName[0]);
      }

      // localStorage.setItem('fileName', myfileName.join(' , '));
      this.filename.emit(myfileName.join(' , '));
      this.progress = 0;

      if (this.selectedFiles) {
        const file: File | null = this.selectedFiles.item(0);

        if (file) {
          this.currentFile = file;
          this.loaderService.loading.next(true);

          this.uploadService.uploadBYOD(this.selectedFiles, 'uploader', this.isImageExits).subscribe({
            next: (event: any) => {
              if (event.type === HttpEventType.UploadProgress) {
                this.progress = Math.round((100 * event.loaded) / event.total);
              } else if (event instanceof HttpResponse) {
                this.loaderService.loading.next(false);
                if (event.body.response?.status == "success" || event.body?.status == "success") {
                  if(event.body.response?.message == "Redirected" || event.body?.message == "Redirected"){
                    localStorage.setItem('Azure', 'yes');
                  }
                  if(event.body.response?.message !== "Redirected" || event.body?.message !== "Redirected"){
                    localStorage.setItem('Azure', 'no');
                  }

                  if(event.body.response?.gcp_status || event.body?.gcp_status){
                    this.fileuploaded.emit({gcp_status:"true", filename:event.body?.filename});
                  }else{
                    this.fileuploaded.emit({gcp_status:"false"});
                  }

                  this.toastMessage.showSuccess(event.body.response?.message);
                } else {
                  this.fileuploaded.emit(false);
                  this.toastMessage.showError(event.body.response?.message);
                }

                this.clearFileInput('file_upload');
                this.currentFile = undefined;
              }
            },
            error: (err: any) => {
              this.loaderService.loading.next(false);
              this.fileuploaded.emit(false);
              this.clearFileInput('file_upload');
              this.currentFile = undefined;
              console.log(err);
              this.progress = 0;

              if (err?.error?.message ) {
                this.toastMessage.showError(err.error.message);
              } else {
                this.toastMessage.showError('Could not upload the file!');
              }
              this.currentFile = undefined;
            },
          });
        }
        this.selectedFiles = undefined;
      }
    }
  }


  checkFileExtension(file: any) {
    // checking file type
    let patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    let fileExtension = (file.name).match(patternFileExtension);
    return '.' + fileExtension[1];
  }

  checkFileSupported(file: any) {
    return this.inputAccept.split(',').includes(this.checkFileExtension(file));
  }

}
