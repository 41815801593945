import { Component, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { LoaderService } from '../shared/service/loader.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { TranslateService } from '../shared/service/translate.service';
import { UserApiLimitService } from '../shared/service/user-api-limit.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-video-indexer',
  templateUrl: './video-indexer.component.html',
  styleUrls: ['./video-indexer.component.scss']
})
export class VideoIndexerComponent implements OnInit, OnDestroy {

  downloadStatus = false;
  languageArray: any = [];
  processStatus: boolean = false;

  convertTo: any = [{ name: "English (India)", value: "en-IN" }];
  convertFrom: any = [{ name: "English (India)", value: "en-IN" }];
  targetSASUrl: any;
  targetSASUrl1: any;

  fileUploadedStatus = false;
  fileData: any;
  userOHR: any;
  diarization:boolean = false;
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,
    idField: 'value',
    textField: 'name',
    itemsShowLimit: 3,
    maxHeight: 160,
    allowSearchFilter: true
  };

  intervalId: any; // Variable to store the interval ID
  endPointDetail: any;
  chkagree: boolean = false;
  modalRef?: BsModalRef;

  constructor(
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private toastMessage: ToastMessageService,
    private modalService: BsModalService,

    private userApiLimitService: UserApiLimitService
  ) {
    // this.getTranslateLanguage();
    this.getUserLimt();
    this.languageArray = [
      // { name: 'Auto', value: 'auto' },

      { name: "Arabic (Saudi Arabia)", value: "ar-SA" },
      { name: "Bulgarian", value: "bg-BG" },
      { name: "Catalan", value: "ca-ES" },
      { name: "Czech", value: "cs-CZ" },
      { name: "Danish", value: "da-DK" },
      { name: "German", value: "de-DE" },
      { name: "Greek", value: "el-GR" },
     // { name: "English (United States)", value: "en-US" },
      { name: "Spanish", value: "es-ES" },
      { name: "Estonian", value: "et-EE" },
      { name: "Persian", value: "fa-IR" },
      { name: "Finnish", value: "fi-FI" },
      { name: "French", value: "fr-FR" },
      { name: "Irish", value: "ga-IE" },
      { name: "Gujarati", value: "gu-IN" },
      { name: "Hebrew", value: "he-IL" },
      { name: "Hindi", value: "hi-IN" },
      { name: "Croatian", value: "hr-HR" },
      { name: "Hungarian", value: "hu-HU" },
      { name: "Armenian", value: "hy-AM" },
      { name: "Indonesian", value: "id-ID" },
      { name: "Icelandic", value: "is-IS" },
      { name: "Italian", value: "it-IT" },
      { name: "Japanese", value: "ja-JP" },
      { name: "Kannada", value: "kn-IN" },
      { name: "Korean", value: "ko-KR" },
      { name: "Lithuanian", value: "lt-LT" },
      { name: "Chinese (Simplified)", value: "zh-Hans" },
      { name: "Turkish", value: "tr-TR" },
      { name: "Thai", value: "th-TH" },
      { name: "Telugu", value: "te-IN" },
      { name: "Tamil", value: "ta-IN" },
      { name: "Swedish", value: "sv-SE" },
      { name: "Slovak", value: "sk-SK" },
      { name: "Russian", value: "ru-RU" },
      { name: "Romanian", value: "ro-RO" },
      { name: "Dutch", value: "nl-NL" },
      { name: "Norwegian", value: "nb-NO" },
      { name: "Malayalam", value: "ml-IN" },
      { name: "Portuguese", value: "pt-BR" },

      // New entries
      { name: "Arabic (United Arab Emirates)", value: "ar-AE" },
      { name: "Arabic (Bahrain)", value: "ar-BH" },
      { name: "Arabic (Algeria)", value: "ar-DZ" },
      { name: "Arabic (Egypt)", value: "ar-EG" },
      { name: "Arabic (Iraq)", value: "ar-IQ" },
      { name: "Arabic (Jordan)", value: "ar-JO" },
      { name: "Arabic (Kuwait)", value: "ar-KW" },
      { name: "Arabic (Libya)", value: "ar-LY" },
      { name: "Arabic (Morocco)", value: "ar-MA" },
      { name: "Arabic (Oman)", value: "ar-OM" },
      { name: "Arabic (Qatar)", value: "ar-QA" },
      { name: "Arabic (Syria)", value: "ar-SY" },
      { name: "Arabic (Yemen)", value: "ar-YE" },
      { name: "Bengali (India)", value: "bn-IN" },
      { name: "Chinese (Traditional, Hong Kong)", value: "zh-HK" },
      { name: "Chinese (Traditional, Taiwan)", value: "zh-TW" },
      { name: "English (Australia)", value: "en-AU" },
      { name: "English (Canada)", value: "en-CA" },
      { name: "English (Ghana)", value: "en-GH" },
      { name: "English (Hong Kong)", value: "en-HK" },
      { name: "English (India)", value: "en-IN" },
      { name: "English (Ireland)", value: "en-IE" },
      { name: "English (Kenya)", value: "en-KE" },
      { name: "English (New Zealand)", value: "en-NZ" },
      { name: "English (Nigeria)", value: "en-NG" },
      { name: "English (Philippines)", value: "en-PH" },
      { name: "English (Singapore)", value: "en-SG" },
      { name: "English (South Africa)", value: "en-ZA" },
      { name: "English (Tanzania)", value: "en-TZ" },
      { name: "French (Canada)", value: "fr-CA" },
      { name: "Latvian (Latvia)", value: "lv-LV" },
      { name: "Maltese (Malta)", value: "mt-MT" },
      { name: "Marathi (India)", value: "mr-IN" },
      { name: "Portuguese (Portugal)", value: "pt-PT" },
      { name: "Spanish (Argentina)", value: "es-AR" },
      { name: "Spanish (Bolivia)", value: "es-BO" },
      { name: "Spanish (Chile)", value: "es-CL" },
      { name: "Spanish (Colombia)", value: "es-CO" },
      { name: "Spanish (Costa Rica)", value: "es-CR" },
      { name: "Spanish (Cuba)", value: "es-CU" },
      { name: "Spanish (Dominican Republic)", value: "es-DO" },
      { name: "Spanish (Ecuador)", value: "es-EC" },
      { name: "Spanish (El Salvador)", value: "es-SV" },
      { name: "Spanish (Equatorial Guinea)", value: "es-GQ" },
      { name: "Spanish (Guatemala)", value: "es-GT" },
      { name: "Spanish (Honduras)", value: "es-HN" },
      { name: "Spanish (Mexico)", value: "es-MX" },
      { name: "Spanish (Nicaragua)", value: "es-NI" },
      { name: "Spanish (Panama)", value: "es-PA" },
      { name: "Spanish (Paraguay)", value: "es-PY" },
      { name: "Spanish (Peru)", value: "es-PE" },
      { name: "Spanish (Puerto Rico)", value: "es-PR" },
      { name: "Spanish (Uruguay)", value: "es-UY" },
      { name: "Spanish (United States)", value: "es-US" },
      { name: "Spanish (Venezuela)", value: "es-VE" },
      { name: "Ukrainian (Ukraine)", value: "uk-UA" },
      { name: "Vietnamese (Vietnam)", value: "vi-VN" }
    ];



    // this.languageArray = [
    //   { name: 'Auto', value: 'auto' },
    //   { name: 'Multi', value: 'multi' },
    //   { name: "Arabic (Saudi Arabia)", value: "ar-SA" },
    //   { name: "Bulgarian", value: "bg-BG" },
    //   { name: "Catalan", value: "ca-ES" },
    //   { name: "Czech", value: "cs-CZ" },
    //   { name: "Danish", value: "da-DK" },
    //   { name: "German", value: "de-DE" },
    //   { name: "Greek", value: "el-GR" },
    //   { name: "English United States", value: "en-US" },
    //   { name: "Spanish", value: "es-ES" },
    //   { name: "Estonian", value: "et-EE" },
    //   { name: "Persian", value: "fa-IR" },
    //   { name: "Finnish", value: "fi-FI" },
    //   { name: "French", value: "fr-FR" },
    //   { name: "Irish", value: "ga-IE" },
    //   { name: "Gujarati", value: "gu-IN" },
    //   { name: "Hebrew", value: "he-IL" },
    //   { name: "Hindi", value: "hi-IN" },
    //   { name: "Croatian", value: "hr-HR" },
    //   { name: "Hungarian", value: "hu-HU" },
    //   { name: "Armenian", value: "hy-AM" },
    //   { name: "Indonesian", value: "id-ID" },
    //   { name: "Icelandic", value: "is-IS" },
    //   { name: "Italian", value: "it-IT" },
    //   { name: "Japanese", value: "ja-JP" },
    //   { name: "Kannada", value: "kn-IN" },
    //   { name: "Korean", value: "ko-KR" },
    //   { name: "Lithuanian", value: "lt-LT" },
    //   { name: "Chinese (Simplified)", value: "zh-Hans" },
    //   { name: "Turkish", value: "tr-TR" },
    //   { name: "Thai", value: "th-TH" },
    //   { name: "Telugu", value: "te-IN" },
    //   { name: "Tamil", value: "ta-IN" },
    //   { name: "Tamil", value: "ta-IN" },
    //   { name: "Swedish", value: "sv-SE" },
    //   { name: "Slovak", value: "sk-SK" },
    //   { name: "Russian", value: "ru-RU" },
    //   { name: "Romanian", value: "ro-RO" },
    //   { name: "Dutch", value: "nl-NL" },
    //   { name: "Norwegian", value: "nb-NO" },
    //   { name: "Malayalam", value: "ml-IN" },
    //   { name: "Portuguese", value: "pt-BR" },
    // ];
  }

  ngOnInit() {
    this.startInterval();
    this.onLoading();

  }
  onLoading() {
    const modalbtn = document.getElementById('byod_policy_model') as HTMLLIElement;
    modalbtn.click();
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md',ignoreBackdropClick: true });
  }
  //get max count and count left of api
  getUserLimt() {
    this.userApiLimitService.getUserApiCount("video_indexer").subscribe((res: any) => {
      if (res?.response?.status == 'success') {
        this.endPointDetail = res?.response?.data ? res?.response?.data[0] : null;
      }
    })
  }

  // Get all translation langularge
  getTranslateLanguage() {
    this.translateService.translateLanguage().subscribe((res: any) => {
      if (res?.translation) {
        const transformedResponse = Object.keys(res?.translation).map(key => ({
          value: key,
          name: res?.translation[key].name
        }));
        this.languageArray = transformedResponse || [];
      }
    })
  }

  // Upload file for translation
  postTranslateUpload(event: any) {
    this.loaderService.loading.next(true);

    if (event?.name) {
      this.fileData = event;
    }
    this.getOHR();



    const formData = new FormData();
    formData.append('file', this.fileData?.file);
    let selectedValues = this.convertTo.map((lang: any) => lang?.value); // Added parentheses around (lang: any)

    // formData.append('source_language', JSON.stringify(selectedValues));
   formData.append('source_language', this.convertFrom[0]?.value);
formData.append('diarization', this.diarization.toString());
    this.translateService.videoUpload(formData)
      .subscribe(
        (res: any) => {
          this.loaderService.loading.next(false);
          this.getUserLimt();

          if (res?.status == 'success') {
            // this.fileUploadedStatus = true;

            //set to localStorage
            let translateData = {
              file_name: res?.blob_name,
              video_id: res?.video_id
            };
            localStorage.setItem('f_indexer', JSON.stringify(translateData));
            this.startInterval();

            this.toastMessage.showSuccess(res?.message);
          } else {
            this.toastMessage.showError(res?.message);
          }

        },
        (error) => {
          // Handle the error here
          this.stopInterval();
          this.loaderService.loading.next(false);
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );
  }

  // translate file  after upload
  translateContent() {
    var translateData = null;
    if (localStorage.getItem('f_indexer')) {
      this.loaderService.loading.next(true);

      translateData = JSON.parse(localStorage.getItem('f_indexer') || '');

      this.loaderService.loading.next(true);
      this.getOHR();

      let formData = {
        "video_id": translateData?.video_id,
        "file_name": this.fileData?.name,
        // "target_language": this.convertTo[0]?.value
        'diarization': this.diarization.toString(),
      };
      // formData.append('diarization', this.diarization.toString());

      this.translateService.getTranscript(formData)
        .subscribe(
          (res: any) => {
            this.loaderService.loading.next(false);

            if (res?.status) {
              this.targetSASUrl = res?.transcript;
              // this.fileUploadedStatus = false;
             if(this.convertFrom[0]?.value == this.convertTo[0]?.value){
              this.downloadFile();
            }
            else{
              this.postAzureTranslateUpload();
            }

              this.toastMessage.showSuccess(res?.message);
            } else {
              this.toastMessage.showError(res?.message);
            }

          },
          (error) => {
            // Handle the error here
            this.loaderService.loading.next(false);
            console.error(error);
            this.toastMessage.showError(error?.message);
          }
        );
    }
  }

  // translateContentforazure() {
  //   var translateData = null;
  //   if (localStorage.getItem('f_indexer')) {
  //     this.loaderService.loading.next(true);

  //     translateData = JSON.parse(localStorage.getItem('f_indexer') || '');

  //     this.loaderService.loading.next(true);
  //     this.getOHR();

  //     let formData = {
  //       "video_id": translateData?.video_id,
  //       "file_name": this.fileData?.name,
  //       // "target_language": this.convertTo[0]?.value
  //       'diarization': this.diarization.toString(),
  //     };
  //     // formData.append('diarization', this.diarization.toString());

  //     this.translateService.getTranscript(formData)
  //       .subscribe(
  //         (res: any) => {
  //           this.loaderService.loading.next(false);

  //           if (res?.status) {
  //             this.targetSASUrl = res?.transcript;
  //             // this.fileUploadedStatus = false;
  //             this.postAzureTranslateUpload();
  //             this.toastMessage.showSuccess(res?.message);
  //           } else {
  //             this.toastMessage.showError(res?.message);
  //           }

  //         },
  //         (error) => {
  //           // Handle the error here
  //           this.loaderService.loading.next(false);
  //           console.error(error);
  //           this.toastMessage.showError(error?.message);
  //         }
  //       );
  //   }
  // }

  // check translation status

  getTranslateStatus() {
    let translateData = null;
    this.processStatus = true;
    if (localStorage.getItem('f_indexer')) {
      this.loaderService.loading.next(true);

      translateData = JSON.parse(localStorage.getItem('f_indexer') || '');

      this.fileData = { name: translateData?.file_name };

      const formData = { 'video_id': translateData?.video_id };

      this.translateService.videoStatus(formData)
        .subscribe(
          (res: any) => {
            if (res?.status == 'Processed') {
              this.stopInterval();
              this.loaderService.loading.next(false);
              // this.fileUploadedStatus = true;
              this.downloadStatus = true;
              this.toastMessage.showSuccess(res?.message);
            }

            else if(res?.status == 'Failed') {
              this.stopInterval();
              this.resetFile();
              this.loaderService.loading.next(false);
              this.toastMessage.showError(res?.message);
            }


            else {
              if (res?.status == 'Processing') {
                this.toastMessage.showWarning(res?.message);
              }

              else {
                this.stopInterval();
                this.resetFile();
                this.loaderService.loading.next(false);
                this.toastMessage.showError(res?.message);
              }

            }

          },
          (error) => {
            // Handle the error here
            this.stopInterval();
            this.loaderService.loading.next(false);
            console.error(error);
            this.toastMessage.showError(error?.message);
          }
        );
    }
  }


  // Download translated file
  downloadFile() {
    this.loaderService.loading.next(true);

    const reader = new FileReader();

    // Convert the blob to text

    const textData = this.targetSASUrl;

    // Create a Blob containing the text data
    const textBlob = new Blob([textData], { type: 'text/plain' });

    // Create a download link and trigger the download
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(textBlob);
    downloadLink.download = 'converted.txt';
    downloadLink.style.display = 'none';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    setTimeout(() => {
      this.loaderService.loading.next(false);
      this.toastMessage.showSuccess('Downloading File.');
      this.resetFile();
    }, 2000);

  }

  // reset upload file

  resetFile() {
    this.fileUploadedStatus = false;
    this.fileData = '';
    this.downloadStatus = false;
    this.targetSASUrl = '';
    localStorage.removeItem('f_indexer');
  }


  // get user OHR id
  getOHR() {
    let userData = localStorage.getItem('okta-token-storage');
    if (userData) {
      let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
      if (ohr_id) {
        this.userOHR = ohr_id;
      }
    }
  }

  // checking status of file per INterver till successed

  startInterval(): void {
    if (localStorage.getItem('f_indexer')) {
      this.getTranslateStatus();

      this.intervalId = setInterval(() => {
        // Your function to be called every 15 seconds
        this.getTranslateStatus();

      }, 15000); // 15 seconds in milliseconds

    }
  }

  stopInterval() {
    this.processStatus = false;
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  resetForm(event: any) {
    localStorage.removeItem('f_indexer');

    this.stopInterval();
    this.loaderService.loading.next(false);
  }


  ngOnDestroy(): void {
    // Clear the interval when the component is destroyed
    this.stopInterval();
  }


  postAzureTranslateUpload() {
    this.loaderService.loading.next(true);


    this.getOHR();


    const formData = new FormData();

    //formData.append('filename', this.fileData?.name);

 //if file is an extension of image
 const blob = new Blob([this.targetSASUrl], { type: 'text/plain' });
  const file = new File([blob], 'converted.txt', { type: 'text/plain' });




  formData.append('user_id', this.userOHR);
  formData.append('glossary_status', 'false');
  formData.append('file', file);

    this.translateService.translateUpload(formData)
      .subscribe(
        (res: any) => {
          this.loaderService.loading.next(false);
          this.getUserLimt();

         if( res?.status == 'Success'){
          this.toastMessage.showSuccess(res?.message);
            //if glossary status is true then upload glossary file
            this.translateAzureContent(file);



         }else{
          this.toastMessage.showError(res?.message);
         }

        },
        (error) => {
          // Handle the error here
          this.loaderService.loading.next(false);
          console.error(error);
          this.toastMessage.showError(error?.message);
        }
      );
  }


  translateAzureContent(file: any) {


        this.loaderService.loading.next(true);
        this.getOHR();

        // Adding the data to the tableData array
        // this.tableData.push({ currentText: this.currentText, translatedText: this.translatedText });

        // const json = JSON.stringify(this.tableData);

        const formData = new FormData();

          formData.append('filename', file.name);


        formData.append('glossary_filename', 'glossary.csv');
        formData.append('user_id', this.userOHR);
        formData.append('source_lang', this.convertFrom[0]?.value);
        formData.append('target_lang', this.convertTo[0]?.value);
        formData.append('glossary_status', 'false');

        //formData.append('file', new Blob([json], { type: 'application/json' }));



        this.translateService.translateDownload(formData)
          .subscribe(
            (res: any) => {
              this.loaderService.loading.next(false);

              if( res?.status == 'Success' && res?.data?.target_blob_url){
                this.targetSASUrl1 = res?.data?.target_blob_url || '';
                // this.downloadStatus = true;
                this.toastMessage.showSuccess(res?.message);

                //set to localStorage
                let translateData = res?.data;
                translateData['file_name'] = this.fileData?.name;
                localStorage.setItem('f_tarnslate',JSON.stringify(translateData));




                this.startInterval1(file);

              }else{
                this.toastMessage.showError(res?.message);
              }

            },
            (error) => {
              // Handle the error here
              this.loaderService.loading.next(false);
              console.error(error);
              this.toastMessage.showError(error?.message);
            }
          );
      }



      // check translation status

      getTranslateAzureStatus(file: any) {
        let translateData = null;
        this.processStatus = true;
        if(localStorage.getItem('f_tarnslate')){
        this.loaderService.loading.next(true);

         translateData = JSON.parse( localStorage.getItem('f_tarnslate') || '');

         this.fileData = {name:translateData?.file_name};
         this.targetSASUrl = translateData?.target_blob_url || '';


        const formData = new FormData();
        formData.append('translation_id', translateData?.translation_id);


        this.translateService.translateStatus(formData)
          .subscribe(
            (res: any) => {
              if( res?.status == 'Succeeded'){

                this.stopInterval1();
                this.loaderService.loading.next(false);
                this.downloadStatus = true;
                this.toastMessage.showSuccess(res?.message);
                this.downloadAzureFile(file.name);

              }else{
                if(res?.status == 'NotStarted' || res?.status == 'Running'){
                  this.toastMessage.showWarning(res?.message);

                  // do nothing
                }else{
                  this.stopInterval1();
                  this.resetFile();
                  this.loaderService.loading.next(false);
                  this.toastMessage.showError(res?.message);
                }
              }

            },
            (error) => {
              // Handle the error here
              this.stopInterval1();

              this.loaderService.loading.next(false);
              console.error(error);
              this.toastMessage.showError(error?.message);
            }
          );


        }
      }

      startInterval1(file:any): void {
        if(localStorage.getItem('f_tarnslate')){
        this.getTranslateAzureStatus(file);

        this.intervalId = setInterval(() => {
          // Your function to be called every 15 seconds
          console.log('interval call')
          this.getTranslateAzureStatus(file);

        }, 15000); // 15 seconds in milliseconds

      }
    }

      stopInterval1() {
        this.processStatus = false;
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
      }


      downloadAzureFile(fileName: any) {
        this.loaderService.loading.next(true);
         // Determine the file extension based on the content type
         const urlParts = this.targetSASUrl.substring(this.targetSASUrl.indexOf('/', 1) + 1, this.targetSASUrl.lastIndexOf('.'));
         //       const filename = urlParts[urlParts.length - 1];

              //  let filename = urlParts;

               const start = this.targetSASUrl.indexOf("playground-translate-out/") + "playground-translate-out/".length;
               const end = this.targetSASUrl.indexOf("?", start);
               const extractedPartWithExtension = this.targetSASUrl.substring(start, end);

               // Extract only the name part without the extension
               let filename = extractedPartWithExtension.split("/").pop().split(".")[0];


      const fname =fileName;

        const payload = {
          'url': this.targetSASUrl,
          'filename':this.userOHR+fname,
        };

        // if(this.ImageStatus)
        // {

        //   payload.filename= this.userOHR + '.' + 'pdf'
        // }



        this.translateService.translatefileDownload(payload, { responseType: 'blob' }).subscribe(
          (response: Blob) => {
            const contentType = response.type || 'application/octet-stream';


            if (contentType.includes('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')) {
              filename += '.xlsx';
            } else if (contentType.includes('application/pdf')) {
              filename += '.pdf';
            } else if (contentType.includes('application/msword')) {
              filename += '.doc';
            } else if (contentType.includes('application/vnd.openxmlformats-officedocument.wordprocessingml.document')) {
              filename += '.docx';
            } else if (contentType.includes('text/plain')) {
              filename += '.txt';
            } else if (contentType.includes('image/png')) {
              filename += '.png';
            } // Add more conditions for other file types

            this.toastMessage.showmessage('Downloading file...');

            // Trigger file download
            saveAs(response, filename);

            // Hide loader and show success message
            this.loaderService.loading.next(false);
            this.toastMessage.showSuccess('File downloaded successfully');
            this.resetFile();

      //
      setTimeout(() => {
        this.translatedelete(filename);

      }, 2000);
          },
          (error: any) => {
            this.loaderService.loading.next(false);
            this.resetFile();
            console.error('Error downloading file:', error);
            this.toastMessage.showError('Error downloading file');
          }
        );
      }
      translatedelete(filename: any) {
        this.getOHR();
        this.loaderService.loading.next(true);
        // const payload = new FormData();
        // payload.append('filename', this.userOHR+filename);
        const fname = filename;

        this.translateService.translatedelete( fname,this.userOHR).subscribe(
          (response: any) => {
            this.loaderService.loading.next(false);
          //  this.toastMessage.showSuccess('File Deleted Successfully');
            console.log(response?.response);    },
          (error: any) => {
            this.loaderService.loading.next(false);
            console.error('Error deleting file:', error);
           // this.toastMessage.showError('Error deleting file');
          }
        );
      }








}
