<div class="wrapper">
  <app-sidenav [item]="'playground'"></app-sidenav>
  <!-- Main content -->
  <section class="content">
    <div class="container-fluid responsive" style="overflow: hidden;">
      <div class="container">

        <div class="row flex-nowrap">
          <main id="main">
            <div class="row mt-5 mb-4">
              <div class="col-12" style="text-align: center;">
                <h4>
                  Transcribe your own audio/video
                </h4>
                <div *ngIf="endPointDetail?.value" class="">
                  Count Left: <b>{{ endPointDetail?.max - endPointDetail?.value}}</b>
                </div>
              </div>
            </div>

            <div class="row py-4">
              <div class="col-md-12">
                <div class="selectlang" align="center">
                  <ng-container *ngIf="!downloadStatus">
                    From:
                    <ng-multiselect-dropdown [placeholder]="'Select'" [settings]="dropdownSettings"
                      [data]="languageArray" [(ngModel)]="convertFrom" title="">
                    </ng-multiselect-dropdown>
                     To:
                     <ng-multiselect-dropdown [placeholder]="'Select'" [settings]="dropdownSettings"
                      [data]="languageArray" [(ngModel)]="convertTo" title="">
                      </ng-multiselect-dropdown>
                  </ng-container>
                  <button *ngIf="downloadStatus" class="bg-success text-light p-2 px-4 border border-r1"
                    (click)="translateContent()">
                    Download Transcript
                  </button>
                  <!-- <button *ngIf="downloadStatus" class="bg-success text-light p-2 px-4 border border-r1"
                    (click)="translateContentforazure()">
                    Get Translated Transcript

                  </button> -->
                </div>
              </div>
            </div>
            <div class="row px-md-5 " *ngIf="fileUploadedStatus || downloadStatus">
              <div class="col-12 file-list">
                File Name:
                <h6 style="display: contents; text-decoration: underline;">
                  {{fileData?.name}}
                  <span (click)="resetFile()">
                    <i class="fa fa-close" style="color:red;"></i>
                  </span>
                </h6>
              </div>
            </div>
            <div class="row">
              <app-upload-file placeholder="Only .mp3 , .wav and .mp4 file are allowed (max Size 50 MB)"
                inputAccept=".mp3, .mp4, .m4a, .m4v ,.wav" (fileuploaded)="postTranslateUpload($event)" maxFileSize=50>
              </app-upload-file>
              <div class="col-md-12 d-flex" style="flex-wrap: wrap;
              align-items: flex-start;
              justify-content: center;">

                <label class="checkbox-label checkboxx">
                  <input type="checkbox" id="uploadCheckbox" [(ngModel)]="diarization" required
                    [ngModelOptions]="{standalone: true}" style="margin-right: 5px;margin-top:5px">


                </label> Include Speech Diarization
                <i class="bi bi-info-circle" tooltip="Diarization is the process of separating speakers in audio data." > </i>
    </div>
            </div>
            <!-- checkbox -->
            <div class="row mt-4">

</div>

            <!-- <div class="row px-md-5 " *ngIf="fileUploadedStatus || downloadStatus">
              <div class="col-12 file-list">
                File Name:
                <h6 style="display: contents; text-decoration: underline;">
                  {{fileData?.name}}
                  <span (click)="resetFile()">
                    <i class="fa fa-close" style="color:red;"></i>
                  </span>
                </h6>
              </div>
            </div> -->

          </main>
        </div>
      </div>
    </div>
  </section>

  <app-stop-process *ngIf="processStatus" (stopProgress)="resetForm($event)"></app-stop-process>


<!-- policy & condition toggle -->
<button type="button" class="btn btn-primary d-none" id="byod_policy_model"  (click)="openModal(byod_policy_model)">
</button>

<!-- policy & condition block -->
<ng-template #byod_policy_model>
    <div class="modal-body">

        <div class="row">
            <div class="col-12  fw-bold text-primary">In order to adhere to the Legal and Security policies,
                please take care of below mentioned points:</div>
        </div>
        <div class="row">
            <div class="col-12" style="font-size: 13px;">
                <ol style="list-style:decimal;">
                    <!-- <li>
                        SOPs/Documents/Images should be hosted on Genpact network.
                    </li> -->
                    <li>
                        Only documents hosted on the Genpact network and that Genpact has contractual
                        permission to maintain on the Genpact network may be uploaded here.
                    </li>
                    <!-- change here-->
                    <li>
                        This application is for Genpact internal employees usage only.
                    </li>

                </ol>

            </div>
        </div>
        <div class="row">
            <div class="col-12 ml-4">
                <input id="byod_check_label" type="checkbox" [(ngModel)]="chkagree" class="form-check-input" />
                <label class="form-check-label text-decoration-underline fw-bold text-primary" style="margin-left: 15px;" for="byod_check_label">
                    I acknowledge that I have read, understand and agree to above instructions.
                </label>
            </div>

        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-genpact text-white center cursor-pointer" [disabled]="!chkagree"
            (click)="modalRef?.hide()">
            Proceed
        </button>
    </div>
</ng-template>
