import { OktaAuth } from '@okta/okta-auth-js';
import { Component, Inject } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent {
  constructor(@Inject(OKTA_AUTH) public oktaAuth: OktaAuth) { 
    this.login();
  }
  async login() {
    await this.oktaAuth.signInWithRedirect();
  }
}
