import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { BedrockService } from '../shared/service/bedrock.service';
import { LoaderService } from '../shared/service/loader.service';
import { ToastMessageService } from '../shared/service/toast-message.service';

@Component({
  selector: 'app-promptgenerator',
  templateUrl: './promptgenerator.component.html',
  styleUrls: ['./promptgenerator.component.scss']
})
export class PromptgeneratorComponent implements OnInit, OnDestroy {
  chatSubscriber!: Subscription;
  chatPayload: any;
  chatData: any = [];
  resetChatStatus: boolean = false;
  prompt:string ='';
  showchat:boolean = false;
  relatedQuestionList: string[] = [
    "1.Create an image of superman.",
    "2.Describe a futuristic city.",
    "3.Write a story about a dragon.",
    "4.Explain quantum computing in simple terms.",
  ];
  constructor(
    private bedrockService: BedrockService,
    private toastMessage: ToastMessageService,
    private loaderService: LoaderService
  ) {
    this.chatPayload = {
      name: 'Prompt Generator',
      description: "Welcome to the Prompt Generator!. How can I assist you today?"
    }
  }

  ngOnInit(): void {

    
   }

  getResponse() {
    this.showchat = true;
    let prompt = this.chatData[this.chatData?.length - 1];

    if(this.prompt){
      prompt = {content:this.prompt};
      this.chatData.push({role: 'user', content: this.prompt});
    }

    this.loaderService.loading.next(true);
    this.resetChatStatus = false;

    this.bedrockService.getprompt(prompt?.content).subscribe(
      (res: any) => {
        this.loaderService.loading.next(false);
        console.log(res);
     this.chatData.push({role: 'assistant', content: res.data});
        // Parse the JSON string to an object
        // let responseObj;
        // try {
        //   responseObj = JSON.parse(res);
        // } catch (error) {
        //   console.error('Failed to parse response:', error);
        //   this.toastMessage.showError('Failed to parse response');
        //   this.resetChatStatus = true;
        //   return;
        // }

        // // Extract and format the content
        // if (responseObj && responseObj.data) {
        //   let formattedContent = this.formatResponse(responseObj.data);
        //   if (formattedContent) {
        //     let resultResponse = { "role": "assistant", "content": formattedContent };
        //     this.chatData.push(resultResponse);
        //   } else {
        //     let resultResponse = { "role": "assistant", "content": 'Unable to parse response' };
        //     this.chatData.push(resultResponse);
        //   }
        // } else {
        //   let resultResponse = { "role": "assistant", "content": 'Invalid response format' };
        //   this.chatData.push(resultResponse);
        // }

        this.resetChatStatus = true;
        this.prompt = '';
      },
      (error: any) => {
        this.loaderService.loading.next(false);
        this.toastMessage.showError('Something went wrong');
        this.resetChatStatus = true;
      }
    );
  }

  formatResponse(response: string): string {
    // Clean up the response to handle escape characters
    const cleanedResponse = response.replace(/\\n/g, '\n').replace(/\\\\/g, '\\');

    // Check if the response contains table-like structure
    const tableRegex = /<p>\s*\|\s*(.*?)\s*\|\s*<\/p>/g;
    if (tableRegex.test(cleanedResponse)) {
      // Format as HTML table
      let tableHtml = '<table>';
      let match;
      while ((match = tableRegex.exec(cleanedResponse)) !== null) {
        const rowContent = match[1].trim();
        const cells = rowContent.split(/\s*\|\s*/).filter(cell => cell !== '');
        tableHtml += '<tr>';
        cells.forEach(cell => {
          tableHtml += `<td>${cell}</td>`;
        });
        tableHtml += '</tr>';
      }
      tableHtml += '</table>';
      return tableHtml;
    } else {
      // Return plain text content if no table-like structure found
      return `<p>${cleanedResponse}</p>`;
    }
  }






  escapeForMarkdown(data: string): string {
    return data.replace(/\\/g, '\\\\');
  }

  ngOnDestroy(): void {

  }

}

