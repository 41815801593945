import { Component } from '@angular/core';
import { AiQuizComponent } from './ai-quiz/ai-quiz.component';
import { AvatarDisplayComponent } from './avatar-display/avatar-display.component';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { ImageFloaterComponent } from './image-floater/image-floater.component';


@Component({
  selector: 'app-digital-avatar',
  templateUrl: './digital-avatar.component.html',
  standalone:true,
  imports:[ImageUploadComponent,AvatarDisplayComponent,ImageFloaterComponent,AiQuizComponent],
  styleUrls: ['./digital-avatar.component.scss']
})
export class DigitalAvatarComponent {
  generatedAvatarUrl: string = ''; // This will hold the URL from ImageUploadComponent
  successMessage: string = 'Image has been successfully generated'; // Success message
  title = 'digital-avatar-generator';

  // Function to handle the emitted avatar URL from ImageUploadComponent
  handleAvatarGenerated(avatarUrl: string) {
    this.generatedAvatarUrl = avatarUrl; // Update the avatar URL
  }

}
