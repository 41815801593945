import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  standalone:true,
  styleUrls: ['./result.component.scss']
})
export class ResultComponent {

  @Input() finalResponse: string = '';

  restartQuiz(): void {
    window.location.reload();  // Simple refresh to restart quiz, you can make this more graceful
  }
}
