import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GcpService {
  endpoint = 'https://gapi1.playground-uat.genpact.com';
  endpoint1 = environment.apiURL;
  endpoint2 = '';

  constructor(private http: HttpClient, private router: Router) { }

  sendMessage(payload: any): Observable<any> {
    return new Observable(observer => {
      // not a good practice, but for the demo purposes - change to prod url
      // const endpoint = 'http://127.0.0.1:5000';
      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${this.endpoint}/chat`, true);
      xhr.setRequestHeader('authorization', 'Bearer '+ localStorage.getItem('token'));
      xhr.setRequestHeader('Content-Type', 'application/json');
      // xhr.setRequestHeader('auth-platform', 'teams');
      
      let buffer = '';
      let lastProcessedIndex = 0;
  
      xhr.onprogress = () => {
        const newData = xhr.responseText.slice(lastProcessedIndex);
        buffer += newData;
        lastProcessedIndex = xhr.responseText.length;
  
        let newlineIndex;
        while ((newlineIndex = buffer.indexOf('\n')) !== -1) {
          const chunk = buffer.slice(0, newlineIndex).trim();
          buffer = buffer.slice(newlineIndex + 1);
  
          if (chunk) {
            try {
              const data = JSON.parse(chunk);
              observer.next(data);
            } catch (error) {
              console.error('Error parsing chunk:', chunk, error);
            }
          }
        }
      };
  
      xhr.onload = () => {
        if (xhr.status === 200) {
          // Process any remaining data in the buffer
          if (buffer.trim()) {
            try {
              const data = JSON.parse(buffer.trim());
              observer.next(data);
            } catch (error) {
              console.error('Error parsing final chunk:', buffer, error);
            }
          }
          observer.complete();
        } else {
          observer.error(new Error(`HTTP error! status: ${xhr.status}`));
        }
      };
  
      xhr.onerror = () => {
        observer.error(new Error('XHR error'));
      };
  
      xhr.send(JSON.stringify(payload));
  
      return () => {
        xhr.abort();
      };
    });
  }
  

  uploadFile(payload: any) {
    return this.http.post(`${this.endpoint}upload`, payload);
  }
}
