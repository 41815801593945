import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LeaderboardService {
  endpoint = environment.nodeEndpoint;

  constructor(private http: HttpClient) { }


  getTop10Rank(): Observable<any> {
    return this.http.get(`${this.endpoint}user-leaderboard`);
  }

  createUser() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.endpoint}create-user`, {}).subscribe(
        (res: any) => {
          resolve(true);
        },
        (err: any) => {
          resolve(false);
        }
      );
    })
    // return this.http.get(`${this.endpoint}create-user`);
  }

}
