import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Observable, catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class MessageService {
  endpoint = environment.nodeEndpoint;
  endpoint1 = environment.apiURL;
  endpoint2 = '';

  constructor(private http: HttpClient, private router: Router) { }
  sendMessage(message: string, filterQuery: any) {
    return this.http.post(`${this.endpoint}azure-search-v1`, {
      prompt: message,
      filterQuery: filterQuery,
    });
  }
  sendE360Message(message: string, filterQuery: any) {
    return this.http.post(`${this.endpoint}azure-search-e360`, {
      prompt: message,
      filterQuery: filterQuery,
    });
  }
  useraccess() {
    return this.http.get(`${this.endpoint}user-access`, {});
  }
  fileStatus() {
    return this.http.get(`${this.endpoint}file-status`, {});
  }
  sendPlaygroundRequest(prompt: any) {
    return this.http.post(`${this.endpoint}playground`, prompt).pipe(catchError(this.handleError))
  }
  sendCorpusRequest(query: string, filter?: string) {
    let url = `${this.endpoint}corpus?&search=` + query;
    if (filter !== undefined && filter !== null) {
      url = url + `&$filter=Category eq '${filter}'`;
    }
    return this.http.get(url);
  }

  sendChatCompletionRequest(messages: any) {
    return this.http.post(`${this.endpoint}chat-completion`, {
      message: messages
    }).pipe(catchError(this.handleError))
  }


  sendBYODRequests(userquery: string) {
    let data = {
      "filename": localStorage.getItem('fileName'),
      "kbname": sessionStorage.getItem('kbname'),
      "userquery": userquery
    };
    return this.http.post(`${this.endpoint1}/getanswer`, data).pipe(catchError(this.handleError))
  }
  sendazurerequests(userquery: string) {
    let data = {
      "file_name": localStorage.getItem('fileName'),
      // "kbname": sessionStorage.getItem('kbname'),
      "userquery": userquery
    };
    return this.http.post(`${this.endpoint1}/azure_csv_resp`, data).pipe(catchError(this.handleError))
  }
  sendFormRequests(userquery: string) {
    let data = {
      "kbname": sessionStorage.getItem('kbname'),
      "userquery": userquery
    };
    return this.http.post(`${this.endpoint}byod-answer`, data).pipe(catchError(this.handleError))
  }
  checkFileStatus() {

    return this.http.get(`${this.endpoint}file-status`).pipe(catchError(this.handleError));
  }

  checkbyodStatus(data: any) {

    return this.http.post(`${this.endpoint1}/byod-status`, data).pipe(catchError(this.handleError));
  }

  uploadBYOD(files: any, method: string, imageExists?: boolean) {
    console.log('api call ', files)
    const formData: FormData = new FormData();
    if (files?.length > 0) {
      for (const file of files) {
        formData.append('files', file, file.name);
      };
    };

    formData.append('with_image', imageExists ? 'true' : 'false');

    //if(imageExists) formData.append('filename file_image', file.name);

    const req = new HttpRequest('POST', `${this.endpoint1}${'/' + method}`, formData, {
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }
  //   uploadBYOD(files: any, method: string, imageExists?: boolean) {
  //     console.log('api call ', files);
  //     const formData: FormData = new FormData();
  //     let isSpecialFileType = false;

  //     if (files?.length > 0) {
  //         for (const file of files) {
  //             formData.append('files', file, file.name);
  //             const fileExtension = file.name.split('.').pop()?.toLowerCase();
  //             if (fileExtension === 'csv' || fileExtension === 'xls' || fileExtension === 'xlsx' ||
  //                 fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png') {
  //                 isSpecialFileType = true;
  //             }
  //         }
  //     }

  //     formData.append('with_image', imageExists ? 'true' : 'false');

  //     if (!isSpecialFileType) {
  //         method ='/uploader'; // Append method 'gcm' if any of the files are special types
  //         this.endpoint2 = `${this.endpoint1}${method}`;
  //     }
  // else{
  //   this.endpoint2 = `${this.endpoint}${method}`;
  // }
  //     const req = new HttpRequest('POST', `${this.endpoint2}`, formData, {
  //         reportProgress: true,
  //         responseType: 'json'
  //     });

  //     return this.http.request(req);
  // }

  // bot api need to remove
  sendPdfCompletionRequest(messages: any) {
    return this.http.post(`${this.endpoint}pdf-search?app=HR-Bot`, {
      message: messages
    }).pipe(catchError(this.handleError))
  }
  sendPdfCompletionRequestIT(messages: any) {
    return this.http.post(`${this.endpoint}pdf-search?app=IT-Bot`, {
      message: messages
    }).pipe(catchError(this.handleError))
  }
  sendPdfCompletionRequestTransition(messages: any) {
    return this.http.post(`${this.endpoint}pdf-search?app=Transition-Bot`, {
      message: messages
    }).pipe(catchError(this.handleError))
  }


  complanceChat(app: any, messages: any, source = '') {
    let payload: any = { message: messages };
    if (source) payload['source'] = source;

    return this.http.post(`${this.endpoint}pdf-search?app=${app}`, payload).pipe(catchError(this.handleError))
  }

  complanceChatStats() {
    const url = `${this.endpoint1}/queryVS-status`;
    return this.http.get<any>(url);
  }




  private handleError(error: HttpErrorResponse) {
    let errMessage = '';
    console.log('error', error);
    if (error?.status === 0) {
      console.error('An error occurred:', error.error);
    } else {

      if (error?.status === 409 || error?.status === 401) {
        errMessage = error.status.toString();
        return throwError(() => new Error(errMessage));
      }
      console.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    // Return an observable with a user-facing error message.
    errMessage = 'Please try again later';
    return throwError(() => new Error(errMessage));
  }
  //bgcupload
  bgcupload(formData: FormData): Observable<any> {
    return this.http.post(`${this.endpoint}/bgc-upload`, formData);
  }




}
