
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { OktaAuthModule } from '@okta/okta-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { CvmatcherComponent } from './cvmatcher/cvmatcher.component';
import { E360SupportComponent } from './e360-support/e360-support.component';

import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { PlaygroundComponent } from './playground/playground.component';
import { HelpSupportComponent } from './shared/components/help-support/help-support.component';
import { SidenavComponent } from './sidenav/sidenav.component';

import { OktaAuth } from '@okta/okta-auth-js';
import { AuthInterceptorProvider } from './auth.interceptor';

import { HomeOpenaiComponent } from './home-openai/home-openai.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';

import { FileUploadComponent } from './file-upload/file-upload.component';



import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AutosizeModule } from 'ngx-autosize';
import { ClipboardModule } from 'ngx-clipboard';
import { MarkdownModule } from 'ngx-markdown';
import { ToastrModule } from 'ngx-toastr';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { environment } from 'src/environments/environment';
import Typed from 'typed.js';
import { ChatGptComponent } from './chatgpt/chat-gpt.component';

import { ConverseComponent } from './converse/converse.component';
import { ChatSectionComponent } from './shared/components/chat-section/chat-section.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { UploadFileComponent } from './shared/components/upload-file/upload-file.component';


import { TranslateFileComponent } from './translate-file/translate-file.component';


import { SecurityContext } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
 import { BedrockChatComponent } from './bedrock-chat/bedrock-chat.component';
import { BringYourFileComponent } from './bring-your-file/bring-your-file.component';
import { VideoIndexerComponent } from './video-indexer/video-indexer.component';


import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ProfileDropdownComponent } from './shared/components/profile-dropdown/profile-dropdown.component';
import { StopProcessComponent } from './shared/components/stop-process/stop-process.component';

import { MatDialogModule } from '@angular/material/dialog';
import { TransitionBotComponent } from './Transition-Bot/Transition-Bot.component';
// import { AcuvateComponent } from './acuvate/acuvate.component';
// import { ChatbotComponent } from './acuvate/chatbot/chatbot.component';
// import { FineTuneComponent } from './acuvate/fine-tune/fine-tune.component';
import { PreviewOrAskQuestionComponent } from './acuvate/preview-or-ask-question/preview-or-ask-question.component';
// import { TopBarComponent } from './acuvate/top-bar/top-bar.component';
import { ConverseAiComponent } from './converse-ai/converse-ai.component';
// import { CvjdpreferencesComponent } from './cvjdpreferences/cvjdpreferences.component';
// import { CvtoolComponent } from './cvtool/cvtool.component';
// import { DataAnalyticsComponent } from './data-analytics/data-analytics.component';
import { DataUploadComponent } from './data-upload/data-upload.component';
import { ImageGeneratorComponent } from './image-generator/image-generator.component';
import { ITBotComponent } from './it-bot/it-bot.component';
// import { JdtoolComponent } from './jdtool/jdtool.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { PdfChatComponent } from './pdf-chat/pdf-chat.component';
import { PerplexityComponent } from './perplexity/perplexity.component';
import { SafePipe } from './shared/Pipes/safe.pipe';
import { ChatLayoutComponent } from './shared/components/chat-layout/chat-layout.component';
import { SuccessComponent } from './shared/components/success/success.component';
import { SuccessStoriesComponent } from './success-stories/success-stories.component';
import { SuccessstoriesComponent } from './successstories/successstories.component';

import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';
import { NgxSpinnerModule } from "ngx-spinner";
import { BgcComponent } from './bgc/bgc.component';
import { MultipleUploadComponent } from './shared/components/multiple-upload/multiple-upload.component';

import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatPaginatorModule } from '@angular/material/paginator';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { PgBotComponent } from './_features/pg-bot/pg-bot.component';
import { BgcFormComponent } from './bgc/bgc-form/bgc-form.component';
import { BgcInitComponent } from './bgc/bgc-init/bgc-init.component';
import { BgcListComponent } from './bgc/bgc-list/bgc-list.component';
import { GoogleTranslateComponent } from './google-translate/google-translate.component';
import { SystranSidenavComponent } from './systran-sidenav/systran-sidenav.component';
import { FileTranslateComponent } from './systran/file-translate/file-translate.component';
import { SystranComponent } from './systran/systran.component';
import { TextTranslateComponent } from './systran/text-translate/text-translate.component';


import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { SubsidiaryComponent } from './subsidiary/subsidiary.component';
import { ApiAssistantComponent } from './api-assistant/api-assistant.component';


import { CommonModule } from '@angular/common';
import { GcpChatComponent } from './gcp-chat/gcp-chat.component';
import { AskGenpactComponent } from './ask-genpact/ask-genpact.component';


import { MsalModule, MsalRedirectComponent, MsalGuard, MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';








const oktaAuth = new OktaAuth(environment.oidc);

const msalConfig = {
  auth: {
    clientId: '72695f9c-85ef-462b-b0e1-d9f1a40ae804',
    authority: 'https://login.microsoftonline.com/313b1a08-33d2-44ce-bf76-7ef5f430f7e8',
    tenantId: '313b1a08-33d2-44ce-bf76-7ef5f430f7e8',
    redirectUri: environment.msredirecturi,
  }
};
@NgModule({
  declarations: [


    AppComponent,
    HelpSupportComponent,
    LoginComponent,
    E360SupportComponent,

    SidenavComponent,

    // CvmatcherComponent,
    // CvtoolComponent,
    // JdtoolComponent,
    // CvjdpreferencesComponent,
    LogoutComponent,
    PlaygroundComponent,
    HomeComponent,
    UnauthorizedComponent,

    HomeOpenaiComponent,
    FileUploadComponent,
    TranslateFileComponent,
    ChatGptComponent,
    UploadFileComponent,
    LoaderComponent,

    ChatSectionComponent,

    ConverseComponent, VideoIndexerComponent,
    BringYourFileComponent,
     BedrockChatComponent,
    StopProcessComponent,

    ProfileDropdownComponent,

    ConverseAiComponent,
    ImageGeneratorComponent,
    ChatLayoutComponent,
    PerplexityComponent,

    PdfChatComponent,
    ITBotComponent,
    TransitionBotComponent,
    SuccessComponent,


    // AcuvateComponent,
    // TopBarComponent,
    // ChatbotComponent,
    // FineTuneComponent,
    SafePipe,
    PreviewOrAskQuestionComponent,
    SuccessstoriesComponent,
    SuccessStoriesComponent,
    LeaderboardComponent,
    // DataAnalyticsComponent,
    DataUploadComponent,

    BgcComponent,
    MultipleUploadComponent,

    BgcListComponent,
    BgcFormComponent,
    BgcInitComponent,
    PgBotComponent,
    SystranComponent,
    TextTranslateComponent,
    FileTranslateComponent,
    SystranSidenavComponent,
    GoogleTranslateComponent,
    SubsidiaryComponent,
    ApiAssistantComponent,
    GcpChatComponent,
    AskGenpactComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    NgxDocViewerModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AgGridModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    NgSelectModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    ClipboardModule,
    MarkdownModule.forRoot({
      sanitize: SecurityContext.NONE
    }),
    AutosizeModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule.forRoot({
      type : "ball-spin-clockwise",
       }),
    NgxTypedJsModule,
    OktaAuthModule,
    OktaAuthModule.forRoot({ oktaAuth }),
    NgMultiSelectDropDownModule.forRoot(),
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }), // ToastrModule added

    ModalModule.forRoot(),
    TooltipModule.forRoot(),

    MatDialogModule,
    PopoverModule.forRoot(),
    MsalModule.forRoot(new PublicClientApplication(msalConfig), {
      interactionType: InteractionType.Redirect,
      authRequest: {
        scopes: ['Sites.Read.All']
      }
    }, {
      interactionType: InteractionType.Redirect,
      protectedResourceMap: new Map([
        ['https://graph.microsoft.com/v1.0/', ['Sites.Read.All']]
      ])
    }),

  ],
  providers: [AuthInterceptorProvider,
    MsalService, MsalBroadcastService,
    MsalGuard,
    { provide: Typed, useValue: Typed }],

  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule { }
