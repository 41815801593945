<div class="wrapper">
  <app-sidenav [item]="'playground'"></app-sidenav>


    <!-- Main content -->
    <section class="content">
  <div class="container-fluid responsive">
      <div class="container">

        <div class="row flex-nowrap ">
        <main id="main" class="mt-2">
          <div class="chat-box-container mt-2 genpact-color">
            <h1 class="chat-box-header"><h4 class="text-white">IT BOT</h4></h1>

            <div
              class="chat-box rounded"
              id="chat-box"
            >


              <div class="ai-person-container">
                <div class="ai-image"><img src="assets/bot.png" /></div>
                <div class="ai border shadow-sm" >
                  <p>Welcome to the IT AI Assistant! I'm here to provide quick and accurate answers to your IT related questions. How can I assist you today?</p>
                </div>
                <span class="ai-date">{{ currenttime }}</span>
              </div>
            </div>
          </div>
          <div class="chat-input-container text-white " >
            <textarea
              class="chat-input rounded"
              type="text"
              name="chat-input"
              id="chat-input"
              value=""
            ></textarea>
            <button
              class="btn rounded text-white"
              id="btn-Submit"
              (click)="onSubmit()"
              type="button"
              name="submit"
            >
              <!-- <svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
                <path fill="#006ae3" d="M2,21L23,12L2,3V10L17,12L2,14V21Z"><p markdown>{{response}}</p></path>
              </svg> -->
            </button>
          </div>
          <div class="row">
            <div class="col-12" style="color: gray; font-size: 12px !important">
              {{ notetext }}
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
    </section>

  </div>
