import { Component, ViewChild, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AvatarDisplayComponent } from '../avatar-display/avatar-display.component';
import { LoaderComponent } from '../loader/loader.component';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { DigiService } from 'src/app/digi.service';

// import { PopupComponent } from '../popup/popup.component';

@Component({
  selector: 'app-image-upload',
  standalone: true,
  templateUrl: './image-upload.component.html',
  imports:[AvatarDisplayComponent,LoaderComponent],
  styleUrls: ['./image-upload.component.scss']
})
export class ImageUploadComponent implements AfterViewInit {

  @ViewChild('video') video!: ElementRef<HTMLVideoElement>;
  @ViewChild('uploadCanvas') uploadCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('captureCanvas') captureCanvas!: ElementRef<HTMLCanvasElement>;
  @ViewChild('canvas') canvas!: ElementRef<HTMLCanvasElement>;

  @Output() avatarGenerated = new EventEmitter<string>(); // Event emitter to pass avatar URL
  imageFile!: File;
  showPopup = false;
  context: CanvasRenderingContext2D | null = null;
  generatedAvatarUrl: string = ''; // Store the generated image URL
  successMessage: string = 'Image has been successfully generated'; // Success message

  constructor(private http: HttpClient,private loaderService: LoaderService,private digiservice:DigiService) {}

  ngAfterViewInit() {
    if (this.canvas?.nativeElement) {
      this.context = this.canvas.nativeElement.getContext('2d');
      if (this.context) {
        this.context.fillRect(0, 0, 100, 100);
      }
    } else {
      console.error('Canvas element is not available');
    }
  }

  onFileSelected(event: any) {
    this.imageFile = event.target.files[0];
    if (this.imageFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        if (this.uploadCanvas?.nativeElement) {
          this.drawImageOnCanvas(e.target.result, this.uploadCanvas.nativeElement);
        } else {
          console.error('Upload canvas element is not available');
        }
      };
      reader.readAsDataURL(this.imageFile);
    }
  }

  captureImage() {
    const modal = document.getElementById('captureModal');
    if (modal) {
      modal.style.display = 'block';
    }
    navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        if (this.video?.nativeElement) {
          this.video.nativeElement.srcObject = stream;
          this.video.nativeElement.style.display = 'block';
        } else {
          console.error('Video element is not available');
        }
      })
      .catch((error) => {
        console.error('Error accessing camera: ', error);
      });
  }

  captureImageFromVideo() {
    if (this.captureCanvas?.nativeElement) {
      const canvas = this.captureCanvas.nativeElement;
      const context = canvas.getContext('2d');
      if (context && this.video?.nativeElement) {
        context.drawImage(this.video.nativeElement, 0, 0, canvas.width, canvas.height);
  
        // Draw the captured image on the uploadCanvas
        if (this.uploadCanvas?.nativeElement) {
          this.drawImageOnCanvas(canvas.toDataURL('image/jpeg'), this.uploadCanvas.nativeElement);
        } else {
          console.error('Upload canvas element is not available');
        }
  
        // Convert the canvas image to a Blob and create a File object
        canvas.toBlob((blob) => {
          if (blob) {
            this.imageFile = new File([blob], 'captured_image.jpg', { type: 'image/jpeg' });
          }
        }, 'image/jpeg');
  
        this.closeModal();
      }
    } else {
      console.error('Capture canvas element is not available');
    }
  }
  

  drawImageOnCanvas(imageSrc: string, canvas: HTMLCanvasElement) {
    const context = canvas.getContext('2d');
    if (context) {
      const image = new Image();
      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0);
      };
      image.src = imageSrc;
    }
  }

  generateAvatar() {

    
    if (!this.imageFile) {
      alert('Please upload or capture an image first.');
      return;
    }

    this.loaderService.loading.next(true);
    

    const formData = new FormData();
    formData.append('image', this.imageFile);

    

    // First API call: Generate prompt
    this.digiservice.getDigiAvatar(formData)
      .subscribe(response => {
        if (response) {
          const prompt = response;
          const formData = new FormData();
          formData.append('dalle_prompt', prompt);

          // Second API call: Generate image using prompt
          // this.http.post<any>('https://api.playground-dev.genpact.com/poc/avatar/generate-image', formData)
          this.digiservice.getAvatar(formData)
            .subscribe(imageResponse => {
              if (imageResponse) {
                this.generatedAvatarUrl = imageResponse; // Set the generated image URL
                this.avatarGenerated.emit(this.generatedAvatarUrl);
                this.loaderService.loading.next(false); // Emit the avatar URL for the avatar display component
              } else {
                console.error('No image URL in response:', imageResponse);
                alert('Failed to generate image.');
              }
              this.showPopup = false;
            }, error => {
              console.error('Error generating image:', error);
              alert('Error occurred while generating the image.');
            });
        } else {
          console.error('No prompt in response:', response);
          alert('Failed to generate prompt.');
          this.showPopup = false;
        }
      }, error => {
        console.error('Error generating prompt:', error);
        alert('Error occurred while generating the prompt.');
        this.showPopup = false;
      });
  }

  closeModal() {
    const modal = document.getElementById('captureModal');
    if (modal) {
      modal.style.display = 'none';
    }
  }

}
