import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { BedrockService } from '../../service/bedrock.service';
import { ToastMessageService } from '../../service/toast-message.service';
import { LoaderService } from '../../service/loader.service';
import { DownloadPdfService } from '../../service/download-pdf.service';



@Component({
  selector: 'app-chat-layout',
  templateUrl: './chat-layout.component.html',
  styleUrls: ['./chat-layout.component.scss']
})
export class ChatLayoutComponent implements OnInit, OnChanges, OnDestroy {

  @ViewChild('scrollChat', { static: false }) scrollContainer!: ElementRef;
  @Output() apiCall = new EventEmitter<any>();
  @Input() methodSelected: any;
  @Input() chatData: any = [];
  @Input() isReset: boolean = false;

  prompt: any;
  generateResponse: boolean = false;
  constructor(   
    private toastMessage: ToastMessageService,
    private downloadPdfService : DownloadPdfService
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isReset) {   
      this.prompt = '';
      this.generateResponse = false;    
      this.scrollToBottom();
    }

  }

  // scoroll to bottom in chat window 
  scrollToBottom() {
    setTimeout(() => {
      const containerElement = this.scrollContainer.nativeElement;
      containerElement.scrollTop = containerElement.scrollHeight;
    }, 0);
  }


  // copy to clipboard
  copyToClipboard(text: any) {  
    const tempInput = document.createElement('textarea');
    tempInput.value = text;
    document.body.appendChild(tempInput);   
    tempInput.select();   
    document.execCommand('copy');
    document.body.removeChild(tempInput);
  }


  // send promt ot chat GPT api
  userChat(regenerate = false) {
    if ((!regenerate && !this.prompt) ||
      (!regenerate && (this.chatData.length == 0) && !this.prompt)) {
      this.toastMessage.showError('Question is required');
      return;
    }

    if (!regenerate) {
      let question = { "role": "user", "content": this.prompt };
      this.chatData.push(question);
    } else {
      this.chatData.push(this.lastChat());
    }
       
    this.generateResponse = true;

    let payload = { unSubscription:false, data:this.chatData}
    this.apiCall.next(payload);
  }

  // get last chat of user
  lastChat() {
    let userChat = this.chatData.filter((ele: any) => ele.role == 'user');
    console.log(userChat)
    return userChat.pop();;
  } 

  unsubscribeChat() {
    let payload = { unSubscription:true, data:this.chatData};    
    this.apiCall.next(payload);   
    this.prompt = '';
    this.generateResponse = false;    
    this.scrollToBottom();
  }

  //download
  downloadAsPdf() {
    this.downloadPdfService.downloadAsPdf("text-d",'downloaded-file.pdf');
}


  ngOnDestroy(): void {    
  }

}

