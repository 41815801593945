<div class="wrapper">
    <app-sidenav [item]="'playground'"></app-sidenav>
    <!-- Main content -->
    <section class="content">
        <div class="container-fluid responsive p-3">
            <div class="container">

                <!-- <app-chat-section></app-chat-section> -->

                <div class="row flex-nowrap">
                    <main id="main">
                        <div class="row mt-3 mb-4">
                            <div class="col-12 text-center">
                                <h4>
                                    Bring Your Own File(s)
                                </h4>

                                <div *ngIf="endPointDetail?.value" class="">
                                    Count Left: <b>{{ endPointDetail?.max - endPointDetail?.value}}</b>
                                  </div>
                            </div>
                        </div>
                        <div class="row">
                            <app-file-upload (filename)="filenameevent($event)" (fileuploaded)="fileuploaded($event)"
                                *ngIf="Isuploading">
                            </app-file-upload>
                        </div>

                        <div class="row px-md-5 " *ngIf="!Isuploading">

                            <div class="col-12 file-list" >
                              File Name:
                              <h6 style="display: contents; text-decoration: underline;">
                                {{filename}}
                                 <span (click)="Isuploading=true;filename='';queryresponse='';relatedQuestionList='';searchquery='';summary='';">
                                  <i class="fa fa-close" style="color:red;"></i>
                                </span>
                              </h6>
                            </div>

                          </div>


                        




                        <!-- Loading icon -->
                        <div *ngIf="showLoading" class="loading-indicator">
                            <div class="spinner"></div>
                            <div>Getting Your Data...</div>
                        </div>
                        <!-- Loading icon -->

                        <div class="row mt-2 px-md-5" *ngIf="summary">
                            <div class="col-12 p-0">
                                <div class="d-flex justify-content-between align-items-center mb-2">
                                    <button (click)="copyToClipboard(summary); isCopied1 = !isCopied1" class="btn-light"
                                    [ngStyle]="{'cursor': isCopied1 ? 'not-allowed' : 'pointer'}">

                                        <span [ngClass]="{'hide-text': isCopied1}"><i class="bi bi-clipboard"
                                                style="color: rgb(0, 0, 0);">copy</i></span>
                                        <!-- Unicode for copy symbol -->
                                        <span [ngClass]="{'hide-text': !isCopied1}"><i class="bi bi-clipboard-check"
                                                style="color: #01791d;">copied!!!</i></span>
                                    </button>

                                    <button (click)="downloadSummaryAsPdf()" class="btn-light">

                                        <i class="bi bi-download"
                                                style="color: rgb(0, 0, 0);">download</i>
                                    </button>

                                </div>
                                <div id = "summary-download" class="queryresponse"
                        lineNumbers clipboard markdown [data]="summary" [disableSanitizer]="true">
                        </div>
                            </div>
                    </div>
                        

                            <!-- download text -->

                        
                        
                        <!-- related questions -->
                        <div class="row mt-2  px-md-5" *ngIf="(relatedQuestionList?.length > 0) && relatedQuestionList !='null'">
                          <div  class="col-md-12" style="text-align: justify; padding-top: 10px">
                            <div class="heading">

                              <svg class="fontawesomesvg" xmlns="http://www.w3.org/2000/svg" height="1em"
                                style="vertical-align: baseline;" viewBox="0 0 576 512">
                                <path
                                  d="M264.5 5.2c14.9-6.9 32.1-6.9 47 0l218.6 101c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 149.8C37.4 145.8 32 137.3 32 128s5.4-17.9 13.9-21.8L264.5 5.2zM476.9 209.6l53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 277.8C37.4 273.8 32 265.3 32 256s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0l152-70.2zm-152 198.2l152-70.2 53.2 24.6c8.5 3.9 13.9 12.4 13.9 21.8s-5.4 17.9-13.9 21.8l-218.6 101c-14.9 6.9-32.1 6.9-47 0L45.9 405.8C37.4 401.8 32 393.3 32 384s5.4-17.9 13.9-21.8l53.2-24.6 152 70.2c23.4 10.8 50.4 10.8 73.8 0z" />
                              </svg>
                              &nbsp;<h4 style="display: contents;">Related Questions</h4>
                              <!-- <div class="answer w-100 mt-3" *ngFor="let answer of researchData"> -->
                              <div class="answer w-100 mt-3" *ngFor="let question of relatedQuestionList"
                                (click)="submitQuestion(question)">

                                <div class="col-sm-12">
                                  <div class="related-question">
                                    <div> {{ question }}</div>
                                    <button class="btn btn-sm border-0 pl-3">
                                      <i class="fa fa-plus"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>

                        <div class="row mt-5 px-md-5"  *ngIf="queryresponse">
                            <!-- copy text -->
                            <div class="col-12 p-0">
                                <div class="d-flex justify-content-between align-items-center mb-2">
                                    <button (click)="copyToClipboard(queryresponse); isCopied2 = !isCopied2" class="btn-light"
                                    [ngStyle]="{'cursor': isCopied2 ? 'not-allowed' : 'pointer'}">

                                        <span [ngClass]="{'hide-text': isCopied2}"><i class="bi bi-clipboard"
                                                style="color: rgb(0, 0, 0);">copy</i></span>
                                        <!-- Unicode for copy symbol -->
                                        <span [ngClass]="{'hide-text': !isCopied2}"><i class="bi bi-clipboard-check"
                                                style="color: #01791d;">copied!!!</i></span>
                                    </button>

                                        <button (click)="downloadAsPdf()" class="btn-light">

                                            <i class="bi bi-download"
                                                    style="color: rgb(0, 0, 0);">download</i>
                                        </button>

                                </div>
                            </div><br>
                            <!-- copy text -->

                            <!-- download text -->


                            <div id = "text-download" class="queryresponse"
                            lineNumbers clipboard markdown [data]="queryresponse" [disableSanitizer]="true">
                                <span class="typed-text"></span><span class="cursor">|</span>
                            </div>
                        </div>
                        <div class="row my-2" *ngIf="!Isuploading && queryresponse" >
                            <div class="col-12 note-block px-md-5">
                                <b [tooltip]="notetext" ><em>Note </em><i class="bi bi-info-circle" > </i></b>
                            </div>

                        </div>

                        <div class="row mt-1" *ngIf="!Isuploading">
                            <div class="col-12 p-0 px-md-5 py-3" >
                                <div class="input-group">
                                    <textarea id="t1" [(ngModel)]="searchquery "
                                    (keydown.enter)="onSearchButtonClicked()"
                                        id="txtquery" class="form-control byod-input "
                                        [disabled]="!tarea"
                                        autosize="true" placeholder="Enter your search query here"></textarea>

                                        <!-- voice button -->

                                        <button class="app-speech-input-btn" *ngIf="startspeech" (click)="startService()"></button>
                                        <button class="app1-speech1-input1-btn1" *ngIf="stopspeech"  (click)="stopService()"><i class="fa fa-microphone fa-beat-fade" style="font-size:20px;color:red"></i></button>

                                        <!-- voice button -->
                                    <!-- Add margin to create space between textarea and button -->
                                    <div class="input-group-append" >
                                        <button  [disabled]="!tarea" class="btn btn-primary" [disabled]="showLoading" type="button" (click)="onSearchButtonClicked()">
                                            <i class="bi bi-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- related questions -->
                        <div id="targetRed" style="opacity: 0;;">
                            ...
                        </div>
                    </main>
                </div>
            </div>
        </div>
    </section>






</div>



<!-- policy & condition toggle -->
<button type="button" class="btn btn-primary d-none" id="byod_policy_model"  (click)="openModal(byod_policy_model)">
</button>

<!-- policy & condition block -->
<ng-template #byod_policy_model>
    <div class="modal-body">

        <div class="row">
            <div class="col-12  fw-bold text-primary">In order to adhere to the Legal and Security policies,
                please take care of below mentioned points:</div>
        </div>
        <div class="row">
            <div class="col-12" style="font-size: 13px;">
                <ol style="list-style:decimal;">
                    <li>
                        SOPs/Documents/Images should be hosted on Genpact network.
                    </li>
                    <li>
                        Only documents hosted on the Genpact network and that Genpact has contractual
                        permission to maintain on the Genpact network may be uploaded here.
                    </li>
                    <!-- change here-->
                    <li>
                        This application is for Genpact internal employees usage only.
                    </li>

                </ol>

            </div>
        </div>
        <div class="row">
            <div class="col-12 ml-4">
                <input id="byod_check_label" type="checkbox" [(ngModel)]="chkagree" class="form-check-input" />
                <label class="form-check-label text-decoration-underline fw-bold text-primary" style="margin-left: 15px;" for="byod_check_label">
                    I acknowledge that I have read, understand and agree to above instructions.
                </label>
            </div>

        </div>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn button-genpact text-white center cursor-pointer" [disabled]="!chkagree"
            (click)="modalRef?.hide()">
            Proceed
        </button>
    </div>
</ng-template>
<!-- <textarea [(ngModel)]="combined">
  {{searchquery}} <br> {{queryresponse}}
</textarea> -->


<app-stop-process *ngIf="processStatus" (stopProgress)="resetForm($event)"></app-stop-process>
