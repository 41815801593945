import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserApiLimitService {

  endpoint = environment.nodeEndpoint;

  constructor(private http: HttpClient) { }

  getUserApiCount(endpoint: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.get(`${this.endpoint}user-endpoint-count?endpoint=${endpoint}`, { headers });
  }

  updateApiLimit(endpoint: any) {
    let paylaod = {
      "userId": this.getOHR(),
      "api_name": endpoint
    }
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${this.endpoint}get-api-access-status`, paylaod, { headers });
  }

  // get user OHR id
  getOHR() {
    let userData = localStorage.getItem('okta-token-storage');
    if (userData) {
      let ohr_id = JSON.parse(userData)?.accessToken?.claims?.OHR;
      if (ohr_id) {
        return ohr_id;
      } else {
        return null;
      }
    }
  }

}