import { Component, Input, OnInit } from '@angular/core';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';
import { TranslateService } from 'src/app/shared/service/translate.service';
import { SystranService } from 'src/app/shared/service/systran.service';
import * as MarkdownIt from 'markdown-it';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as saveAs from 'file-saver';
import { VoiceRecognitionService } from 'src/app/shared/voice-recognition.service';
@Component({
  selector: 'app-text-translate',
  templateUrl: './text-translate.component.html',
  styleUrls: ['./text-translate.component.scss']
})
export class TextTranslateComponent implements OnInit{
  dropdownSettings: IDropdownSettings = {
    singleSelection: true,  // or false if you want multiple selections
    idField: 'id',          // 'id' is the unique identifier in your options array
    textField: 'name',      // 'name' is the field used for display text
    itemsShowLimit: 3,
    maxHeight: 160,
    allowSearchFilter: true
  };
  source = [
    { id: 'Auto-detect', name: 'Auto-detect' },
    { id: 'en', name: 'English' },
    { id: 'nl', name: 'Dutch' },
    { id: 'fr', name: 'French' }
  ];

  target = [
    { id: 'en', name: 'English' },
    { id: 'nl', name: 'Dutch' },
    { id: 'fr', name: 'French' }
  ];
  TargetLanguage: any;
  sourceLanguage: any;
  isButtonDisabled: boolean = true;
  input:any;
  output:any;
  vocabulary:any;
  md = new MarkdownIt();
  showCopiedTranslatedText: boolean = false;
  output1: boolean = false;
  copy1: any;
  startspeech: boolean = true;
  stopspeech: boolean = false;
  speaking: boolean = false;
  speechSynth: SpeechSynthesis | undefined;
  utterance: SpeechSynthesisUtterance | undefined;

  constructor(
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private toastMessage:ToastMessageService,
    private sanitizer: DomSanitizer,
    public speechservice: VoiceRecognitionService,
    private systranService:SystranService
  ) {
    this.speechservice.init();
    this.speechservice.start();
    this.speechservice.stop();
   }


  // showCopiedTranslatedText: boolean = false;
  // showCopiedVocabulary: boolean = false;


  copyTranslatedText() {
    navigator.clipboard.writeText(this.output).then(() => {
      console.log('Translated text copied to clipboard');
      this.showCopiedTranslatedText = true;
      setTimeout(() => {
        this.showCopiedTranslatedText = false;
      }, 2000); // Change to desired duration in milliseconds
    }).catch((error) => {
      console.error('Unable to copy translated text: ', error);
    });
  }

  // copyVocabulary() {
  //   navigator.clipboard.writeText(this.md.render(this.vocabulary)).then(() => {
  //     console.log('Vocabulary copied to clipboard');
  //     this.showCopiedVocabulary = true;
  //     setTimeout(() => {
  //       this.showCopiedVocabulary = false;
  //     }, 2000); // Change to desired duration in milliseconds
  //   }).catch((error) => {
  //     console.error('Unable to copy vocabulary: ', error);
  //   });
  // }

  startService() {
    this.speechservice.start()
    this.startspeech = false;
    this.stopspeech = true;
    //this.showLoading = true;

  }

  stopService() {
    this.speechservice.stop()
    this.startspeech = true;
    this.stopspeech = false;
   // this.showLoading = false;
  }
  toggleSpeaking() {
    this.speaking = !this.speaking;
    if (this.speaking) {
      this.speechSynth = window.speechSynthesis;
      if (this.speechSynth && !this.speechSynth.speaking && this.output.length > 0) {
        this.utterance = new SpeechSynthesisUtterance(this.output);
        this.speechSynth.speak(this.utterance);
        this.utterance.onend = () => {
          this.speaking = false;
        };
      }
    } else {
      if (this.speechSynth && this.utterance) {
        this.speechSynth.cancel();
        this.speaking = false;
      }
    }
  }

  ngOnInit(): void {
    // Auto-select "Auto-detect" option

    this.sourceLanguage = [{ id: 'Auto-detect', name: 'Auto-detect' }];
  //  this.TargetLanguage = [{ id: 'English', name: 'English' }];
   // this.onDropdownChange();//
   this.speechservice.voiceRecognitionUpdate.subscribe((updatedText: string) => {
    this.input = updatedText;
  });
  }

  onDropdownChange() {
    // Call this function whenever your dropdown values change
    // Update the button state
   // console.log('this works');
    this.updateButtonState();
  }
  storeLogs(){
    const payload = {
      'event_type' : 'AON',
      'event_data' : {
        "type":"text_translation",
        'target_language' : this.TargetLanguage[0].id,
      }
    }
    this.systranService.logsStorage(payload).subscribe((res)=>{
      // console.log(res);
    })
  }
  private updateButtonState() {
    // Adjust the condition based on your requirements
    this.isButtonDisabled = !this.sourceLanguage.length || !this.TargetLanguage.length  ;
  }
translate(){
  // this.storeLogs();
  const payload = {
   source_language: this.sourceLanguage[0].id =='Auto-detect' ? '' : this.sourceLanguage[0].id,
    target_language:this.TargetLanguage[0].id,
    text:this.input
  };
  this.loaderService.loading.next(true);
  this.systranService.translatetext(payload).subscribe((res: any) => {
if(res.status === "success"){
    this.loaderService.loading.next(false);
    this.toastMessage.showSuccess(res.message);
    this.output = res.data;
    this.output1 = true;
    this.dictionary();
  }
  else{
    this.loaderService.loading.next(false);
    this.toastMessage.showError(res.message);
    this.output = 'error';
  }
  }, (error) => {
    this.loaderService.loading.next(false);
    this.toastMessage.showError('Error in translating text');
    this.output = 'error';
    this.vocabulary = 'error';
  });

}
dictionary(){
  const payload = {
    //source: this.sourceLanguage[0].id,
    target_language:this.TargetLanguage[0].id,
    text:this.input
  };
  this.loaderService.loading.next(true);
  this.systranService.dictionary(payload).subscribe((res: any) => {
if(res.status === "success"){
    this.loaderService.loading.next(false);
    this.toastMessage.showSuccess(res.message);
    this.vocabulary =this.md.render(res.data);
    this.copy1=res.data;
  }
  else{
    this.loaderService.loading.next(false);
    this.toastMessage.showError(res.message);
    this.vocabulary = 'error';
  }
  }, (error) => {
    this.loaderService.loading.next(false);
    this.toastMessage.showError('Error in translating text');
    this.vocabulary = 'error';
  });
}
download() {
  const content = this.vocabulary;
  const blob = new Blob([content], { type: 'text/plain' });
  saveAs(blob, 'vocabulary.txt');
}
// download() {
//   const htmlContent = this.copy1 ;
//   const content = `
//     <!DOCTYPE html>
//     <html lang="en">
//     <head>
//       <meta charset="UTF-8">
//       <title>Document</title>
//     </head>
//     <body>${htmlContent}</body>
//     </html>
//   `;
//   const blob = new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
//   const url = window.URL.createObjectURL(blob);
//   const a = document.createElement('a');
//   document.body.appendChild(a);
//   a.href = url;
//   a.download = 'vocabulary.docx';
//   a.click();
//   window.URL.revokeObjectURL(url);
//   document.body.removeChild(a);
// }

}
