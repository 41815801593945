<div class="wrapper">
    <app-sidenav [item]="'playground'"></app-sidenav>
    <!-- Main content -->
  
    <section class="content">
      <div class="container-fluid responsive">
        <div class="container">
  
          <div class="row flex-nowrap">
            <main id="main">
              <div class="row mt-4 mb-3">
                <div class="col-12" style="text-align: center;">
                  <h4>
                    Translate Your Files using Google Translate
                  </h4>
  
  
                  <div *ngIf="endPointDetail?.value" class="">
                    Count Left: <b>{{ endPointDetail?.max - endPointDetail?.value}}</b>
                  </div>
  
                </div>
              </div>
  
              <div class="row py-3">
                <div class="col-md-12">
                  <div class="selectlang" align="center">
                    <ng-container *ngIf="fileUploadedStatus">
                      <!-- From
                           <ng-multiselect-dropdown
                            [placeholder]="'Select'"
                            [settings]="dropdownSettings"
                            [data]="languageArray"
                            [(ngModel)]="convertFrom"
                          >
                          </ng-multiselect-dropdown> -->
  
                      Translate To
                      <ng-multiselect-dropdown [placeholder]="'Select'" [settings]="dropdownSettings"
                        [data]="languageArray" [(ngModel)]="convertTo">
                      </ng-multiselect-dropdown>
  
                      <button class="bg-primary text-light p-2 px-4 border border-r1" (click)="translateContent()">
                        Translate </button>
  
                    </ng-container>
                    <button *ngIf="downloadbtn" class="bg-success text-light p-2 px-4 border border-r1"
                      (click)="downloadFile()"> Download </button>
  
                  </div>
  
                </div>
              </div>
              <div class="row" *ngIf = "showUpload">
  
                <!-- <app-upload-file placeholder="Only .txt, .doc, .pdf, .xls,.png ,.jpeg,.jpg,.PNG,.tif,.tiff and .ppt file are allowed (max Size 16 MB)"
                 multiple inputAccept=".txt,.xlsx,.xls,.pdf,.PDF,.pptx,.PPTX,.ppt,.pptx,.doc,.DOC,.docx,.DOCX,.png ,.jpeg,.jpg,.PNG,.tif,.tiff"
                  (fileuploaded)="postTranslateUpload($event)" maxFileSize=16 ></app-upload-file> -->
                  <!-- [accept]="ImageStatus ? '.png ,.jpeg,.jpg,.PNG,.tif,.tiff' : '.txt,.xlsx,.xls,.pdf,.PDF,.pptx,.PPTX,.ppt,.pptx,.doc,.DOC,.docx,.DOCX'" [multiple]="ImageStatus" -->
  
                  <app-multiple-upload placeholder="Only .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .png, .jpg, .jpeg, .PNG file are allowed"
                  inputAccept=".pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .png, .jpg, .jpeg, .PNG"
                  (change)="onupload($event)" >
                </app-multiple-upload>
                <!-- <app-multiple-upload placeholder="Only .txt, .doc, .pdf, .xls,.png ,.jpeg,.jpg,.PNG,.tif,.tiff and .ppt file are allowed"
                [accept]="fileUploadAcceptValue()"
                [multiple]="fileUploadMultipleValue()"
                (change)="onupload($event)" >
              </app-multiple-upload> -->
  
                  <!-- note -->
  
  
              </div>
              <!-- <div class="item pt-2 pl-1" *ngIf = "showUpload" >
                <label class="checkbox-label">
                  <input type="checkbox" id="uploadCheckbox" [(ngModel)]="ImageStatus"
  
                    required [ngModelOptions]="{standalone: true}">
                  check the box if uploading  Image(s)
                </label>
  
                    <div class="disclaimer mt-3">
                      <p>
                          <b> <i class="bi bi-info-circle"></i> </b>
                          Multiple file upload is only for Images.
                      </p>
                  </div>
  
              </div> -->
  
  
              <div class="row px-md-5 " *ngIf="fileUploadedStatus || downloadStatus">
  
                <div class="col-12 file-list">
                  File Name:
                  <h6 style="display: contents; text-decoration: underline;">
                    {{file?.name}}
                    <span (click)="resetFile()">
                      <i class="fa fa-close" style="color:red;"></i>
                    </span>
                  </h6>
                </div>&nbsp;
                <div class="item">
                  <label class="checkbox-label">
                    <input type="checkbox" id="uploadCheckbox" [(ngModel)]="glossaryStatus"
                      required [ngModelOptions]="{standalone: true}">
                    Do you want to add a glossary for keywords /brandnames?
                  </label>
                  <!-- <div class="row" *ngIf = "glossaryStatus">
                    <table>
                      <tr>
                        <th>Current Text</th>
                        <th>Translate To</th>
                      </tr>
                      <tr *ngFor="let data of tableData">
                        <td><input [(ngModel)]="currentText" type="text" placeholder="Current Text"></td>
                        <td><input [(ngModel)]="translatedText" type="text" placeholder="Translate To"></td>
                      </tr>
                    </table>
                    <div class="row py-3">
                      <div class="col-sm-12 text-center">
                    <button class="bg-primary text-light p-2 px-4 border border-r1" (click)="addRow()">Add More</button>
                    <button class="bg-primary text-light p-2 px-4 border border-r1" (click)="removeRow()">Remove</button>
                   </div>
                   <button class="bg-primary text-light p-2 px-4 border border-r1" (click)="glossaryFileUpload(tableData)">Submit</button>
                  </div>
                    <app-upload-file placeholder="You can only upload csv files (max Size 16 MB)"
                      inputAccept=".csv"
                      (fileuploaded)="glossaryFileUpload($event)" maxFileSize=16>
                    </app-upload-file> -->
                    <!-- <div class="row px-md-5 " *ngIf="glossaryFileUploadedStatus || downloadStatus">
  
                  <div class="col-12 file-list">
                  File Name:
                  <h6 style="display: contents; text-decoration: underline;">
                    {{glossaryFileData?.name}}
                    <span (click)="resetglossaryFile()">
                      <i class="fa fa-close" style="color:red;"></i>
                    </span>
                  </h6>
                  </div>
                    </div> -->
                  <!-- </div> -->
                  <div class="row table-responsive m-0" *ngIf="glossaryStatus" >
                    <table class="table" >
  
                        <div class="row py-3 m-0">
                          <div class="col-sm-12 text-right">
                            <button  class="bg-primary text-light p-2 px-4 border border-r1" (click)="addGlossaryEntry()"><i class="bi bi-plus"></i>Add More</button>
                            <!-- <button class="bg-primary text-light p-2 px-4 border border-r1" (click)="removeGlossaryEntry()">Less</button> -->
                          </div></div>
                      <tr>
                        <th style="text-align: left; background-color:#ececec">Current Text</th>
                        <th style="text-align: left;background-color:#ececec">Translate To</th>
                        <th style="background-color:#ececec">Action</th>
                      </tr>
                      <tbody>
                      <tr *ngFor="let data of tableData;let i= index">
                        <td ><input class="form-control" [(ngModel)]="data.currentText" type="text" placeholder="Current Text"></td>
                        <td ><input class="form-control" [(ngModel)]="data.translatedText" type="text" placeholder="Translate To"></td>
                        <td class="rmbtn" >
                          <button *ngIf="i > 0" class="bg-danger text-light p-2 px-4 border border-r1" (click)="removeGlossaryEntry(i)">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
                              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
                            </svg>
                          </button>
                        </td>
                      </tr></tbody>
  
                    </table>
                    <br><br><br>
                      <!-- <div class="col-sm-12 text-center">
                      <button class="bg-primary text-light p-2 px-4 border border-r1" (click)="glossaryFileUpload()">Submit</button>
                    </div> -->
                    <!-- </div> -->
                  </div>
                  </div>
  
              </div>
  
  
  
            </main>
          </div>
        </div>
      </div>
    </section>
  
  
  
    <app-stop-process *ngIf="processStatus" (stopProgress)="resetForm($event)"></app-stop-process>
  