import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpeakService {

  constructor() { }

  speak(text: string) {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.voice = this.getPreferredVoice();
    speechSynthesis.speak(utterance);
  }

  cancel() {
    speechSynthesis.cancel();
  }

  private getPreferredVoice(): SpeechSynthesisVoice | null {
    const voices = speechSynthesis.getVoices();
    let preferredVoice: SpeechSynthesisVoice | undefined;

    for (const voice of voices) {
      if (this.isIndianEnglishVoice(voice)) {
        return voice;
      } else if (!preferredVoice && this.isEnglishVoice(voice)) {
        preferredVoice = voice;
      }
    }

    // If no Indian English or English voice is found, return null
    return preferredVoice || null;
  }

  private isIndianEnglishVoice(voice: SpeechSynthesisVoice): boolean {
    return voice.lang.startsWith('en-IN') || voice.lang.startsWith('hi-IN');
  }

  private isEnglishVoice(voice: SpeechSynthesisVoice): boolean {
    return voice.lang.startsWith('en');
  }
}
