export const environment = {
  production: false,
  apiURL: "https://api1.playground-dev.genpact.com", // End point of translate file in python
  languageApi: "https://api.cognitive.microsofttranslator.com/languages?api-version=3.0&scope=translation",
  googleTranslateApi: "https://gapi1.playground.genpact.com",
  imgGeneratorApiURL: "https://gapiw.playground-dev.genpact.com/images",
  nodeEndpoint: 'https://api.playground-dev.genpact.com/nodejs/',
  // nodeEndpoint : 'http://localhost:3000/nodejs/',
// msredirecturi: 'http://localhost:4200/ask-genpact',
  msredirecturi: 'https://playground-dev.genpact.com/ask-genpact',

  podurl: 'https://gapi1.playground.genpact.com',
  podcastsurl: 'https://gapi1.playground.genpact.com/podcasts',


  oidc: {
    clientId: '0oa5iwgo7z7fjaWIM0x7',
    issuer: 'https://genpact.oktapreview.com/oauth2/default',
   redirectUri: 'https://playground-dev1.genpact.com/home',

  //redirectUri: 'http://localhost:4200/home',

    scopes:  ['openid', 'profile', 'email'],
  },
  resourceServer: {
    messagesUrl: 'https://playground-dev.genpact.com/api/messages',
  },

  //okta
  LOGOUT_REDIRECT_URI: 'https://playground-dev1.genpact.com/logout',
// LOGOUT_REDIRECT_URI: 'http://localhost:4200/logout',
//
//
  msalConfig: {
    auth: {
      clientId: '7674748b-9f67-4137-88f5-d613af7ebc46',
      authority: 'https://login.microsoftonline.com/common'
    }
  },
  apiConfig: {
    scopes: ['user.read','profile'],
    uri: 'https://graph.microsoft.com/v1.0/me'
  },


  "query": "https://api.playground-dev.genpact.com/gknowledge/QueryVectorStore?query=",
  "historyLog": "https://api.playground-dev.genpact.com/gknowledge/UserHistoryLog",
  "collections": "https://api.playground-dev.genpact.com/gknowledge/GetCollections",
  "fine_tune_model_api": "https://api.playground-dev.genpact.com/acuvate/",
  "server_name": "pgdbpostgress.postgres.database.azure.com",
  "admin_username": "pgadmin",
  "password": "G#enpact123",
  "database_name": "gk_vectordb"

};





